<template>
  <div class="d-flex justify-center">
    <div class="title-bold"> {{ jobTitle }} </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useStore } from '@/stores/store'

const store = useStore()

const jobTitle = computed(() => {
  return store.selectedJobPosting?.title
})
</script>
