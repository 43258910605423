<template>
  <div>
    <v-img
      class="labatt-logo"
      src="@/assets/labatt-logo.png"
      alt="Labatt Logo"
    ></v-img>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>
.labatt-logo {
  height: 65px;
  margin-top: 25px;
}
</style>