<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row>
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="section" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
        v-if="error"
        class="d-flex justify-center mb-5"
      >
        <v-alert
          border="start"
          type="error"
          variant="tonal"
        >
          {{ errorMessage }}
        </v-alert>
      </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Review Application' : 'Revise la aplicación' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <div>
            <div v-for="s in sections">
              <hr class="mb-3">
              <div class="d-flex">
                <!-- Section -->
                <h4 class="mt-2 mb-3"> 
                  <span v-if="s.id === 'personal-information'"> 
                    {{ isEnglish ? 'Personal Information' : 'Información Personal' }} 
                  </span>
                  <span v-if="s.id === 'work-eligibility'"> 
                    {{ isEnglish ? 'Work Eligibility' : 'Elegibilidad Laboral' }} 
                  </span>
                  <span v-if="s.id === 'education-military'"> 
                    <span v-if="phase === 1">
                      {{ isEnglish ? 'Education' : 'Educación' }} 
                    </span>
                    <span v-else>
                      {{ isEnglish ? 'Education & Military' : 'Educación y Servicio Militar' }}
                    </span>
                  </span>
                  <span v-if="s.id === 'previous-employment'"> 
                    {{ isEnglish ? 'Previous Employment' : 'Empleo Previo' }} 
                  </span>
                  <span v-if="s.id === 'work-experience'"> 
                    {{ isEnglish ? 'Work Experience' : 'Experiencia Laboral' }} 
                  </span>
                  <span v-if="s.id === 'background-questions'"> 
                    {{ isEnglish ? 'Background Questions' : 'Preguntas Sobre Antecedentes' }} 
                  </span>
                  <span v-if="s.id === 'driver-information'"> 
                    {{ isEnglish ? 'Driver Information' : 'Historial de Conducir' }} 
                  </span>
                  <span v-if="s.id === 'eeo-aa'"> 
                    {{ isEnglish ? 
                       'Equal Employment Opportunity & Affirmative Action' : 
                       'Declaración de Igualdad de Oportunidades de Empleo y Acción Afirmativa' }} 
                  </span>
                </h4>
                <!-- Edit - Btn -->
                <v-btn 
                  class="btn ml-8" 
                  elevation="0"
                  variant="tonal"
                  @click="editSection(s)"
                >
                  {{ isEnglish ? 'Edit' : 'Editar' }}
                </v-btn>
              </div>
              <div class="font-sm">                
                <!-- Personal Information -->
                <div v-if="s.id === 'personal-information'">
                  <div v-if="phase === 0 || phase === 1">               
                    <div>
                      <label> {{ isEnglish ? 'Date of birth: ' : 'Fecha de nacimiento: ' }} </label>
                      <span><b> {{ formatDate(personalInformation.dateOfBirth) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Social security number: ' : 'Número de seguro social: ' }} </label>
                      <span><b> {{ formatSSN(personalInformation.socialSecurityNumber) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Cell phone number: ' : 'Número de teléfono celular: ' }} </label>
                      <span><b> {{ formatPhone(personalInformation.phoneNumber) }} </b></span>
                    </div>
                    <div v-if="personalInformation.alternatePhoneNumber">
                      <label> {{ isEnglish ? 'Alternate phone number: ' : 'Número de teléfono alternativo: ' }} </label>
                      <span><b> {{ formatPhone(personalInformation.alternatePhoneNumber) }} </b></span>
                    </div>
                  </div>
                  <div v-if="requireMaritalStatus && (phase === 0 || phase === 2)">
                    <div>
                      <label> {{ isEnglish ? 'Marital status: ' : 'Estado civil: ' }} </label>
                      <span v-if="isEnglish">
                        <b> {{ personalInformation.maritalStatus === 'Unmarried' ? 'Single' : personalInformation.maritalStatus }} </b>
                      </span>
                      <span v-else>
                        <b> {{ personalInformation.maritalStatus === 'Unmarried' ? 'Soltero(a)' : 
                               personalInformation.maritalStatus === 'Married' ? 'Casado(a)' : '' }} </b>
                      </span>
                    </div>
                    <div v-if="personalInformation.spouseName">
                      <label> {{ isEnglish ? 'Spouse name: ' : 'Nombre de esposo(a): ' }} </label>
                      <span><b> {{ personalInformation.spouseName }} </b></span>
                    </div>
                    <div v-if="personalInformation.childrenAges">
                      <label> {{ isEnglish ? 'Children ages: ' : 'Edades de hijos: ' }} </label>
                      <span><b> {{ personalInformation.childrenAges }} </b></span>
                    </div>
                  </div> 
                  <div v-if="phase === 0 || phase === 1">
                    <br>    
                    <h4> {{ isEnglish ? 'Primary Address' : 'Domicilio Principal' }} </h4>        
                    <div>
                      <label> {{ isEnglish ? 'Address: ' : 'Dirección: ' }} </label>
                      <span><b> {{ personalInformation.primaryAddress1 }} </b></span>
                    </div>
                    <div v-if="personalInformation.primaryAddressApartmentNumber">
                      <label> {{ isEnglish ? 'Apt #: ' : 'Dpto #: ' }} </label>
                      <span><b> {{ personalInformation.primaryAddressApartmentNumber }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                      <span><b> {{ personalInformation.primaryAddressCity }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                      <span><b> {{ formatState(personalInformation.primaryAddressState) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Zip code: ' : 'Código postal: ' }} </label>
                      <span><b> {{ personalInformation.primaryAddressZipCode }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'How long have you lived at this address? ' : '¿Cuánto tiempo ha vivido en esta dirección? ' }} </label>
                      <span>
                        <b> 
                          {{ personalInformation.primaryAddressYears }} {{ isEnglish ? 'year(s) & ' : 'año(s) y ' }}  
                          {{ personalInformation.primaryAddressMonths }} {{ isEnglish ? 'month(s)' : 'mes(es)' }}
                        </b>
                      </span>
                    </div>
                    <br>
                    <div v-if="personalInformation.previousAddresses && personalInformation.previousAddresses.length > 0">
                      <h4> {{ isEnglish ? 'Previous Addresses' : 'Domicilio Previo' }} </h4>    
                      <div v-for="a in personalInformation.previousAddresses">
                        <div>
                          <label> {{ isEnglish ? 'Address: ' : 'Dirección: ' }} </label>
                          <span><b> {{ a.address1 }} </b></span>
                        </div>
                        <div v-if="a.apartmentNumber">
                          <label> {{ isEnglish ? 'Apt #: ' : 'Dpto #: ' }} </label>
                          <span><b> {{ a.apartmentNumber }} </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                          <span><b> {{ a.city }} </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                          <span><b> {{ formatState(a.state) }} </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'Zip code: ' : 'Código postal: ' }} </label>
                          <span><b> {{ a.zipCode }} </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'How long long did you live at this address? ' : '¿Cuánto tiempo vivió en esta dirección? ' }} </label>
                          <span>
                            <b> 
                              {{ a.years }} {{ isEnglish ? 'year(s) & ' : 'año(s) y ' }}
                              {{ a.months }} {{ isEnglish ? 'month(s)' : 'mes(es)' }}
                            </b>
                          </span>
                        </div>
                      </div> 
                      <br>                 
                    </div>
                    <h4> {{  isEnglish ? 'Drivers License or State ID' : 'Licencia o Identificación Estatal' }} </h4>
                    <div>
                      <label> {{ isEnglish ? 'ID number: ' : 'Número de identificación: ' }} </label>
                      <span><b> {{ personalInformation.driverLicenseNumber }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                      <span><b> {{ formatState(personalInformation.driverLicenseState) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo de licencia: ' }} </label>
                      <span><b> {{ formatLicenseType(personalInformation.driverLicenseType) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Expires on: ' : 'Fecha de vencimiento: ' }} </label>
                      <span><b> {{ formatDate(personalInformation.driverLicenseExpirationDate) }} </b></span>
                    </div>
                  </div>
                  <div v-if="phase === 0 || phase === 2">
                    <br>
                    <h4> {{ isEnglish ? 'Emergency Contact' : 'Contacto de Emergencia' }} </h4>              
                    <div>
                      <label> {{ isEnglish ? 'Name: ' : 'Nombre: ' }} </label>
                      <span><b> {{ personalInformation.emergencyContactName }} </b></span>
                    </div>             
                    <div>
                      <label> {{ isEnglish ? 'Cell phone number: ' : 'Número de teléfono celular: ' }} </label>
                      <span><b> {{ formatPhone(personalInformation.emergencyContactPhoneNumber) }} </b></span>
                    </div>            
                    <div v-if="personalInformation.emergencyContactAddress1">
                      <label> {{ isEnglish ? 'Address: ' : 'Dirección: ' }} </label>
                      <span><b> {{ personalInformation.emergencyContactAddress1 }} </b></span>
                    </div>
                    <div v-if="personalInformation.emergencyContactApartmentNumber">
                      <label> {{ isEnglish ? 'Apt #: ' : 'Dpto #: ' }} </label>
                      <span><b> {{ personalInformation.emergencyContactApartmentNumber }} </b></span>
                    </div>
                    <div v-if="personalInformation.emergencyContactCity">
                      <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                      <span><b> {{ personalInformation.emergencyContactCity }} </b></span>
                    </div>
                    <div v-if="personalInformation.emergencyContactState">
                      <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                      <span><b> {{ formatState(personalInformation.emergencyContactState) }} </b></span>
                    </div>
                    <div v-if="personalInformation.emergencyContactZipCode">
                      <label> {{ isEnglish ? 'Zip code: ' : 'Código postal: ' }} </label>
                      <span><b> {{ personalInformation.emergencyContactZipCode }} </b></span>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Work Eligibility -->
                <div v-if="s.id === 'work-eligibility'">
                  <div v-if="phase === 0 || phase === 1">
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Are you legally authorized to work in the United States? ' : 
                           '¿Está legalmente autorizado para trabajar en los Estados Unidos? ' }} 
                      </label>
                      <span><b> {{ workEligibility.isAuthorized ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                  </div>
                  <div v-if="phase === 0 || phase === 2">
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Will you now or in the future require sponsorship for employment visa status? ' : 
                           '¿Necesitará patrocinio, ahora o en el futuro, para su estatus de visa de trabajo? ' }}
                      </label>
                      <span><b> {{ workEligibility.requireSponsorship ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 'What date can you start work? ' : '¿Qué tan pronto puede empezar a trabajar? ' }}
                      </label>
                      <span><b> {{ formatDate(workEligibility.potentialStartDate) }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> {{ isEnglish ? 'Are you employed now? ' : '¿Está empleado ahora? ' }} </label>
                      <span><b> {{ workEligibility.isEmployedNow ? (isEnglish ? 'Yes' : 'Sí')  : 'No' }} </b></span>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Education & Military -->
                <div v-if="s.id === 'education-military'">
                  <div v-if="phase === 0 || phase === 1">
                    <div v-if="educationMilitary.highSchoolName">
                      <h4> {{ isEnglish ? 'High School' : 'Preparatoria' }} </h4>
                      <div>
                        <label> {{ isEnglish ? 'Name: ' : 'Nombre: ' }} </label>
                        <span><b> {{ educationMilitary.highSchoolName }} </b></span>
                      </div>
                      <div v-if="educationMilitary.highSchoolCity">
                        <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                        <span><b> {{ educationMilitary.highSchoolCity }} </b></span>
                      </div>
                      <div v-if="educationMilitary.highSchoolState">
                        <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                        <span><b> {{ formatState(educationMilitary.highSchoolState) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.highSchoolYearStarted">
                        <label> {{ isEnglish ? 'Year started: ' : 'Año de inicio: ' }} </label>
                        <span><b> {{ educationMilitary.highSchoolYearStarted }} </b></span>
                      </div>
                      <div v-if="educationMilitary.highSchoolYearCompleted">
                        <label> {{ isEnglish ? 'Year completed: ' : 'Año que terminó: ' }} </label>
                        <span><b> {{ educationMilitary.highSchoolYearCompleted }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Graduated: ' : '¿Se graduó? ' }} </label>
                        <span><b> {{ educationMilitary.highSchoolGraduated ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                      </div>
                      <div v-if="educationMilitary.highSchoolFinalYearCompleted">
                        <label> {{ isEnglish ? 'Final year completed: ' : 'Último año completado: ' }} </label>
                        <span>
                          <b> 
                          {{ isEnglish ? 
                             formatEducationYear(educationMilitary.highSchoolFinalYearCompleted) : 
                             formatEducationYearSpanish(educationMilitary.highSchoolFinalYearCompleted) }} 
                          </b>
                        </span>
                      </div>
                    </div>                    
                    <div v-if="educationMilitary.gedName">
                      <h4 class="mt-3"> GED </h4>
                      <div>
                        <label> {{ isEnglish ? 'Name: ' : 'Nombre: ' }} </label>
                        <span><b> {{ educationMilitary.gedName }} </b></span>
                      </div>
                      <div v-if="educationMilitary.gedCity">
                        <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }}</label>
                        <span><b> {{ educationMilitary.gedCity }} </b></span>
                      </div>
                      <div v-if="educationMilitary.gedState">
                        <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                        <span><b> {{ formatState(educationMilitary.gedState) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.gedYearStarted">
                        <label> {{ isEnglish ? 'Year started: ' : 'Año de inicio: ' }} </label>
                        <span><b> {{ educationMilitary.gedYearStarted }} </b></span>
                      </div>
                      <div v-if="educationMilitary.gedYearCompleted">
                        <label> {{ isEnglish ? 'Year completed: ' : 'Año que terminó: ' }} </label>
                        <span><b> {{ educationMilitary.gedYearCompleted }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Graduated: ' : '¿Se graduó? ' }} </label>
                        <span><b> {{ educationMilitary.gedGraduated ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                      </div>
                    </div>
                    <div v-if="educationMilitary.collegeUniversityName">
                      <h4 class="mt-3"> {{ isEnglish ? 'College or University' : 'Universidad' }} </h4>
                      <div>
                        <label> {{ isEnglish ? 'Name: ' : 'Nombre: ' }}</label>
                        <span><b> {{ educationMilitary.collegeUniversityName }} </b></span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityCity">
                        <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                        <span><b> {{ educationMilitary.collegeUniversityCity }} </b></span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityState">
                        <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                        <span><b> {{ formatState(educationMilitary.collegeUniversityState) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityYearStarted">
                        <label> {{ isEnglish ? 'Year started: ' : 'Año de inicio: ' }} </label>
                        <span><b> {{ educationMilitary.collegeUniversityYearStarted }} </b></span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityYearCompleted">
                        <label> {{ isEnglish ? 'Year completed: ' : 'Año que terminó: ' }} </label>
                        <span><b> {{ educationMilitary.collegeUniversityYearCompleted }} </b></span>
                      </div>
                      <div v-if="!isOffice">
                        <label> {{ isEnglish ? 'Graduated: ' : '¿Se graduó? ' }} </label>
                        <span><b> {{ educationMilitary.collegeUniversityGraduated ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityFinalYearCompleted">
                        <label> {{ isEnglish ? 'Final year completed: ' : 'Último año completado: ' }} </label>
                        <span>
                          <b> 
                          {{ isEnglish ? 
                             formatEducationYear(educationMilitary.collegeUniversityFinalYearCompleted) : 
                             formatEducationYearSpanish(educationMilitary.collegeUniversityFinalYearCompleted) }} 
                          </b>
                        </span>
                      </div>
                      <div v-if="educationMilitary.collegeUniversityDegree">
                        <label> {{ isEnglish ? 'Degree awarded: ' : 'Título recibido: ' }} </label>
                        <span><b> {{ educationMilitary.collegeUniversityDegree }} </b></span>
                      </div>
                    </div>
                    <br>
                  </div>
                  <div v-if="phase === 0 || phase === 2">
                    <div v-if="educationMilitary.extracurricularActivities">
                      <label> {{ isEnglish ? 'Extracurricular activities: ' : 'Actividades extracurriculares: ' }} </label>
                      <span><b> {{ educationMilitary.extracurricularActivities }} </b></span>
                    </div>
                    <div v-if="educationMilitary.specialSkills">
                      <label> {{ isEnglish ? 'Special skills: ' : 'Habilidades especiales: ' }} </label>
                      <span><b> {{ educationMilitary.specialSkills }} </b></span>
                    </div>
                    <div>
                      <label> 
                        {{ isEnglish ? 
                           'Are you a member of either the National Guard or Reserve? ' : 
                           '¿Es usted miembro de la Guardia Nacional o la Reserva? ' }} 
                      </label>
                      <span><b> {{ formatMilitaryNationalGuardOrReserve(educationMilitary.militaryNationalGuardOrReserve) }} </b></span>
                    </div>
                    <div v-if="educationMilitary.militaryNationalGuardOrReserve !== 'Neither'">  
                      <div v-if="educationMilitary.militaryServiceBranch">
                        <label> {{ isEnglish ? 'Service branch: ' : 'Sucursal de servicio: ' }} </label>
                        <span><b> {{ formatMilitaryServiceBranch(educationMilitary.militaryServiceBranch) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.militaryDateEntered">
                        <label> {{ isEnglish ? 'Date entered: ' : 'Fecha de ingreso: ' }} </label>
                        <span><b> {{ formatDate(educationMilitary.militaryDateEntered) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.militaryDateDischarged">
                        <label> {{ isEnglish ? 'Date discharged: ' : 'Fecha de alta: ' }} </label>
                        <span><b> {{ formatDate(educationMilitary.militaryDateDischarged) }} </b></span>
                      </div>
                      <div v-if="educationMilitary.militarySpecialty">
                        <label> {{ isEnglish ? 'Specialty: ' : 'Especialidad: ' }} </label>
                        <span><b> {{ educationMilitary.militarySpecialty }} </b></span>
                      </div>
                      <div v-if="educationMilitary.militaryFinalRankOrRate">
                        <label> {{ isEnglish ? 'Final rank/rate: ' : 'Último rango: ' }}  </label>
                        <span><b> {{ educationMilitary.militaryFinalRankOrRate }} </b></span>
                      </div>  
                    </div>  
                  </div>                               
                  <br>
                </div>                
                <!-- Previous Employment -->
                <div v-if="s.id === 'previous-employment'">
                  <div>
                    <label> 
                      {{ isEnglish ? 'Have you been employed before? ' : '¿Usted ha tenido empleo antes? ' }}
                    </label>
                    <span><b> {{ previousEmployment.hasPreviousJobs ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div> 
                  <div v-for="j in previousEmployment.jobs">
                    <h4 class="mt-3"> 
                      {{ isEnglish ? 'Job ' : 'Empleo ' }} 
                      {{ previousJobCount(previousEmployment, j) }} 
                    </h4>
                    <div>
                      <label> {{ isEnglish ? 'Company name: ' : 'Compañía: ' }} </label>
                      <span><b> {{ j.company }} </b></span>
                    </div> 
                    <div>
                      <label> {{ isEnglish ? 'Job title: ' : 'Título: ' }} </label>
                      <span><b> {{ j.jobTitle }} </b></span>
                    </div>                    
                    <div v-if="phase === 0 || phase === 2">   
                      <div>
                        <label> {{ isEnglish ? 'Supervisor name: ' : 'Nombre del supervisor: ' }} </label>
                        <span><b> {{ j.supervisorName }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Phone number: ' : 'Número de teléfono: ' }} </label>
                        <span><b> {{ formatPhone(j.supervisorPhoneNumber) }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Address: ' : 'Dirección: ' }} </label>
                        <span><b> {{ j.address.address1 }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'City: ' : 'Ciudad: ' }} </label>
                        <span><b> {{ j.address.city }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                        <span><b> {{ formatState(j.address.state) }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Zip code: ' : 'Código postal: ' }} </label>
                        <span><b> {{ j.address.zipCode }} </b></span>
                      </div>
                    </div>  
                    <div v-if="phase === 0 || phase === 1">    
                      <div>
                        <label> {{ isEnglish ? 'Start date: ' : 'Fecha de inicio: ' }} </label>
                        <span><b> {{ formatDate(j.startDate) }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Starting pay: ' : 'Paga inicial: ' }} </label>
                        <span><b> ${{ formatPay(j.startingPay) }} / {{ formatPayFrequency(j.startingFrequency) }} </b></span>
                      </div>
                      <div v-if="j.endDate === new Date().toISOString().substring(0, 10)">
                        <div>
                          <label> {{ isEnglish ? 'Currently employed? ' : '¿Actualmente empleado? ' }} </label>
                          <span><b> Yes </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'Current pay: ' : 'Paga actual: ' }} </label>
                          <span><b> ${{ formatPay(j.endingPay) }} / {{ formatPayFrequency(j.endingFrequency) }} </b></span>
                        </div>
                      </div>  
                      <div v-else>
                        <div>
                          <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                          <span><b> {{ formatDate(j.endDate) }} </b></span>
                        </div>
                        <div>
                          <label> {{ isEnglish ? 'Ending pay: ' : 'Paga final: ' }} </label>
                          <span><b> ${{ formatPay(j.endingPay) }} / {{ formatPayFrequency(j.endingFrequency) }} </b></span>
                        </div>
                      </div>
                    </div>  
                    <div v-if="(phase === 0 || phase === 2) && !isOffice">  
                      <div>
                        <label> {{ isEnglish ? 'Responsibilities: ' : 'Responsabilidades: ' }}</label>
                        <span><b> {{ j.responsibilities }} </b></span>
                      </div>
                    </div>
                    <div v-if="phase === 0 || phase === 1"> 
                      <div v-if="isTransportation">
                        <div>
                          <label> 
                            {{ isEnglish ? 
                              'Were you subject to federal motor carrier safety regulations? ' : 
                              '¿Estuvo sujeto a regulaciones federales para vehículos de transporte? ' }} 
                          </label>
                          <span><b> {{ j.isSubjectToFederalRegulations ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                        </div>
                        <div>
                          <label> 
                            {{ isEnglish ? 
                              'Was this job designated as a safety sensitive function in any DOT regulated mode ' + 
                              'subject to alcohol and controlled substance testing required by 49CFR Part 40? ' : 

                              '¿Era este empleo considerado una función de alta seguridad de acuerdo al Departamento ' + 
                              'de Transporte y estaba sujeto a pruebas de drogas y alcohol requeridas por el código ' + 
                              '49CFR parte 40?' }}
                          </label>
                          <span><b> {{ j.isSafetySensitiveFunction ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                        </div> 
                      </div>
                      <div>
                        <label>  
                          {{ isEnglish ? 
                             'Please state clearly your full reason(s) for leaving this job: ' : 
                             'Indique la razón (o razones) por las que dejó este empleo: ' }}
                        </label>
                        <span><b> {{ j.reasonForLeaving }} </b></span>
                      </div>                                       
                    </div>                                       
                    <div v-if="phase === 0 || phase === 2">
                      <div>
                        <label> {{ isEnglish ? 'May we contact this employer? ' : '¿Podemos contactar a este empleador? ' }} </label>
                        <span><b> {{ j.canContactEmployer ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                      </div>
                    </div>
                  </div>
                  <br>
                </div>                
                <!-- Work Experience -->
                <div v-if="s.id === 'work-experience'">
                  <div>
                    <label>
                      {{ isEnglish ? 
                         'Do you have any warehouse, transportation, or clerical work experience? ' : 
                         '¿Tiene experiencia laboral en trabajos de almacén, transporte, o administrativos? ' }} 
                    </label>
                    <span><b> {{ workExperience.hasWorkExperience ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>
                  <div v-for="w in workExperience.workExperiences">
                    <h4 class="mt-3"> 
                      {{ isEnglish ? 'Skill / Experience ' : 'Habilidad / Experiencia ' }}  
                      {{ workExperienceCount(workExperience, w) }} 
                    </h4>
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ formatWorkExperience(w) }} </b></span>
                    </div>
                    <div v-if="w.description">
                      <label> {{ isEnglish ? 'Description: ' : 'Descripción: ' }} </label>
                      <span><b> {{ w.description }} </b></span>
                    </div> 
                    <div>
                      <label> {{ isEnglish ? 'Company: ' : 'Compañía: ' }} </label>
                      <span><b> {{ w.location }} </b></span>
                    </div> 
                    <div>
                      <label> {{ isEnglish ? 'How long? ' : '¿Cuánto tiempo? ' }} </label>
                      <span><b> {{ w.years }} {{ isEnglish ? 'year(s)' : 'año(s)' }} / {{ w.months }} {{ isEnglish ? 'month(s)' : 'mes(es)' }} </b></span>
                    </div>  
                  </div>  
                  <br>
                </div>
                <!-- Background Questions -->
                <div v-if="s.id === 'background-questions'">
                  <div v-if="phase === 0 || phase === 1">                
                    <div 
                      v-if="requireBackgroundQuestion" 
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 
                           'Have you ever (i) been convicted of, or (ii) pled guilty to, or (iii) pled no contest to a violation of law, ' +
                           'other than a minor traffic violation? ' : 

                           '¿Alguna vez (i) ha sido condenado, (ii) se ha declarado culpable, o (iii) ' + 
                           'ha declarado no oposición a una violación de la ley que no haya sido una violación menor de tráfico? '}}
                      </label>
                      <span><b> {{ background.hasCriminalConvictions ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.hasCriminalConvictions" 
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 
                           'If yes, state the date, court, and place where offense occurred: ' : 
                           'Anote la fecha, el tribunal, y el lugar donde ocurrió la ofensa: ' }}
                      </label>
                      <span><b> {{ background.criminalConvictionDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Have you ever been discharged or requested to resign? ' : 
                           '¿Alguna vez ha sido despedido o se le ha pedido su renuncia? ' }}
                      </label>
                      <span><b> {{ background.hasPriorTerminations ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.hasPriorTerminations"
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'If yes, please explain: ' : 'Por favor explique: ' }}
                      </label>
                      <span><b> {{ background.priorTerminationDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Has your license ever been revoked, denied, or suspended? ' : 
                           '¿Alguna vez se le ha revocado, negado, o suspendido su licencia de conducir? ' }}
                      </label>
                      <span><b> {{ background.hasLicenseViolations ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.hasLicenseViolations"
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'If yes, please explain: ' : 'Por favor explique: ' }}
                      </label>
                      <span><b> {{ background.licenseViolationDetails }} </b></span>
                    </div> 
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'If applying for a warehouse or driving job, how many vehicular accidents have you had in the past 10 years? ' : 
                           'Si está aplicando a un trabajo de almacén o transporte, ¿cuántos accidentes de tránsito ha tenido en los últimos 10 años? ' }}
                      </label>
                      <span><b> {{ background.numberOfVehicularAccidents }} </b></span>
                    </div>
                    <div 
                      v-if="background.numberOfVehicularAccidents"
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'If yes, please give details: ' : 'Por favor explique: ' }}
                      </label>
                      <span><b> {{ background.vehicularAccidentDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'If applying for a warehouse or driving job, how many moving violations have you had in the past 3 years? ' : 
                           'Si está aplicando a un trabajo de almacén o transporte, ¿cuántos violaciones de tránsito ha tenido en los últimos 3 años? ' }}
                      </label>
                      <span><b> {{ background.numberOfMovingViolations }} </b></span>
                    </div>
                    <div 
                      v-if="background.numberOfMovingViolations"
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'If yes, please give details: ' : 'Por favor explique: ' }}
                      </label>
                      <span><b> {{ background.movingViolationDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Do you have family members who work for Labatt? ' : 
                           '¿Tiene algún familiar que trabaje para Labatt? ' }}
                      </label>
                      <span><b> {{ background.hasFamilyAtLabatt ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.hasFamilyAtLabatt"
                      class="mb-2"
                    >
                      <label> {{ isEnglish ? 'Who? ' : '¿Quién? ' }} </label>
                      <span><b> {{ background.familyAtLabattDetails }} </b></span>
                    </div>  
                  </div>
                  <div v-if="phase === 0 || phase === 2">
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'How do you plan on getting to work every day? ' : 
                           '¿Cómo planea llegar al trabajo todos los días? ' }}
                      </label>
                      <span><b> {{ background.getToWorkTransportation }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Are there any days or hours of the week you would not be able to work? ' : 
                           '¿Hay algún día u horas de la semana que no pueda trabajar? ' }}
                      </label>
                      <span><b> {{ background.daysAndWeeksUnavailable }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'Have you ever filed an application with Labatt? ' : 
                           '¿Ha solicitado trabajo en Labatt previamente? ' }}
                      </label>
                      <span><b> {{ background.isPreviousApplicant ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.isPreviousApplicant"
                      class="mb-2"
                    >
                      <label> {{ isEnglish ? 'When? ' : '¿Cuándo? ' }} </label>
                      <span><b> {{ formatDate(background.previousApplicantDate) }} </b></span>
                    </div>  
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 'Have you worked for Labatt before? ' : '¿Ha sido empleado de Labatt previamente? ' }}
                      </label>
                      <span><b> {{ background.isPreviousEmployee ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                    </div>
                    <div 
                      v-if="background.isPreviousEmployee"
                      class="mb-2"
                    >
                      <label> {{ isEnglish ? 'When? ' : '¿Cuándo? ' }} </label>
                      <span><b> {{ formatDate(background.previousEmployeeDate) }} </b></span>
                    </div>  
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'What attracts you to this job? ' : 
                           '¿Qué lo atrae a este trabajo? ' }}
                      </label>
                      <span><b> {{ formatJobAttractions(background.jobAttractions) }} </b></span>
                    </div> 
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                           'How were you referred to us? ' : 
                           '¿Cómo fue referido a nuestra compañía? ' }}
                      </label>
                      <span><b> {{ formatReferralSource(background.referralSourceCode) }} </b></span>
                    </div>
                    <div
                      v-if="background.referralSourceDetails" 
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'Details: ' : 'Detalles: ' }}
                      </label>
                      <span><b> {{ background.referralSourceDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 
                          'Getting this job is important to me because: ' : 
                          'Obtener este trabajo es importante para mí porque: ' }}
                      </label>
                      <span><b> {{ background.whyIsJobImportant }} </b></span>
                    </div>
                  </div>
                  <br>
                </div>
                <!-- Driver Information -->
                <div v-if="s.id === 'driver-information'">
                  <div v-for="d in driverInformation.driverLicenses">
                    <h4 class="mt-3"> 
                      {{ isEnglish ? 'Driver License' : 'Licencia de Conducir' }} 
                      {{ driverLicenseCount(driverInformation, d) }} 
                    </h4>
                    <div>
                      <label> {{ isEnglish ? 'State: ' : 'Estado: ' }} </label>
                      <span><b> {{ formatState(d.state) }} </b></span>
                    </div> 
                    <div>
                      <label> {{ isEnglish ? 'License number: ' : 'Número de licencia: ' }} </label>
                      <span><b> {{ d.number }} </b></span>
                    </div>  
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo de licencia: ' }} </label>
                      <span><b> {{ formatLicenseType(d.type) }} </b></span>
                    </div> 
                    <div>
                      <label> {{ isEnglish ? 'Expiration date: ' : 'Fecha de vencimiento: ' }} </label>
                      <span><b> {{ formatDate(d.expirationDate) }} </b></span>
                    </div>  
                  </div>  
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Have you ever been denied a license, permit, or privilege to operate a motor vehicle? ' : 
                         '¿Alguna vez se le ha negado la licencia, permiso o privilegio de operar un vehículo motorizado? ' }}
                    </label>
                    <span><b> {{ driverInformation.hasLicenseDenied ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>
                  <div 
                    v-if="driverInformation.hasLicenseDenied"
                    class="mb-2"
                  >
                    <label> {{ isEnglish ? 'Please explain: ' : 'Por favor explique: ' }} </label>
                    <span><b> {{ driverInformation.licenseDeniedDetails }} </b></span>
                  </div>   
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Has any license, permit, or privilege ever been suspended or revoked? ' : 
                         '¿Se le ha suspendido o revocado una licencia, permiso o privilegio de conducir? ' }}
                    </label>
                    <span><b> {{ driverInformation.hasLicenseSuspended ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>
                  <div 
                    v-if="driverInformation.hasLicenseSuspended"
                    class="mb-2"
                  >
                    <label> {{ isEnglish ? 'Please explain: ' : 'Por favor explique: ' }}  </label>
                    <span><b> {{ driverInformation.licenseSuspendedDetails }} </b></span>
                  </div>   
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Have you had any motor vehicle accidents in the past? ' : 
                         '¿Ha tenido algún accidente de tráfico en el pasado? ' }}
                    </label>
                    <span><b> {{ driverInformation.hasAccidentRecord ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>
                  <div 
                    v-if="driverInformation.hasAccidentRecord"
                    class="mb-2"
                  >
                    <div v-for="a in driverInformation.accidentRecords">
                      <h4 class="mt-3"> 
                        {{ isEnglish ? 'Accident' : 'Accidente' }} 
                        {{ accidentRecordCount(driverInformation, a) }} 
                      </h4>
                      <div>
                        <label> {{ isEnglish ? 'Date: ' : 'Fecha: ' }} </label>
                        <span><b> {{ formatDate(a.dateOfAccident) }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Nature: ' : 'Tipo: ' }} </label>
                        <span><b> {{ a.natureOfAccident }} </b></span>
                      </div> 
                      <div>
                        <label> {{ isEnglish ? '# Fatalities: ' : '# Muertes: ' }} </label>
                        <span><b> {{ a.fatalities }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? '# Injuries: ' : '# Heridos: ' }} </label>
                        <span><b> {{ a.injuries }} </b></span>
                      </div>
                    </div> 
                  </div>
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Have you had any traffic convictions in the past? ' : 
                         '¿Ha tenido alguna infracción de tránsito en el pasado? ' }}
                    </label>
                    <span><b> {{ driverInformation.hasTrafficConviction ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>
                  <div 
                    v-if="driverInformation.hasTrafficConviction"
                    class="mb-2"
                  >
                    <div v-for="t in driverInformation.trafficConvictions">
                      <h4 class="mt-3"> 
                        {{ isEnglish ? 'Traffic Conviction' : 'Infracción' }} 
                        {{ trafficConvictionCount(driverInformation, t) }} 
                      </h4>
                      <div>
                        <label> {{ isEnglish ? 'Date: ' : 'Fecha: ' }} </label>
                        <span><b> {{ formatDate(t.dateOfConviction) }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Location: ' : 'Ubicación: ' }} </label>
                        <span><b> {{ t.location }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Violation: ' : 'Violación: ' }} </label>
                        <span><b> {{ t.charge }} </b></span>
                      </div>
                      <div>
                        <label> {{ isEnglish ? 'Penalty: ' : 'Multa: ' }} </label>
                        <span><b> {{ t.penalty }} </b></span>
                      </div>
                    </div> 
                  </div> 
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Do you have experience with Straight Truck? ' : 
                         '¿Tiene experiencia manejando camiones no articulados? ' }}
                    </label>
                    <span><b> {{ getDrivingExperience('straight') ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>                   
                  <div 
                    v-if="getDrivingExperience('straight')"
                    class="mb-2"
                  >
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ getDrivingExperience('straight')?.equipmentType }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Start date: ' : 'Fecha inicial: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('straight')?.startDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('straight')?.endDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Approx miles: ' : 'Millas aproximadas: ' }} </label>
                      <span><b> {{ getDrivingExperience('straight')?.miles }} </b></span>
                    </div>
                  </div> 
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Do you have experience with Tractor - Semi Trailer? ' : 
                         '¿Tiene experiencia manejando tractores con semirremolque? ' }}
                    </label>
                    <span><b> {{ getDrivingExperience('semitrailer') ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>                   
                  <div 
                    v-if="getDrivingExperience('semitrailer')"
                    class="mb-2"
                  >
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ getDrivingExperience('semitrailer')?.equipmentType }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Start date: ' : 'Fecha inicial: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('semitrailer')?.startDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('semitrailer')?.endDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Approx miles: ' : 'Millas aproximadas: ' }} </label>
                      <span><b> {{ getDrivingExperience('semitrailer')?.miles }} </b></span>
                    </div>
                  </div> 
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Do you have experience with Tractor - Two Trailers? ' : 
                         '¿Tiene experiencia manejando tractores con doble remolque? ' }}
                    </label>
                    <span><b> {{ getDrivingExperience('twotrailers') ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>                   
                  <div 
                    v-if="getDrivingExperience('twotrailers')"
                    class="mb-2"
                  >
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ getDrivingExperience('twotrailers')?.equipmentType }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Start date: ' : 'Fecha inicial: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('twotrailers')?.startDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('twotrailers')?.endDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Approx miles: ' : 'Millas aproximadas: ' }} </label>
                      <span><b> {{ getDrivingExperience('twotrailers')?.miles }} </b></span>
                    </div>
                  </div> 
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Do you have experience with Motorcoach - Schoolbus? ' : 
                         '¿Tiene experiencia manejando autobuses turísticos o escolares? ' }}
                    </label>
                    <span><b> {{ getDrivingExperience('motorcoach') ? (isEnglish ? 'Yes' : 'Sí') : 'No' }} </b></span>
                  </div>                   
                  <div 
                    v-if="getDrivingExperience('motorcoach')"
                    class="mb-2"
                  >
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ getDrivingExperience('motorcoach')?.equipmentType }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Start date: ' : 'Fecha inicial: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('motorcoach')?.startDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('motorcoach')?.endDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Approx miles: ' : 'Millas aproximadas: ' }} </label>
                      <span><b> {{ getDrivingExperience('motorcoach')?.miles }} </b></span>
                    </div>
                  </div> 
                  <div class="mt-2 mb-2">
                    <label> 
                      {{ isEnglish ? 
                         'Do you have other experience? ' : 
                         '¿Tiene alguna otra experiencia? ' }}
                    </label>
                    <span><b> {{ getDrivingExperience('other') ? 'Yes' : 'No' }} </b></span>
                  </div>                   
                  <div 
                    v-if="getDrivingExperience('other')"
                    class="mb-2"
                  >
                    <div>
                      <label> {{ isEnglish ? 'Type: ' : 'Tipo: ' }} </label>
                      <span><b> {{ getDrivingExperience('other')?.equipmentType }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Start date: ' : 'Fecha inicial: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('other')?.startDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'End date: ' : 'Fecha final: ' }} </label>
                      <span><b> {{ formatDate(getDrivingExperience('other')?.endDate) }} </b></span>
                    </div>
                    <div>
                      <label> {{ isEnglish ? 'Approx miles: ' : 'Millas aproximadas: ' }} </label>
                      <span><b> {{ getDrivingExperience('other')?.miles }} </b></span>
                    </div>
                  </div>    
                  <br>
                </div>               
                <!-- EEO & AA -->
                <div v-if="s.id === 'eeo-aa'">
                  <div 
                    v-if="eeoaa.isEEOAAOmitted"
                    class="mb-2"
                  >
                    <label> 
                      {{ isEnglish ? 'Selected: ' : 'Seleccionado: ' }}
                    </label>
                    <span>
                      <b> 
                        {{ isEnglish ? 
                           'I do not wish to provide the information above' : 
                           'No deseo proveer la información anteriormente mencionada' }} 
                      </b>
                    </span>
                  </div>
                  <div v-else>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 'Race/national origin: ' : 'Raza/nacionalidad de origen: ' }}
                      </label>
                      <span><b> {{ formatEthnicity(eeoaa.ethnicityCode) }} </b></span>
                    </div>
                    <div 
                      v-if="eeoaa.ethnicityCode === 'OT'"
                      class="mb-2"
                    >
                      <label> 
                        {{ isEnglish ? 'Details: ' : 'Detalles: '}}
                      </label>
                      <span><b> {{ eeoaa.ethnicityDetails }} </b></span>
                    </div>
                    <div class="mb-2">
                      <label> 
                        {{ isEnglish ? 'Sex: ' : 'Sexo: ' }}
                      </label>
                      <span v-if="isEnglish"><b> {{ eeoaa.gender === 'Female' ? 'Female' : eeoaa.gender === 'Male' ? 'Male' : '' }} </b></span>
                      <span v-else><b> {{ eeoaa.gender === 'Female' ? 'Mujer' : eeoaa.gender === 'Male' ? 'Hombre' : '' }} </b></span>
                    </div>  
                  </div>                  
                  <br>                
                  <h4 class="mb-2"> 
                    {{ isEnglish ? 'Voluntary Self-Identification of Disability' : 'Auto-Identificación Voluntaria de Discapacidad' }} 
                  </h4>
                  <div class="mb-2">
                    <label> 
                      {{ isEnglish ? 'Selected: ' : 'Seleccionado: ' }}
                    </label>
                    <span v-if="eeoaa.isDisabledOmitted">
                      <b> {{ isEnglish ? 'I do not want to answer' : 'No quiero contestar' }} </b>
                    </span>
                    <span v-else-if="eeoaa.isDisabled">
                      <b> 
                        {{ isEnglish ? 
                           'Yes, I have a disability, or have had one in the past' : 
                           'Sí, tengo una discapacidad o la he tenido en el pasado' }}
                      </b>
                    </span>
                    <span v-else>
                      <b> 
                        {{ isEnglish ? 
                           'No, I do not have a disability and have not had one in the past' : 
                           'No, no tengo ninguna discapacidad ni la he tenido en el pasado' }}
                      </b>
                    </span>
                  </div>
                  <br>
                  <h4 class="mb-2"> 
                    {{ isEnglish ? 
                       'Voluntary Invitation to Self-Identify for Veterans' : 
                       'Invitación para Autoidentificarse Voluntariamente para Veteranos' }}
                  </h4>
                  <div class="mb-2">
                    <label> 
                      {{ isEnglish ? 'Selected: ' : 'Seleccionado: ' }}
                    </label>
                    <span v-if="eeoaa.isVeteranOmitted">
                      <b> {{ isEnglish ? 'I do not wish to answer' : 'No deseo contestar' }} </b>
                    </span>
                    <span v-else-if="eeoaa.isVeteran">
                      <b> 
                        {{ isEnglish ? 
                           'I identify as one or more of the classifications of protected veteran listed above' : 
                           'Me identifico con una o más de las clasificaciones  de veterano protegido' }} 
                      </b>
                    </span>
                    <span v-else>
                      <b> 
                        {{ isEnglish ? 
                           'I am not a protected veteran' : 
                           'No soy un veterano protegido' }} 
                      </b>
                    </span>
                  </div>
                  <br>
                </div>
              </div>
            </div>
            <!-- Only Transportation -->
            <div v-if="isTransportation">
              <hr class="mb-3">
              <div class="d-flex">
                <h4 class="mt-1 mb-3"> {{ isEnglish ? 'Applicant Verification' : 'Verificación de Solicitante' }} </h4>
              </div>
              <div class="font-sm">
                <div v-if="isEnglish">
                  This certifies that this application was completed by me, and that all entries on it and information in it are true and 
                  complete to the best of my knowledge.
                </div>
                <div v-else>
                  Esto certifica que esta solicitud fue llenada por mí y que todas las respuestas e información en ella son verídicas 
                  y fueron completadas a mi leal saber y entender.
                </div>
                <div 
                  v-if="!readMoreVerification"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreVerification = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreVerification">
                  <br>
                  <div v-if="isEnglish">
                    I authorize you to make such investigations and inquiries of my personal, employment, financial, or medical history and other related 
                    matters as may be necessary in arriving at an employment decision. (Generally, inquiries regarding medical history will be made 
                    only if and after a conditional offer of employment has been extended.) <br>
                    <br>
                    I hereby release employers, schools, health care providers and other persons from liability in responding to inquiries and
                    releasing information in connection with my application. <br>
                    <br>
                    In the event of employment, I understand that false or misleading information given in my application or interview(s) may 
                    result in discharge. I understand, also, that I am required to abide by all rules and regulations of the company. <br>
                    <br>
                    I agree that my electronic signature is the legally binding equivalent to my handwritten signature. <br>
                  </div>
                  <div v-else>
                    Autorizo a que la compañía haga investigaciones y consultas sobre mis asuntos personales, laborales, financieros, 
                    o médicos, o cualquier otra cuestión relacionada que sea necesaria para tomar una decisión de empleo. (En general, 
                    las consultas sobre historial médico serán llevadas a cabo solamente después de que la compañía le haya ofrecido una 
                    oferta condicional de empleo.) <br>
                    <br>
                    Por medio de la presente eximo a empleadores, escuelas, proveedores de servicios de salud, y otras personas de 
                    cualquier responsabilidad que resulte de responder a consultas y proveer información relacionadas con mi solicitud. <br>
                    <br>
                    En caso de ser contratado, entiendo que haber provisto información falsa o engañosa en mi solicitud o entrevista(s) 
                    puede resultar en despido. Entiendo también que estoy obligado a seguir todas las reglas y regulaciones de la compañía. <br>
                    <br>
                    Estoy de acuerdo con que mi firma electrónica sea el equivalente jurídicamente vinculante a mi firma manuscrita. <br>                    
                  </div>  
                </div> 
                <div 
                  v-if="readMoreVerification"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreVerification = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <!-- Signature --> 
                <div class="d-flex justify-center"> 
                  {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                </div>
                <v-text-field
                  v-model="app.applicantVerificationDetails"
                  :rules="required"
                  :readonly="app.isApplicantVerificationSigned"
                  class="tf-padding input-signature"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <div class="d-flex justify-center">
                  {{ isEnglish ? 'Signature' : 'Firma' }}
                </div>
              </div>
              <br>
            </div>
            <div>
              <hr class="mb-3">
              <div class="d-flex">
                <h4 class="mt-1 mb-3"> 
                  {{ isEnglish ? 'Applicant Agreement and Certification' : 'Acuerdo de Solicitante de Trabajo y Certificación' }}
                </h4>
              </div>
              <div class="font-sm">
                <div v-if="isEnglish">
                  "I certify that the information given by me in this application is true in all respects, and I agree that if the information given 
                  is found to be false in any way, it shall be considered sufficient cause for denial of employment or discharge. I authorize the use 
                  of any information in this application to verify my statements, and I authorize past employers, all references, and any other persons 
                  to answer all questions asked concerning my ability, character, reputation, and previous employment record. I release all such persons 
                  from any liability or damage on account of having furnished such information."
                </div>
                <div v-else>
                  "Certifico que la información que proporcioné en esta solicitud es verídica, y estoy de acuerdo con que si esta información resulta 
                  ser falsa, podrá ser considerada causa suficiente para ser rechazado de empleo o despedido. Autorizo el uso de cualquier información 
                  en esta solicitud para verificar mis declaraciones, y autorizo a mis empleadores anteriores, todas mis referencias, y cualquier otra 
                  persona a responder todas las preguntas sobre mis habilidades, carácter, reputación, e historial de empleo. Eximo a toda persona de 
                  cualquier responsabilidad o daños por causa de haber proporcionado dicha información."
                </div>
                <div 
                  v-if="!readMoreCertification"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreCertification = true"
                > 
                  <span><i>  {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreCertification">
                  <br>
                  <div v-if="isEnglish">
                    "I understand that nothing contained in this employment application or the granting of an interview is intended to create an employment 
                    contract between the company and myself for either employment or for the providing of any benefit. No promises regarding employment have 
                    been made to me, and I understand that no such promise or guarantee is binding upon the company unless made in writing. If an employment 
                    relationship is established, I understand that I have the right to terminate my employment at any time and the company retains the same right." <br>
                    <br>
                    <div v-if="isTransportation">
                      "I agree to take a physical examination and other qualification standards consistent with the 
                      DOT requirements following a conditional offer of employment, if given."
                    </div>
                    <div v-else> 
                      "I agree to take a physical examination and other qualification standards consistent with the DOT requirements following a conditional 
                      offer of employment, if given."
                    </div>                    
                    <br>
                    "I understand that if employed, policies and rules which are issued are not conditions of employment and that the employer may revise 
                    policies or procedures, in whole or in part, at any time." <br>
                    <br>
                    "I fully understand that my work schedule, in regard to the hours and days that I work, will be set by my supervisor; that I may be 
                    required to work irregular schedules as to days and hours of the week; and that I am subject to transfer between departments." <br>
                    <br>
                    "I understand that this application will be kept on file for 90 days from the date completed, after which time I would have to reapply 
                    in accordance with established company procedures." <br>
                    <br>
                    I agree that my electronic signature is the legally binding equivalent to my handwritten signature. <br>
                  </div>
                  <div v-else>
                    "Comprendo que ningún contenido de esta solicitud ni el hecho de obtener una entrevista tienen la intención de crear un contrato 
                    de trabajo entre la compañía y yo para ser empleado ni para proveer ningún beneficio. No se me ha dado ninguna promesa de empleo, 
                    y entiendo que ninguna promesa o garantía de este tipo es vinculante a la compañía a menos que sea por escrito. Si una relación de
                    empleo es establecida, entiendo que tengo el derecho de terminar mi empleo en cualquier momento y que la compañía mantiene el mismo 
                    derecho." <br>
                    <br>
                    <div v-if="isTransportation">
                      "Acepto tomar un examen físico y otros estándares de calificación de acuerdo con los requisitos del DOT luego de una oferta de 
                      empleo condicional, de ser necesario."
                    </div>
                    <div v-else> 
                      "Acepto tomar un examen físico y prueba de drogas después de una oferta de empleo condicional, de ser necesario."
                    </div>                    
                    <br>
                    "Comprendo que de ser empleado, las políticas y normas publicadas no son condiciones de empleo y que la compañía tiene el 
                    derecho de revisar dichas políticas y normas total o parcialmente en cualquier momento." <br>
                    <br>
                    "Comprendo que mi horario de trabajo, respecto a las horas y días que trabaje, será determinado por mi supervisor; que puede ser
                    requerido que trabaje horarios irregulares, en cuanto a las horas y días de la semana; y que puedo ser sujeto a transferencias 
                    entre departamentos." <br>
                    <br>
                    "Comprendo que esta solicitud será archivada por 90 días a partir de la fecha en que la complete, después de este tiempo tendré 
                    que reaplicar de acuerdo a los procedimientos establecidos por la compañía." <br>
                    <br>
                    Estoy de acuerdo con que mi firma electrónica sea el equivalente jurídicamente vinculante a mi firma manuscrita. <br>
                  </div>
                </div> 
                <div 
                  v-if="readMoreCertification"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreCertification = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <!-- Signature -->
                <div class="d-flex justify-center"> 
                  {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                </div>
                <v-text-field
                  v-model="app.applicantCertificationDetails"
                  :rules="required"
                  :readonly="app.isApplicantCertificationSigned"
                  class="tf-padding input-signature"
                  variant="outlined"
                  hide-details
                ></v-text-field> 
                <div class="d-flex justify-center">
                  {{ isEnglish ? 'Signature' : 'Firma' }}
                </div>         
              </div>
            </div>
          </div>    
          <br><br><br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent> 
        </v-form>
      </v-col>
    </v-row>
  </div>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import type { ApplicationSection, LicenseType, State, Ethnicity, ApplicationPersonalInfo, ApplicationWorkEligibility, 
  ApplicationEducationMilitary, ApplicationPreviousEmployment, PreviousJob, ApplicationWorkExperience,
  WorkExperience, ApplicationBackground, ApplicationDriverInformation, ApplicationEEOAA, ApplicationReview,
  DriverLicense, AccidentRecord, TrafficConviction, ReferralSource
} from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')

const sections = ref([] as ApplicationSection[])
const licenseTypes = ref([] as LicenseType[])
const states = ref([] as State[])
const ethnicities = ref([] as Ethnicity[])
const referralSources = ref([] as ReferralSource[])
const requireMaritalStatus = ref(true)
const requireBackgroundQuestion = ref(true)

const personalInformation = ref({} as ApplicationPersonalInfo)
const workEligibility = ref({} as ApplicationWorkEligibility)
const educationMilitary = ref({} as ApplicationEducationMilitary)
const previousEmployment = ref({} as ApplicationPreviousEmployment)
const workExperience = ref({} as ApplicationWorkExperience)
const background = ref({} as ApplicationBackground)
const driverInformation = ref({} as ApplicationDriverInformation)
const eeoaa = ref({} as ApplicationEEOAA)
const app = ref({} as ApplicationReview)

const readMoreVerification = ref(false)
const readMoreCertification = ref(false)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const section = computed(() => {
  if (phase.value === 1) {
    return isTransportation.value ? 7 : 6
  } 
  if (phase.value === 2) {
    return isOffice.value ? 7 : 8
  }
  if (isOffice.value) {
    return 7
  }
  if (isTransportation.value) {
    return 9
  }
  return 8
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  sections.value = store.applicationSections
  store.setIsApplicationReview(false)
  try {
    await getReview()
    await getPersonalInformation()
    await getWorkEligibility()
    await getEducationMilitary()
    await getPreviousEmployment()
    await getWorkExperience()
    await getBackground()
    await getDriverInformation()
    await getEEOAA()
  } catch (e) {
    error.value = true
    errorMessage.value = 'Please reload the page and try again'
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const getReview = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationReview(store.applicationId)
    if (r) {
      app.value = r
    }
  }
}

const getPersonalInformation = async () => {
  const r = await api.getOffices()
  if (r) {
    const office = r.find(o => o.code == store.selectedJobPosting?.officeCode)
    if (office?.requireMaritalStatus) {
      requireMaritalStatus.value = true
    } else {
      requireMaritalStatus.value = false
    }
  } else {
    showError(isEnglish.value ? 'Error loading personal information' : 'Error al cargar el formulario')
  }
  const r1 = await api.getLicenseTypes()
  if (r1) {
    licenseTypes.value = r1
  } else {
    showError(isEnglish.value ? 'Error loading personal information' : 'Error al cargar el formulario')
  }
  const r2 = await api.getStates()
  if (r2) {
    states.value = r2
  } else {
    showError(isEnglish.value ? 'Error loading personal information' : 'Error al cargar el formulario')
  }
  const r3 = await api.getEthnicities()
  if (r3) {
    ethnicities.value = r3
  } else {
    showError(isEnglish.value ? 'Error loading personal information' : 'Error al cargar el formulario')
  }
  if (store.applicationId) {
    const r4 = await api.getApplicationPersonalInfo(store.applicationId)
    if (r4) {
      personalInformation.value = r4
    }
  }
}

const getWorkEligibility = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationWorkEligibility(store.applicationId)
    if (r) {
      workEligibility.value = r
    }
  }
}

const getEducationMilitary = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationEducationMilitary(store.applicationId)
    if (r) {
      educationMilitary.value = r
    }
  }
}

const getPreviousEmployment = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationPreviousEmployment(store.applicationId)
    if (r) {
      previousEmployment.value = r
    }
  }
}

const getWorkExperience = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationWorkExperience(store.applicationId)
    if (r) {
      workExperience.value = r
    }
  }
}

const getBackground = async () => {
  const r = await api.getReferralSources()
  if (r) {
    referralSources.value = r
  } else {
    showError(isEnglish.value ? 'Error loading background information' : 'Error al cargar el formulario')
  }
  const r1 = await api.getOffices()
  if (r1) {
    const office = r1.find(o => o.code == store.selectedJobPosting?.officeCode)
    if (office?.requireBackgroundQuestion) {
      requireBackgroundQuestion.value = true
    } else {
      requireBackgroundQuestion.value = false
    }
  } else {
    showError(isEnglish.value ? 'Error loading background information' : 'Error al cargar el formulario')
  }
  if (store.applicationId) {
    const r2 = await api.getApplicationBackground(store.applicationId)
    if (r2) {
      background.value = r2
    }
  }
}

const getDriverInformation = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationDriverInformation(store.applicationId)
    if (r) {
      driverInformation.value = r
    }
  }
}

const getEEOAA = async () => {
  if (store.applicationId) {
    const r = await api.getApplicationEEOAA(store.applicationId)
    if (r) {
      eeoaa.value = r
    }
  }
}

const previousJobCount = (pe: ApplicationPreviousEmployment, j: PreviousJob) => {
  if (pe?.jobs) {
    return pe.jobs.indexOf(j) + 1
  }
  return 0
}

const workExperienceCount = (we: ApplicationWorkExperience, w: WorkExperience) => {
  if (we?.workExperiences) {
    return we.workExperiences.indexOf(w) + 1
  }
  return 0
}

const driverLicenseCount = (di: ApplicationDriverInformation, d: DriverLicense) => {
  if (di?.driverLicenses) {
    return di.driverLicenses.indexOf(d) + 1
  }
  return 0
}

const accidentRecordCount = (di: ApplicationDriverInformation, a: AccidentRecord) => {
  if (di?.accidentRecords) {
    return di.accidentRecords.indexOf(a) + 1
  }
  return 0
}

const trafficConvictionCount = (di: ApplicationDriverInformation, t: TrafficConviction) => {
  if (di?.trafficConvictions) {
    return di.trafficConvictions.indexOf(t) + 1
  }
  return 0
}

const formatDate = (d: string | null | undefined) => {
  if (d && d !== '0001-01-01') {
    return d.substring(5, 7) + '/' + d.substring(8, 10) + '/' + d.substring(0, 4)
  }
  return ''
}

const formatPhone = (p: number | null) => {
  if (p) {
    const str = p.toString()
    return '(' + str.substring(0, 3) + ') ' + str.substring(3, 6) + '-' + str.substring(6, 10)
  }
  return p
}

const formatSSN = (s: number | null) => {
  if (s) {
    const str = s.toString()
    return str.substring(0, 3) + '-' + str.substring(3, 5) + '-' + str.substring(5, 9)
  }
  return s
}

const formatState = (s: string | null) => {
  if (s) {
    if (isEnglish.value) {
      return states.value.find(ss => ss.id === s)?.name
    } else {
      return states.value.find(ss => ss.id === s)?.spanishName
    }
  }
  return s
}

const formatLicenseType = (l: string | null) => {
  if (l) {
    if (isEnglish.value) {
      return licenseTypes.value.find(t => t.id === l)?.name
    } else {
      return licenseTypes.value.find(t => t.id === l)?.spanishName
    }  
  }
  return l
}

const formatEthnicity = (e: string | null) => {
  if (e) {
    if (isEnglish.value) {
      return ethnicities.value.find(c => c.code === e)?.description
    } else {
      return ethnicities.value.find(c => c.code === e)?.spanishDescription
    }
  }
  return e
}

const formatPay = (p: number | null) => {
  if (p) {
    return p.toFixed(2)
  }
  return p
}

const formatPayFrequency = (f: string | null) => {
  if (f && !isEnglish.value) {
    if (f === 'Hour') {
      return 'Hora'
    } else if (f === 'Week') {
      return 'Semana'
    } else {
      return 'Año'
    }
  }
  return f
}

const formatEducationYear = (y: number | null) => {
  const options = [
    { id: 9, title: 'Freshman' }, 
    { id: 10, title: 'Sophomore' }, 
    { id: 11, title: 'Junior' }, 
    { id: 12, title: 'Senior' }
  ]
  const found = options.find(o => o.id === y)
  if (found) {
    return found.title 
  }
  return ''
}

const formatEducationYearSpanish = (y: number | null) => {
  const options = [
    { id: 9, title: 'Primero' }, 
    { id: 10, title: 'Segundo' }, 
    { id: 11, title: 'Tercero' }, 
    { id: 12, title: 'Cuarto' },
    { id: 15, title: 'Quinto' },
    { id: 16, title: 'Sexto' }
  ]
  const found = options.find(o => o.id === y)
  if (found) {
    return found.title 
  }
  return ''
}

const formatMilitaryNationalGuardOrReserve = (m: string | null) => {
  const options = [
    { id: 'NationalGuard', title: isEnglish.value ? 'National Guard' : 'Guardia Nacional' }, 
    { id: 'Reserve', title: isEnglish.value ? 'Reserve' : 'Reserva' }, 
    { id: 'Neither', title: isEnglish.value ? 'Neither' : 'Ninguno' }
  ]
  const found = options.find(o => o.id === m)
  if (found) {
    return found.title 
  }
  return ''
}

const formatMilitaryServiceBranch = (b: string | null) => {
  const options = [
    { id: 'AirForce', title: isEnglish.value ? 'Air Force' : 'Fuerza Aérea' }, 
    { id: 'Army', title: isEnglish.value ? 'Army' : 'Ejército' }, 
    { id: 'CoastGuard', title: isEnglish.value ? 'Coast Guard' : 'Guardia Costera' }, 
    { id: 'MarineCorps', title: isEnglish.value ? 'Marine Corps' : 'Infantería de Marina' }, 
    { id: 'Navy', title: isEnglish.value ? 'Navy' : 'Armada' }
  ]
  const found = options.find(o => o.id === b)
  if (found) {
    return found.title 
  }
  return ''
}

const formatWorkExperience = (w: WorkExperience | null) => {
  const workExperiences = [
    { id: '12', title: isEnglish.value ? 'Hard Physical Labor' : 'Trabajo Físico Pesado', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
    { id: '13', title: isEnglish.value ? 'Lifting For Long Periods' : 'Levantamiento por Períodos Prolongados', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
    { id: '14', title: isEnglish.value ? 'Working Nights' : 'Turno de Noche', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
    { id: '15', title: isEnglish.value ? 'Working Weekends' : 'Turno en Fin de Semana', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
    { id: '1', title: isEnglish.value ? 'Supervisor' : 'Supervisor', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '2', title: isEnglish.value ? 'Warehouse Worker' : 'Trabajo de Almacén', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '6', title: isEnglish.value ? 'Order Selector' : 'Selector de Pedidos', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '7', title: isEnglish.value ? 'Loader' : 'Cargador', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '8', title: isEnglish.value ? 'Stock Person' : 'Bodeguero', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '9', title: isEnglish.value ? 'Fork-lift Operator' : 'Operador de Montacargas', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '16', title: isEnglish.value ? 'Operate Pallet Jack' : 'Operador de Transpaleta', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '17', title: isEnglish.value ? 'Operate Tugger Or Tow Motor' : 'Operador de Tractor de Arrastre', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
    { id: '3', title: isEnglish.value ? 'Shipping Clerk' : 'Administrativo de Envío', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
    { id: '4', title: isEnglish.value ? 'Receiving Clerk' : 'Administrativo de Recepción', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
    { id: '5', title: isEnglish.value ? 'Inventory Clerk' : 'Administrativo de Inventario', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
    { id: '10', title: isEnglish.value ? 'Truck Driver' : 'Conductor de Camión', subtitle: isEnglish.value ? 'Transportation' : 'Transporte' }, 
    { id: '11', title: isEnglish.value ? 'Delivery Person' : 'Repartidor', subtitle: isEnglish.value ? 'Transportation' : 'Transporte' }, 
    { id: '18', title: isEnglish.value ? 'Specify' : 'Especifique', subtitle: isEnglish.value ? 'Other' : 'Otro' }
  ]
  const found = workExperiences.find(we => we.id === w?.code)
  if (found) {
    return found.subtitle + ' - ' + found.title
  }
  return ''
}

const formatJobAttractions = (jobAttractions: string[] | null) => {
  let list = [] as string[]
  const allJobAttractions = [
    { id: 'Pay', title: isEnglish.value ? 'Pay' : 'Paga' }, 
    { id: 'Secure', title: isEnglish.value ? 'Secure company' : 'Compañía estable' }, 
    { id: 'Place', title: isEnglish.value ? 'Nice place to work' : 'Lugar agradable para trabajar' }, 
    { id: 'Benefits', title: isEnglish.value ? 'Benefits' : 'Beneficios' }, 
    { id: 'Inside', title: isEnglish.value ? 'Working inside' : 'Trabajar adentro' }, 
    { id: 'Similar', title: isEnglish.value ? 'Similar work experience' : 'Experiencia de trabajo semejante' }, 
    { id: 'Close', title: isEnglish.value ? 'Close to home' : 'Cerca de casa' }, 
    { id: 'Products', title: isEnglish.value ? 'Products sold' : 'Los productos que se venden' }, 
    { id: 'Size', title: isEnglish.value ? 'Size of company' : 'El tamaño de la compañía' }, 
    { id: 'Type', title: isEnglish.value ? 'Type of work' : 'El tipo de trabajo' }, 
    { id: 'Need', title: isEnglish.value ? 'Need a job' : 'Necesita un trabajo' }, 
    { id: 'Friends', title: isEnglish.value ? 'Friends who work here' : 'Los amigos que trabajan aquí' }, 
  ]
  jobAttractions?.forEach(j => {
    const found = allJobAttractions.find(ja => ja.id === j)
    if (found) {
      list.push(found.title)
    }
  })
  return list.join(', ')
}

const formatReferralSource = (code: number| null) => {
  const found = referralSources.value.find(rs => rs.code === code)
  if (found) {
    return isEnglish.value ? found.description : found.spanishDescription
  }
  return ''
}

const getDrivingExperience = (c: string | null) => {
  if (driverInformation.value.drivingExperiences) {
    return driverInformation.value.drivingExperiences.find(d => d.equipmentClass === c)
  }
  return null
}

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isApplicantVerificationValid = () : boolean => {
  if (!isTransportation.value || app.value.applicantVerificationDetails) {
    return true
  } else {
    showError(isEnglish.value ? 
      'Please sign Applicant Verification section' : 
      'Por favor firme la sección de la Verificación de Solicitante')
    return false
  }
}

const isApplicantCertificationValid = () : boolean => {
  if (app.value.applicantCertificationDetails) {
    return true
  } else {
    showError(isEnglish.value ? 
      'Please sign Applicant Agreement and Certification section' : 
      'Por favor firme la sección del Acuerdo de Solicitante de Trabajo y Certificación')
    return false
  }
}

const editSection = (s: ApplicationSection) => {
  store.setIsApplicationReview(true)
  router.push('/application/' + s.id) 
}

const previous = async () => {
  if (phase.value === 1) {
    if (isTransportation.value) {
      router.push('/application/driver-information') 
    } else {
      router.push('/application/background-questions') 
    }
  } else {
    router.push('/application/eeo-aa') 
  }
}

const isFormValid = () => {
  if (!isApplicantVerificationValid()) {
    showError(isEnglish.value ? 
      'Please sign Applicant Verification section' : 
      'Por favor firme la sección de la Verificación de Solicitante')
    return false
  } else if (!isApplicantCertificationValid()) {
    showError(isEnglish.value ? 
      'Please sign Applicant Agreement and Certification section' : 
      'Por favor firme la sección del Acuerdo de Solicitante de Trabajo y Certificación')
    return false
  }
  return true
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value
    if (isTransportation.value) {
      app.value.isApplicantVerificationSigned = true
    }
    app.value.isApplicantCertificationSigned = true

    sections.value.forEach(s => {
      if (s.id === 'personal-information') {
        app.value.isPersonalInformationReviewed = true
      } else if (s.id === 'work-eligibility') {
        app.value.isWorkEligibilityReviewed = true
      } else if (s.id === 'education-military') {
        app.value.isEducationMilitaryReviewed = true
      } else if (s.id === 'previous-employment') {
        app.value.isPreviousEmploymentReviewed = true
      } else if (s.id === 'work-experience') {
        app.value.isWorkExperienceReviewed = true
      } else if (s.id === 'background-questions') {
        app.value.isBackgroundReviewed = true
      } else if (s.id === 'driver-information') {
        app.value.isDriverInformationReviewed = true
      } else if (s.id === 'eeo-aa') {
        app.value.isEEOAAReviewed = true
      }
    })
    try {
      const r = await api.updateApplicationReview(app.value)
      if (r === 'Success') {
        if (exit) {
          router.push('/application/dashboard')
        } else if (phase.value === 1) {
          router.push('/application/submitted') 
        } else {      
          router.push('/application/additional-forms') 
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving info' : 'Error al guardar la información')
    }
  }  
}
</script>

<style>
.read-more-toggle {
  font-size: 13px;
}
.read-more-toggle:hover {
  cursor: pointer;
}
</style>
