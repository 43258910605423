<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Loading -->
      <div v-if="loading">
        <br><br>
        <div class="d-flex justify-center">
          <v-progress-circular
            class="orange-spinner"
            indeterminate
          ></v-progress-circular>
        </div>      
      </div>
      <div v-else>
        <div v-if="jobNoLongerAvailable">
          <div class="d-flex justify-center title-bold mt-10 mb-2"> 
            {{ isEnglish ? 'This job is no longer available' : 'Este empleo ya no está disponible' }} 
          </div>
        </div>
        <div v-else>
          <!-- Job Posting -->
          <v-row>
            <v-col>
              <div class="d-flex justify-center title-bold mt-4 mb-2">
                {{ jobPosting?.title }}
              </div>
            </v-col>
          </v-row>
          <!-- Title -->
          <v-row>
            <v-col>
              <div class="d-flex justify-center title-bold"> 
                {{ isEnglish ? 'Submit information' : 'Envíe su información' }}
              </div>
            </v-col>
          </v-row>
          <!-- Subtitle -->
          <div class="d-flex justify-center subtitle s-title"> 
            {{ isEnglish ? 
              'Please use First name and Last name as seen on official ID' : 
              'Por favor use su Nombre y Apellido como está escrito en su identificación oficial' }} 
          </div>
          <!-- Alert -->
          <v-row v-if="error">
            <v-col>
              <v-alert          
                border="start"
                type="error"
                variant="tonal"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
          <!-- Form -->
          <v-row>
            <v-col>
              <v-form
                @submit.prevent="onSubmit()"
              >
                <div>
                  <!-- First Name -->
                  <v-text-field
                    v-model="qa.firstName"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'First name*' : 'Nombre*'"
                    variant="outlined"
                    hide-details
                    required
                  ></v-text-field>
                  <!-- Middle Name -->
                  <v-text-field
                    v-model="qa.middleName"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Middle name' : 'Segundo nombre'"
                    hide-details
                  ></v-text-field>
                  <!-- Last Name -->
                  <v-text-field
                    v-model="qa.lastName"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Last name*' : 'Apellido*'"
                    hide-details
                    required
                  ></v-text-field>
                  <!-- Email -->
                  <v-text-field
                    v-model="qa.email"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Email address*' : 'Correo electrónico*'"
                    hide-details
                    required
                  ></v-text-field>        
                  <!-- Phone Number -->
                  <v-text-field
                    v-model="phoneNumber"
                    :rules="phoneNumberRule"
                    :readonly="loading"
                    type="tel"
                    class="tf-padding"
                    :label="isEnglish ? 'Phone number*' : 'Número de teléfono*'"
                    variant="outlined"
                    v-mask="'(###) ###-####'"
                    hide-details
                    required
                  ></v-text-field>
                  <!-- Preferred Name -->
                  <v-text-field
                    v-model="qa.preferredName"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'Preferred name' : 'Nombre preferido'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Referral Source -->
                  <v-select
                    v-model="qa.referralSourceCode"
                    :rules="required"
                    :readonly="loading"
                    :items="referralSources"
                    item-value="code"
                    :item-title="isEnglish ? 'description' : 'spanishDescription'"
                    class="tf-padding"
                    :label="isEnglish ? 'How were you referred to us?*' : '¿Cómo fue referido a nuestra compañía?*'"
                    variant="outlined"
                    @update:modelValue="updateReferralSource()"
                    hide-details
                    required
                  ></v-select>            
                  <!-- Referral Source Details -->
                  <v-text-field
                    v-if="qa.hasReferralSourceDetails"
                    v-model="qa.referralSourceDetails"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="formatReferralSourceDetailLabel"
                    hide-details
                    required
                  ></v-text-field>
                </div>
                <!-- Preferred time to be contacted -->
                <div class="mb-2"> 
                  {{ isEnglish ? 
                    'Preferred time to be contacted*' : 
                    '¿A qué hora prefiere ser contactado?*' }} 
                </div>
                <v-checkbox 
                  v-model="qa.isTimeRange1"
                  :rules="preferredTimeRule"
                  :readonly="loading"
                  class="radio-btn"
                  hide-details
                  label="8:00 AM - 12:00 PM"
                  density="compact"
                ></v-checkbox>
                <v-checkbox 
                  v-model="qa.isTimeRange2"
                  :rules="preferredTimeRule"
                  :readonly="loading"
                  class="radio-btn"
                  hide-details
                  label="12:00 PM - 1:00 PM"
                  density="compact"
                  required
                ></v-checkbox>
                <v-checkbox 
                  v-model="qa.isTimeRange3"
                  :rules="preferredTimeRule"
                  :readonly="loading"
                  class="radio-btn"
                  hide-details
                  label="1:00 PM - 5:00 PM"
                  density="compact"
                ></v-checkbox>
                <div class="d-flex">
                  <v-checkbox 
                    v-model="qa.isTimeRangeOther"
                    :rules="preferredTimeRule"
                    :readonly="loading"
                    class="radio-btn"
                    hide-details
                    :label="isEnglish ? 'Other' : 'Otra hora'"
                    density="compact"
                  ></v-checkbox>
                  <v-text-field
                    v-if="qa.isTimeRangeOther"
                    v-model="qa.timeRangeOtherDetails"
                    :rules="required"
                    :readonly="loading"
                    class="ml-3"
                    :label="isEnglish ? 'Enter time' : '¿A qué hora?'"
                    variant="outlined"
                    density="compact"
                    hide-details
                  ></v-text-field>
                </div>
                <br>
                <!-- Submit -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :loading="loading"
                    class="btn submit-btn orange-btn" 
                    elevation="0"
                    type="submit"
                  >
                    {{ isEnglish ? 'Submit' : 'Enviar' }}
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/stores/store'
import api from '@/api'
import LabattLogo from '@/components/LabattLogo.vue'
import type { QuickApply, ReferralSource, JobPosting } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const jobPosting = ref(null as JobPosting | null)
const required = [(value: string) => !!value || 'Required']
const phoneNumberRule = [() => isPhoneNumberValid(false) || 'Invalid']
const preferredTimeRule = [() => isPreferredTimeValid(false) || 'Invalid']
const router = useRouter()
const route = useRoute()
const store = useStore()
const qa = ref({} as QuickApply)
const phoneNumber = ref('')
const referralSources = ref([] as ReferralSource[])
const jobNoLongerAvailable = ref(false)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    if (route.query.j) {
      jobPosting.value = await api.getJobPosting(route.query.j.toString())
      if (jobPosting.value) {
        if (route.query.h) {
          store.setWebHistory(route.query.h.toString())
        } else {
          store.setWebHistory(document.referrer)
        }
        const r = await api.getReferralSources()
        if (r) {
          referralSources.value = r
          if (jobPosting.value) {
            const officeCode = jobPosting.value.officeCode
            if (officeCode) {
              referralSources.value = r.filter(rs => rs.offices.includes(officeCode))
            }
            if (jobPosting.value.positionType === 'Office' || jobPosting.value.positionType === 'DSMOffice') {
              referralSources.value = referralSources.value.filter(rs => rs.isForResume === true)
            }
            if (isEnglish.value) {
              referralSources.value.sort((a, b) => a.description.localeCompare(b.description))
            } else {
              referralSources.value.sort((a, b) => a.spanishDescription.localeCompare(b.spanishDescription))
            }
          }
        } else {
          showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
        }
      } else {
        jobNoLongerAvailable.value = true
      }
    } else {
      jobNoLongerAvailable.value = true
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const updateReferralSource = () => {
  const selected = referralSources.value.find(r => r.code === qa.value.referralSourceCode)
  if (selected) {
    qa.value.hasReferralSourceDetails = selected.needDetails
  }
}

const formatReferralSourceDetailLabel = computed(() => {
  const found = referralSources.value.find(rs => rs.code === qa.value.referralSourceCode) 
  if (found) {
    if(found.description.includes('Labatt employee')) {
      return isEnglish.value ? 'Employee\'s name*' : 'Nombre del empleado*'
    }
    if(found.description.includes('Driving school')) {
      return isEnglish.value ? 'Name*' : 'Nombre*'
    }
  }
  return isEnglish.value ? 'Please explain*' : 'Por favor explique*'
})

const isFirstNameValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'First name is invalid' : 'Nombre no es válido'
  if (qa.value.firstName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isLastNameValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Last name is invalid' : 'Apellido no es válido'
  if (qa.value.lastName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isEmailValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Email address is invalid' : 'Correo electrónico no es válido'
  if (qa.value.email) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhoneNumberValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Phone number is invalid' : 'Número de teléfono no es válido'
  if (phoneNumber.value) {
    const phone = phoneNumber.value.toString()
    const pattern = new RegExp('^\\(?\\d{3}\\)?\\s ?\\d{3}-\\d{4}$')
    if(!phone.match(pattern)) {
      if (alert) {
        showError(msg)
      }
      return false
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (qa.value.referralSourceCode) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (qa.value.hasReferralSourceDetails === false || qa.value.referralSourceDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreferredTimeValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (qa.value.isTimeRange1 || qa.value.isTimeRange2 || qa.value.isTimeRange3 || qa.value.isTimeRangeOther) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreferredTimeOtherDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (qa.value.isTimeRangeOther === undefined || qa.value.isTimeRangeOther === false || qa.value.timeRangeOtherDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const convertToNumber = (v: string) : number => {
  if (v) {
    return parseInt(v.replace(/[^0-9]/g, ''))
  } 
  return 0
}

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isFormValid = () : boolean => {
  return isFirstNameValid(true) && 
    isLastNameValid(true) &&
    isEmailValid(true) &&
    isPhoneNumberValid(true) &&
    isReferralSourceValid(true) &&
    isReferralSourceDetailsValid(true) &&
    isPreferredTimeValid(true) &&
    isPreferredTimeOtherDetailsValid(true)
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    try {
      if (jobPosting.value) {
        if (qa.value.middleName) {
          // do nothing
        } else {
          qa.value.middleName = ''
        }
        qa.value.phoneNumber = convertToNumber(phoneNumber.value)
        if (qa.value.isTimeRange1) {
          // do nothing
        } else {
          qa.value.isTimeRange1 = false
        }
        if (qa.value.isTimeRange2) {
          // do nothing
        } else {
          qa.value.isTimeRange2 = false
        }
        if (qa.value.isTimeRange3) {
          // do nothing
        } else {
          qa.value.isTimeRange3 = false
        }
        if (qa.value.isTimeRangeOther) {
          // do nothing
        } else {
          qa.value.isTimeRangeOther = false
        }
        if (qa.value.preferredName) {
          // do nothing
        } else {
          qa.value.preferredName = ''
        }
        if (qa.value.referralSourceDetails) {
          // do nothing
        } else {
          qa.value.referralSourceDetails = ''
        }
        if (qa.value.timeRangeOtherDetails) {
          // do nothing
        } else {
          qa.value.timeRangeOtherDetails = ''
        }
        qa.value.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        qa.value.jobPostingId = jobPosting.value?.id
        qa.value.language = store.language
        const r = await api.submitQuickApply(qa.value)
        if (r === 'Success') {
          router.push('/quick-apply-submitted')
        } else {
          showError(r)
        }
      } else {
        showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
      }
    } catch (e) {
      showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style>
.s-title {
  margin-top: 15px;
  margin-bottom: 15px;
}
.submit-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
</style>
