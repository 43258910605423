import Axios from 'axios'
import { CurrentUser, NewUser, LoginUser, ResetUserPassword, HelpCategory, HelpUser, JobPosting, 
  ApplicationInfo, NewApplication, CancelApplication, ContinueApplication, LicenseType, State, Office, 
  ApplicationPersonalInfo, ApplicationWorkEligibility, ApplicationEducationMilitary,
  ApplicationPreviousEmployment, ApplicationWorkExperience, ApplicationDriverInformation, 
  ApplicationBackground, Ethnicity, ApplicationEEOAA, ApplicationReview, ApplicationAdditionalForms, 
  ReferralSource, QuickApply, Resume, College, FileDoc, Activity, SaveActivity, AccountInformation, 
  MultiPhaseApplicant } from '@/types'
import z from 'zod'

export default {
  async getCurrentUser(email : string) {
    const r = await Axios.get(`/api/users/current?email=${email}`)
    return CurrentUser.parse(r.data)
  },
  async getHelpCategories() : Promise<HelpCategory[]> {
    const r = await Axios.get('/api/users/help-categories')
    return z.array(HelpCategory).parse(r.data)
  },
  async createUser(user: NewUser) : Promise<string> {
    const r = await Axios.post('/api/users/create', user) 
    return r.data
  },
  async loginUser(user: LoginUser) : Promise<string> {
    const r = await Axios.post('/api/users/login', user) 
    return r.data
  },
  async verifyUser(email: string, code: string, language: string) : Promise<string> {
    const r = await Axios.post('/api/users/verify', { email: email, code: code, language: language }) 
    return r.data
  },
  async sendUserVerificationCode(email: string) : Promise<string> {
    const r = await Axios.post('/api/users/send-verification-code', { email: email }) 
    return r.data
  },
  async sendUserPasswordResetConfirmationCode(email: string, language: string) : Promise<string> {
    const r = await Axios.post('/api/users/send-password-reset-confirmation-code', { email: email, language: language }) 
    return r.data
  },
  async resetUserPassword(user: ResetUserPassword) : Promise<string> {
    const r = await Axios.post('/api/users/reset-password', user) 
    return r.data
  },
  async helpUser(user: HelpUser) : Promise<string> {
    const r = await Axios.post('/api/users/help', user) 
    return r.data
  },
  async getIsInternal() : Promise<boolean> {
    const r = await Axios.get('/api/users/is-internal') 
    return r.data
  },
  async updateAccountInformation(a: AccountInformation) : Promise<string> {
    const r = await Axios.put('/api/users/account-information', a) 
    return r.data
  },
  async getJobPosting(jobPostingId: string) : Promise<JobPosting | null> {
    const r = await Axios.get(`/api/applications/job-postings?id=${jobPostingId}`)
    if (r.data) {
      return JobPosting.parse(r.data)
    }
    return null
  },
  async getCurrentApplication(email: string) : Promise<ApplicationInfo | null> {
    const r = await Axios.get(`/api/applications/current?email=${email}`)
    if (r.data) {
      return ApplicationInfo.parse(r.data)
    }
    return null
  },
  async getPreviousApplications(email: string) : Promise<ApplicationInfo[]> {
    const r = await Axios.get(`/api/applications/previous?email=${email}`)
    return z.array(ApplicationInfo).parse(r.data)
  },
  async getJobSearchLink() : Promise<string> {
    const r = await Axios.get('/api/applications/job-search-link')
    return r.data
  },  
  async createApplication(app: NewApplication) : Promise<string> {
    const r = await Axios.post('/api/applications/create', app) 
    return r.data
  },
  async cancelApplication(app: CancelApplication) : Promise<string> {
    const r = await Axios.put('/api/applications/cancel', app) 
    return r.data
  },
  async continueApplication(app: ContinueApplication) : Promise<string> {
    const r = await Axios.put('/api/applications/continue', app) 
    return r.data
  },
  async getLicenseTypes() : Promise<LicenseType[]> {
    const r = await Axios.get('/api/applications/license-types')
    return z.array(LicenseType).parse(r.data)
  },
  async getStates() : Promise<State[]> {
    const r = await Axios.get('/api/applications/states')
    return z.array(State).parse(r.data)
  },
  async getOffices() : Promise<Office[]> {
    const r = await Axios.get('/api/applications/offices')
    return z.array(Office).parse(r.data)
  },
  async getEthnicities() : Promise<Ethnicity[]> {
    const r = await Axios.get('/api/applications/ethnicities')
    return z.array(Ethnicity).parse(r.data)
  },
  async getReferralSources() : Promise<ReferralSource[]> {
    const r = await Axios.get('/api/applications/referral-sources')
    return z.array(ReferralSource).parse(r.data)
  },
  async getColleges() : Promise<College[]> {
    const r = await Axios.get('/api/applications/colleges')
    return z.array(College).parse(r.data)
  },
  async getApplicationPersonalInfo(applicationId: string) : Promise<ApplicationPersonalInfo | null> {
    const r = await Axios.get(`/api/applications/personal-information?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationPersonalInfo.parse(r.data)
    }
    return null
  },
  async updateApplicationPersonalInfo(app: ApplicationPersonalInfo) : Promise<string> {
    const r = await Axios.put('/api/applications/personal-information', app) 
    return r.data
  },
  async getApplicationWorkEligibility(applicationId: string) : Promise<ApplicationWorkEligibility | null> {
    const r = await Axios.get(`/api/applications/work-eligibility?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationWorkEligibility.parse(r.data)
    }
    return null
  },
  async updateApplicationWorkEligibility(app: ApplicationWorkEligibility) : Promise<string> {
    const r = await Axios.put('/api/applications/work-eligibility', app) 
    return r.data
  },
  async getApplicationEducationMilitary(applicationId: string) : Promise<ApplicationEducationMilitary | null> {
    const r = await Axios.get(`/api/applications/education-military?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationEducationMilitary.parse(r.data)
    }
    return null
  },
  async updateApplicationEducationMilitary(app: ApplicationEducationMilitary) : Promise<string> {
    const r = await Axios.put('/api/applications/education-military', app) 
    return r.data
  },
  async getApplicationPreviousEmployment(applicationId: string) : Promise<ApplicationPreviousEmployment | null> {
    const r = await Axios.get(`/api/applications/previous-employment?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationPreviousEmployment.parse(r.data)
    }
    return null
  },
  async updateApplicationPreviousEmployment(app: ApplicationPreviousEmployment) : Promise<string> {
    const r = await Axios.put('/api/applications/previous-employment', app) 
    return r.data
  },
  async getApplicationWorkExperience(applicationId: string) : Promise<ApplicationWorkExperience | null> {
    const r = await Axios.get(`/api/applications/work-experience?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationWorkExperience.parse(r.data)
    }
    return null
  },
  async updateApplicationWorkExperience(app: ApplicationWorkExperience) : Promise<string> {
    const r = await Axios.put('/api/applications/work-experience', app) 
    return r.data
  },
  async getApplicationBackground(applicationId: string) : Promise<ApplicationBackground | null> {
    const r = await Axios.get(`/api/applications/background?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationBackground.parse(r.data)
    }
    return null
  },
  async updateApplicationBackground(app: ApplicationBackground) : Promise<string> {
    const r = await Axios.put('/api/applications/background', app) 
    return r.data
  },
  async getApplicationDriverInformation(applicationId: string) : Promise<ApplicationDriverInformation | null> {
    const r = await Axios.get(`/api/applications/driver-information?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationDriverInformation.parse(r.data)
    }
    return null
  },
  async updateApplicationDriverInformation(app: ApplicationDriverInformation) : Promise<string> {
    const r = await Axios.put('/api/applications/driver-information', app) 
    return r.data
  },
  async getApplicationEEOAA(applicationId: string) : Promise<ApplicationEEOAA | null> {
    const r = await Axios.get(`/api/applications/eeo-aa?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationEEOAA.parse(r.data)
    }
    return null
  },
  async updateApplicationEEOAA(app: ApplicationEEOAA) : Promise<string> {
    const r = await Axios.put('/api/applications/eeo-aa', app) 
    return r.data
  },
  async getApplicationReview(applicationId: string) : Promise<ApplicationReview | null> {
    const r = await Axios.get(`/api/applications/review?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationReview.parse(r.data)
    }
    return null
  },
  async updateApplicationReview(app: ApplicationReview) : Promise<string> {
    const r = await Axios.put('/api/applications/review', app) 
    return r.data
  },
  async getApplicationAdditionalForms(applicationId: string) : Promise<ApplicationAdditionalForms | null> {
    const r = await Axios.get(`/api/applications/additional-forms?applicationId=${applicationId}`)
    if (r.data) {
      return ApplicationAdditionalForms.parse(r.data)
    }
    return null
  },
  async updateApplicationAdditionalForms(app: ApplicationAdditionalForms) : Promise<string> {
    const r = await Axios.put('/api/applications/additional-forms', app) 
    return r.data
  },
  async submitQuickApply(qa: QuickApply) : Promise<string> {
    const r = await Axios.post('/api/applications/submit-quick-apply', qa) 
    return r.data
  },
  async submitResume(resume: Resume) : Promise<string> {
    const r = await Axios.post('/api/applications/submit-resume', resume) 
    return r.data
  },
  async uploadFile(file: FileDoc) : Promise<string> {
    const r = await Axios.post('/api/applications/upload-file', file) 
    return r.data
  },
  async deleteFile(file: FileDoc) : Promise<string> {
    const r = await Axios.post('/api/applications/delete-file', file) 
    return r.data
  },
  async getTakeActivities(email: string) : Promise<boolean> {
    const r = await Axios.get(`/api/applications/take-activities?email=${email}`)
    return r.data
  },
  async getActivities(email: string) : Promise<Activity[]> {
    const r = await Axios.get(`/api/applications/activities?email=${email}`)
    return z.array(Activity).parse(r.data)
  },
  async getActivitiesCompleted(email: string) : Promise<number> {
    const r = await Axios.get(`/api/applications/activities-completed?email=${email}`)
    return r.data
  },
  async saveActivity(activity: SaveActivity) : Promise<string> {
    const r = await Axios.post('/api/applications/save-activity', activity) 
    return r.data
  },
  async getMultiPhaseApplicant(applicationId: string) : Promise<MultiPhaseApplicant | null> {
    const r = await Axios.get(`/api/applications/multi-phase-applicant?applicationId=${applicationId}`)
    if (r.data) {
      return MultiPhaseApplicant.parse(r.data)
    }
    return null
  },
}