<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="section" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- View -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Driver Information' : 'Historial de Conducir' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <div> List all driver's licenses you have held for the past 3 years: </div>
          <div v-for="d in driverLicenses">
            <v-row>
              <v-col cols="8">
                <h4 class="mb-3 mt-3"> 
                  {{ isEnglish ? 'Driver License' : 'Licencia de Conducir' }} 
                  ({{ driverLicenses.indexOf(d) + 1 }}) </h4>
              </v-col>
              <v-col>
                <div class="d-flex justify-end">
                  <v-btn 
                    v-if="driverLicenses.indexOf(d) > 0"
                    class="btn mt-1" 
                    variant="tonal"
                    elevation="0"
                    @click="deleteDriverLicense(d)"
                  >
                    {{ isEnglish ? 'Delete' : 'Eliminar' }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <!-- License State -->
            <v-select
              v-model="d.state"
              :rules="required"
              :readonly="loading"
              :items="states"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'State*' : 'Estado*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- License Number -->
            <v-text-field
              v-model="d.number"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'License number*' : 'Número de licencia*'"
              variant="outlined"
              v-mask="'XXXXXXXXXXXXXXXXXXXX'"
              hide-details
            ></v-text-field>
            <!-- Type -->
            <v-select
              v-model="d.type"
              :rules="required"
              :readonly="loading"
              :items="licenseTypes"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'Type*' : 'Tipo de licencia*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- License Expiration Date -->
            <v-text-field
              v-model="d.expirationDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Expiration date*' : 'Fecha de vencimiento*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <!-- Add Another License - Btn -->
          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn 
                  class="btn" 
                  elevation="0"
                  variant="tonal"
                  @click="addDriverLicense()"
                >
                  {{ isEnglish ? 'Add another license' : 'Agregar otra licencia' }}
                </v-btn>
              </div>
            </v-col>
          </v-row> 
          <br>
          <!-- License Denied -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Have you ever been denied a license, permit, or privilege to operate a motor vehicle?*' : 
               '¿Alguna vez se le ha negado la licencia, permiso o privilegio de operar un vehículo motorizado?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasLicenseDenied"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <!-- License Denied Details -->
          <div v-if="app.hasLicenseDenied">
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 'Please explain*' : 'Por favor explique*' }}
            </div>
            <v-textarea
              v-model="app.licenseDeniedDetails"
              :rules="required"
              :readonly="loading"
              row-height="20"
              rows="2"
              auto-grow
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-textarea> 
          </div>
          <!-- License Suspended -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Has any license, permit, or privilege ever been suspended or revoked?*' : 
               '¿Se le ha suspendido o revocado una licencia, permiso o privilegio de conducir?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasLicenseSuspended"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <!-- License Suspended Details -->
          <div v-if="app.hasLicenseSuspended">
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 'Please explain*' : 'Por favor explique*' }}
            </div>
            <v-textarea
              v-model="app.licenseSuspendedDetails"
              :rules="required"
              :readonly="loading"
              row-height="20"
              rows="2"
              auto-grow
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-textarea>
          </div>
          <!-- Accident Record -->
          <h4 class="mb-3 mt-3"> {{ isEnglish ? 'Accident Record' : 'Historial de Accidentes de Tránsito' }} </h4>
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Have you had any motor vehicle accidents in the past?*' : 
               '¿Ha tenido algún accidente de tráfico en el pasado?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasAccidentRecord"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
            @update:modelValue="updateAccidentRecord()"
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="app.hasAccidentRecord">
            <div v-for="a in accidentRecords">
              <v-row>
                <v-col cols="8">
                  <h4 class="mb-3 mt-3"> 
                    {{ isEnglish ? 'Accident' : 'Accidente' }} 
                    ({{ accidentRecords.indexOf(a) + 1 }}) 
                  </h4>
                </v-col>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn 
                      v-if="accidentRecords.indexOf(a) > 0"
                      class="btn mt-1" 
                      variant="tonal"
                      elevation="0"
                      @click="deleteAccidentRecord(a)"
                    >
                      {{ isEnglish ? 'Delete' : 'Eliminar' }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- Accident Date -->
              <v-text-field
                v-model="a.dateOfAccident"
                :rules="required"
                :readonly="loading"
                type="date"
                class="tf-padding"
                :label="isEnglish ? 'Date*' : 'Fecha*'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Accident Nature -->
              <v-text-field
                v-model="a.natureOfAccident"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Nature*' : 'Tipo*'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- # Fatalities -->
              <v-text-field
                v-model="a.fatalities"
                :rules="numberRule"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? '# Fatalities*' : '# Muertes*'"
                variant="outlined"
                v-mask="'###'"
                hide-details
              ></v-text-field>
              <!-- # Injuries -->
              <v-text-field
                v-model="a.injuries"
                :rules="numberRule"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? '# Injuries*' : '# Heridos*'"
                variant="outlined"
                v-mask="'###'"
                hide-details
              ></v-text-field>
            </div>
            <!-- Add Another Accident - Btn -->
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn 
                    class="btn" 
                    elevation="0"
                    variant="tonal"
                    @click="addAccidentRecord()"
                  >
                    {{ isEnglish ? 'Add another accident' : 'Agregar otro accidente' }}
                  </v-btn>
                </div>
              </v-col>
            </v-row> 
            <br>
          </div>
          <!-- Traffic Convictions -->
          <h4 class="mb-3 mt-3"> {{ isEnglish ? 'Traffic Convictions' : 'Historial de Infracciones de Tránsito' }} </h4>
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Have you had any traffic convictions in the past?*' : 
               '¿Ha tenido alguna infracción de tránsito en el pasado?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasTrafficConviction"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
            @update:modelValue="updateTrafficConviction()"
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="app.hasTrafficConviction">
            <div v-for="t in trafficConvictions">
              <v-row>
                <v-col cols="8">
                  <h4 class="mb-3 mt-3"> 
                    {{ isEnglish ? 'Traffic Conviction' : 'Infracción' }} 
                    ({{ trafficConvictions.indexOf(t) + 1 }}) </h4>
                </v-col>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn 
                      v-if="trafficConvictions.indexOf(t) > 0"
                      class="btn mt-1" 
                      variant="tonal"
                      elevation="0"
                      @click="deleteTrafficConviction(t)"
                    >
                      {{ isEnglish ? 'Delete' : 'Eliminar' }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- Date -->
              <v-text-field
                v-model="t.dateOfConviction"
                :rules="required"
                :readonly="loading"
                type="date"
                class="tf-padding"
                :label="isEnglish ? 'Date*' : 'Fecha*'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Location -->
              <v-text-field
                v-model="t.location"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Location*' : 'Ubicación*'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Violation -->
              <v-text-field
                v-model="t.charge"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Violation*' : 'Violación*'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Penalty -->
              <v-text-field
                v-model="t.penalty"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Penalty*' : 'Multa*'"
                variant="outlined"
                hide-details
              ></v-text-field>
            </div>
            <!-- Add Another Traffic Conviction - Btn -->
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn 
                    class="btn" 
                    elevation="0"
                    variant="tonal"
                    @click="addTrafficConviction()"
                  >
                    {{ isEnglish ? 'Add another traffic conviction' : 'Agregar otra infracción' }}
                  </v-btn>
                </div>
              </v-col>
            </v-row> 
            <br>
          </div>
          <!-- Driving Experience -->
          <h4 class="mb-3 mt-3"> 
            {{ isEnglish ? 'Driving Experience' : 'Experiencia de Conducir' }} 
          </h4>
          <!-- Straight Truck -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have experience with Straight Truck?*' : 
               '¿Tiene experiencia manejando camiones no articulados?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="hasStraightTruckExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="hasStraightTruckExperience">
            <!-- Type -->
            <v-text-field
              v-model="straightTruckExperience.equipmentType"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Type (Van, Truck, Flat, etc.)*' : 'Tipo (Van, Camión, Plataforma, etc.)*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Start Date -->
            <v-text-field
              v-model="straightTruckExperience.startDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Start date*' : 'Fecha inicial*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- End Date -->
            <v-text-field
              v-model="straightTruckExperience.endDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'End date*' : 'Fecha final*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Approx Miles -->
            <v-text-field
              v-model="straightTruckExperience.miles"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Approx miles*' : 'Millas aproximadas*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <!-- Tractor - Semi Trailer -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have experience with Tractor - Semi Trailer?*' : 
               '¿Tiene experiencia manejando tractores con semirremolque?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="hasTractorSemiTrailerExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="hasTractorSemiTrailerExperience">
            <!-- Type -->
            <v-text-field
              v-model="tractorSemiTrailerExperience.equipmentType"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Type (Van, Truck, Flat, etc.)*' : 'Tipo (Van, Camión, Plataforma, etc.)*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Start Date -->
            <v-text-field
              v-model="tractorSemiTrailerExperience.startDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Start date*' : 'Fecha inicial*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- End Date -->
            <v-text-field
              v-model="tractorSemiTrailerExperience.endDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'End date*' : 'Fecha final*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Approx Miles -->
            <v-text-field
              v-model="tractorSemiTrailerExperience.miles"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Approx miles*' : 'Millas aproximadas*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <!-- Tractor - Two Trailers -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have experience with Tractor - Two Trailers?*' : 
               '¿Tiene experiencia manejando tractores con doble remolque?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="hasTractorTwoTrailersExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="hasTractorTwoTrailersExperience">
            <!-- Type -->
            <v-text-field
              v-model="tractorTwoTrailersExperience.equipmentType"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Type (Van, Truck, Flat, etc.)*' : 'Tipo (Van, Camión, Plataforma, etc.)*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Start Date -->
            <v-text-field
              v-model="tractorTwoTrailersExperience.startDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Start date*' : 'Fecha inicial*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- End Date -->
            <v-text-field
              v-model="tractorTwoTrailersExperience.endDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'End date*' : 'Fecha final*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Approx Miles -->
            <v-text-field
              v-model="tractorTwoTrailersExperience.miles"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Approx miles*' : 'Millas aproximadas*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <!-- Motorcoach - Schoolbus -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have experience with Motorcoach - Schoolbus?*' : 
               '¿Tiene experiencia manejando autobuses turísticos o escolares?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="hasSchoolbusExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="hasSchoolbusExperience">
            <!-- Type -->
            <v-text-field
              v-model="schoolbusExperience.equipmentType"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Type (Van, Truck, Flat, etc.)*' : 'Tipo (Van, Camión, Plataforma, etc.)*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Start Date -->
            <v-text-field
              v-model="schoolbusExperience.startDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Start date*' : 'Fecha inicial*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- End Date -->
            <v-text-field
              v-model="schoolbusExperience.endDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'End date*' : 'Fecha final*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Approx Miles -->
            <v-text-field
              v-model="schoolbusExperience.miles"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Approx miles*' : 'Millas aproximadas*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <!-- Other -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have other experience?*' : 
               '¿Tiene alguna otra experiencia?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="hasOtherExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="hasOtherExperience">
            <!-- Type -->
            <v-text-field
              v-model="otherExperience.equipmentType"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Type (Van, Truck, Flat, etc.)*' : 'Tipo (Van, Camión, Plataforma, etc.)*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Start Date -->
            <v-text-field
              v-model="otherExperience.startDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Start date*' : 'Fecha inicial*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- End Date -->
            <v-text-field
              v-model="otherExperience.endDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'End date*' : 'Fecha final*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Approx Miles -->
            <v-text-field
              v-model="otherExperience.miles"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Approx miles*' : 'Millas aproximadas*'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>            
          <br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent> 
        </v-form>
      </v-col>
    </v-row>  
  </div>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import { State, LicenseType, DriverLicense, AccidentRecord, TrafficConviction, DrivingExperience, ApplicationSection, ApplicationDriverInformation } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const yesOrNoRule = [(value: string) => isYesOrNoValid(value) || 'Required']
const numberRule = [(value: string) => isNumberValid(value) || 'Invalid']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const states = ref([] as State[])
const licenseTypes = ref([] as LicenseType[])
const app = ref({} as ApplicationDriverInformation)
const driverLicenses = ref([] as DriverLicense[])
const accidentRecords = ref([] as AccidentRecord[])
const trafficConvictions = ref([] as TrafficConviction[])
const hasStraightTruckExperience = ref(null as boolean | null)
const straightTruckExperience = ref({ equipmentClass: 'straight' } as DrivingExperience)
const hasTractorSemiTrailerExperience = ref(null as boolean | null)
const tractorSemiTrailerExperience = ref({ equipmentClass: 'semitrailer' } as DrivingExperience)
const hasTractorTwoTrailersExperience = ref(null as boolean | null)
const tractorTwoTrailersExperience = ref({ equipmentClass: 'twotrailers' } as DrivingExperience)
const hasSchoolbusExperience = ref(null as boolean | null)
const schoolbusExperience = ref({ equipmentClass: 'motorcoach' } as DrivingExperience)
const hasOtherExperience = ref(null as boolean | null)
const otherExperience = ref({ equipmentClass: 'other' } as DrivingExperience)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const section = computed(() => {
  return phase.value === 1 ? 6 : 7
})

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isYesOrNoValid = (v: any) : boolean => {
  return v || v === true || v === false
}

const isNumberValid = (v: any) : boolean => {
  return v !== null && v >= 0
}

const isDriverLicenseValid = (alert: boolean) : boolean => {
  if (driverLicenses.value.length) {
    let isValid = true
    driverLicenses.value.every((d: DriverLicense) => {
      if (d.state) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Driver license state is invalid' : 'Estado de la licencia de conducir no es válido')
        }
        return false
      }
      if (d.number) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Driver license number is invalid' : 'Número de la licencia de conducir no es válido')
        }
        return false
      }
      if (d.type) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Driver license type is invalid' : 'Tipo de la licencia de conducir no es válido')
        }
        return false
      }
      if (d.expirationDate) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Driver license expiration date is invalid' : 'Fecha de vencimiento de la licencia de conducir no es válida')
        }
        return false
      }
      return true
    })
    if(!isValid) {
      return false
    }      
  }
  return true 
}

const isHasLicenseDeniedValid = (alert: boolean) : boolean => {
  if (app.value.hasLicenseDenied === true || app.value.hasLicenseDenied === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isLicenseDeniedDetailsValid = (alert: boolean) : boolean => {
  if (app.value.hasLicenseDenied === false || app.value.licenseDeniedDetails) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasLicenseSuspendedValid = (alert: boolean) : boolean => {
  if (app.value.hasLicenseSuspended === true || app.value.hasLicenseSuspended === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isLicenseSuspendedDetailsValid = (alert: boolean) : boolean => {
  if (app.value.hasLicenseSuspended === false || app.value.licenseSuspendedDetails) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasAccidentRecordValid = (alert: boolean) : boolean => {
  if (app.value.hasAccidentRecord === true || app.value.hasAccidentRecord === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isAccidentRecordValid = (alert: boolean) : boolean => {
  if (app.value.hasAccidentRecord && accidentRecords.value.length) {
    let isValid = true
    accidentRecords.value.every((a: AccidentRecord) => {
      if (a.dateOfAccident) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Accident date is invalid' : 'Fecha de accidente no es válida')
        }
        return false
      }
      if (a.natureOfAccident) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Accident nature is invalid' : 'Naturaleza de accidente no es válida')
        }
        return false
      }
      if (a.fatalities !== null && a.fatalities >= 0) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Accident fatalities is invalid' : '# Muertes de accidente no es válido')
        }
        return false
      }
      if (a.injuries !== null && a.injuries >= 0) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Accident injuries is invalid' : '# Heridos de accidente no es válido')
        }
        return false
      }
      return true
    })
    if(!isValid) {
      return false
    }      
  }
  return true 
}

const isHasTrafficConvictionValid = (alert: boolean) : boolean => {
  if (app.value.hasTrafficConviction === true || app.value.hasTrafficConviction === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isTrafficConvictionValid = (alert: boolean) : boolean => {
  if (app.value.hasTrafficConviction && trafficConvictions.value.length) {
    let isValid = true
    trafficConvictions.value.every((t: TrafficConviction) => {
      if (t.dateOfConviction) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Traffic conviction date is invalid' : 'Fecha de convicción no es válida')
        }
        return false
      }
      if (t.location) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Traffic conviction location is invalid' : 'Ubicación de convicción no es válida')
        }
        return false
      }
      if (t.charge) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Traffic conviction violation is invalid' : 'Violación de convicción no es válida')
        }
        return false
      }
      if (t.penalty) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Traffic conviction penalty is invalid' : 'Multa de convicción no es válida')
        }
        return false
      }
      return true
    })
    if(!isValid) {
      return false
    }      
  }
  return true 
}

const isHasStraightTruckExperienceValid = (alert: boolean) : boolean => {
  if (hasStraightTruckExperience.value === true || hasStraightTruckExperience.value === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isStraightTruckExperienceValid = (alert: boolean) : boolean => {
  if (hasStraightTruckExperience.value === false || (straightTruckExperience.value.equipmentType &&
    straightTruckExperience.value.startDate && straightTruckExperience.value.endDate &&
    straightTruckExperience.value.miles !== null)) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasTractorSemiTrailerExperienceValid = (alert: boolean) : boolean => {
  if (hasTractorSemiTrailerExperience.value === true || hasTractorSemiTrailerExperience.value === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isTractorSemiTrailerExperienceValid = (alert: boolean) : boolean => {
  if (hasTractorSemiTrailerExperience.value === false || (tractorSemiTrailerExperience.value.equipmentType &&
    tractorSemiTrailerExperience.value.startDate && tractorSemiTrailerExperience.value.endDate &&
    tractorSemiTrailerExperience.value.miles !== null)) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasTractorTwoTrailersExperienceValid = (alert: boolean) : boolean => {
  if (hasTractorTwoTrailersExperience.value === true || hasTractorTwoTrailersExperience.value === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isTractorTwoTrailersExperienceValid = (alert: boolean) : boolean => {
  if (hasTractorTwoTrailersExperience.value === false || (tractorTwoTrailersExperience.value.equipmentType &&
  tractorTwoTrailersExperience.value.startDate && tractorTwoTrailersExperience.value.endDate &&
  tractorTwoTrailersExperience.value.miles !== null)) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasSchoolbusExperienceValid = (alert: boolean) : boolean => {
  if (hasSchoolbusExperience.value === true || hasSchoolbusExperience.value === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isSchoolbusExperienceValid = (alert: boolean) : boolean => {
  if (hasSchoolbusExperience.value === false || (schoolbusExperience.value.equipmentType &&
    schoolbusExperience.value.startDate && schoolbusExperience.value.endDate &&
    schoolbusExperience.value.miles !== null)) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isHasOtherExperienceValid = (alert: boolean) : boolean => {
  if (hasOtherExperience.value === true || hasOtherExperience.value === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isOtherExperienceValid = (alert: boolean) : boolean => {
  if (hasOtherExperience.value === false || (otherExperience.value.equipmentType &&
    otherExperience.value.startDate && otherExperience.value.endDate &&
    otherExperience.value.miles !== null)) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
    }
    return false
  }
}

const isFormValid = () : boolean => {
  return isDriverLicenseValid(true) && 
    isHasLicenseDeniedValid(true) &&
    isLicenseDeniedDetailsValid(true) &&
    isHasLicenseSuspendedValid(true) &&
    isLicenseSuspendedDetailsValid(true) &&
    isHasAccidentRecordValid(true) &&
    isAccidentRecordValid(true) &&
    isHasTrafficConvictionValid(true) &&
    isTrafficConvictionValid(true) &&
    isHasStraightTruckExperienceValid(true) &&
    isStraightTruckExperienceValid(true) &&
    isHasTractorSemiTrailerExperienceValid(true) &&
    isTractorSemiTrailerExperienceValid(true) &&
    isHasTractorTwoTrailersExperienceValid(true) &&
    isTractorTwoTrailersExperienceValid(true) &&
    isHasSchoolbusExperienceValid(true) &&
    isSchoolbusExperienceValid(true) &&
    isHasOtherExperienceValid(true) &&
    isOtherExperienceValid(true)
}

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r1 = await api.getStates()
    if (r1) {
      states.value = r1
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    const r2 = await api.getLicenseTypes()
    if (r2) {
      licenseTypes.value = r2
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    if (store.applicationId) {
      const r3 = await api.getApplicationDriverInformation(store.applicationId)
      if (r3) {
        app.value = r3
        if (app.value.driverLicenses) {
          driverLicenses.value = app.value.driverLicenses
        } else {          
          addDriverLicense()
        }
        if (app.value.accidentRecords) {
          accidentRecords.value = app.value.accidentRecords
        } 
        if (app.value.trafficConvictions) {
          trafficConvictions.value = app.value.trafficConvictions
        }
        if (app.value.drivingExperiences) {
          const straight = app.value.drivingExperiences.find(d => d.equipmentClass === 'straight')
          if (straight) {
            hasStraightTruckExperience.value = true
            straightTruckExperience.value = straight
          } else {
            hasStraightTruckExperience.value = false
          }
          const semiTrailer = app.value.drivingExperiences.find(d => d.equipmentClass === 'semitrailer')
          if (semiTrailer) {
            hasTractorSemiTrailerExperience.value = true
            tractorSemiTrailerExperience.value = semiTrailer
          } else {
            hasTractorSemiTrailerExperience.value = false
          }
          const twoTrailers = app.value.drivingExperiences.find(d => d.equipmentClass === 'twotrailers')
          if (twoTrailers) {
            hasTractorTwoTrailersExperience.value = true
            tractorTwoTrailersExperience.value = twoTrailers
          } else {
            hasTractorTwoTrailersExperience.value = false
          }
          const schoolBus = app.value.drivingExperiences.find(d => d.equipmentClass === 'motorcoach')
          if (schoolBus) {
            hasSchoolbusExperience.value = true
            schoolbusExperience.value = schoolBus
          } else {
            hasSchoolbusExperience.value = false
          }
          const other = app.value.drivingExperiences.find(d => d.equipmentClass === 'other')
          if (other) {
            hasOtherExperience.value = true
            otherExperience.value = other
          } else {
            hasOtherExperience.value = false
          }
        }
      } else {
        app.value.id = store.applicationId
        addDriverLicense()
      } 
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/application/background-questions') 
  }
}

const addDriverLicense = () => {
  var d : DriverLicense = {
    state: null,
    number: null,
    type: null,
    expirationDate: null,
  }
  driverLicenses.value.push(d)
}

const deleteDriverLicense = (d: DriverLicense) => {
  const i = driverLicenses.value.indexOf(d)
  if (i > -1) { 
    driverLicenses.value.splice(i, 1)
  }
}

const updateAccidentRecord = () => {
  if (app.value.hasAccidentRecord) {
    addAccidentRecord()
  } else if (app.value.accidentRecords?.length) {
    accidentRecords.value = app.value.accidentRecords
  } else {
    accidentRecords.value = []
  }
}

const addAccidentRecord = () => {
  var a : AccidentRecord = {
    dateOfAccident: null,
    natureOfAccident: null,
    fatalities: null,
    injuries: null,
  }
  accidentRecords.value.push(a)
}

const deleteAccidentRecord = (a: AccidentRecord) => {
  const i = accidentRecords.value.indexOf(a)
  if (i > -1) { 
    accidentRecords.value.splice(i, 1)
  }
}

const updateTrafficConviction = () => {
  if (app.value.hasTrafficConviction) {
    addTrafficConviction()
  } else if (app.value.trafficConvictions?.length) {
    trafficConvictions.value = app.value.trafficConvictions
  } else {
    trafficConvictions.value = []
  }
}

const addTrafficConviction = () => {
  var t : TrafficConviction = {
    dateOfConviction: null,
    location: null,
    charge: null,
    penalty: null,
  }
  trafficConvictions.value.push(t)
}

const deleteTrafficConviction = (t: TrafficConviction) => {
  const i = trafficConvictions.value.indexOf(t)
  if (i > -1) { 
    trafficConvictions.value.splice(i, 1)
  }
}

const save = async (exit?: boolean) => {  
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value
    app.value.driverLicenses = driverLicenses.value
    if (app.value.hasAccidentRecord) {
      app.value.accidentRecords = accidentRecords.value
    } else {
      app.value.accidentRecords = []
    }
    if (app.value.hasTrafficConviction) {
      app.value.trafficConvictions = trafficConvictions.value
    } else {
      app.value.trafficConvictions = []
    }
    app.value.drivingExperiences = []
    if (hasStraightTruckExperience.value) {
      app.value.drivingExperiences.push(straightTruckExperience.value)
    }
    if (hasTractorSemiTrailerExperience.value) {
      app.value.drivingExperiences.push(tractorSemiTrailerExperience.value)
    }
    if (hasTractorTwoTrailersExperience.value) {
      app.value.drivingExperiences.push(tractorTwoTrailersExperience.value)
    }
    if (hasSchoolbusExperience.value) {
      app.value.drivingExperiences.push(schoolbusExperience.value)
    }
    if (hasOtherExperience.value) {
      app.value.drivingExperiences.push(otherExperience.value)
    }
    try {
      const r = await api.updateApplicationDriverInformation(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'driver-information',
          title: 'Driver Information',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview || phase.value === 1) {
          router.push('/application/review')
        } else {      
          router.push('/application/eeo-aa') 
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving driver information' : 'Error al guardar la información del historial de conducir')
    }
  }
}
</script>

<style>

</style>
