<template>
  <v-app id="app">    
    <v-main>
      <!-- Header -->
      <div class="header">
        <v-container>
          <v-row>
            <v-col 
              cols="8"
              sm="9"
              lg="10"
              xl="11"
            > 
              <div 
                v-if="showUser"
                class="mt-2"
              >
                <a class="account-link" :href="'#/account'">
                  {{ isEnglish ? 'My Account' : 'Mi Cuenta' }} 
                </a>
                <span class="ml-2"> | </span>
                <v-icon
                  class="mr-2 ml-1"
                  icon="mdi-account"
                  :title="email"
                ></v-icon>
                <span> {{ formatCurrentEmail(email) }} </span> 
              </div>
            </v-col>
            <v-col>
              <v-select
                v-model="language"
                :items="[{ id: 'english', title: 'EN', subtitle: 'English' }, { id: 'spanish', title: 'ES', subtitle: 'Español' }]"
                item-value="id"
                item-title="title"
                :item-props="true"
                variant="outlined"
                density="compact"
                :disabled="disableLanguage"
                hide-details
                @update:modelValue="updateLanguage()"
              ></v-select> 
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- Router View -->
      <div class="content">
        <RouterView />
      </div>
      <!-- Footer -->
      <div class="footer"></div>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useDialogs } from './plugins/dialogs'
import { onErrorCaptured } from 'vue'
import { useStore } from '@/stores/store'
import { useDisplay } from 'vuetify'
import { useRoute } from 'vue-router'

const language = ref('english')
const dialogs = useDialogs()
const store = useStore()
const { xs } = useDisplay()
const route = useRoute()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const showUser = computed(() => {
  if (email.value && route.name !== 'verify') {
    return true
  }
  return false
})

const email = computed(() => {
  return store.currentEmail
})

const disableLanguage = computed(() => {
  return store.disableLanguage
})

const reload = async () => {
  if (store.temporaryLanguage) {
    language.value = store.temporaryLanguage 
  } 
  store.setLanguage(language.value)
  store.setDisableLanguage(false)
}
onMounted(reload)

onErrorCaptured(error => {
  dialogs.showError(error)
})

const updateLanguage = () => { 
  window.location.reload()
  store.setTemporaryLanguage(language.value) 
}

const formatCurrentEmail = (email: string) => {
  if (xs.value && email.length > 19) {
    return email.substring(0, 19) + '...'
  }
  return email
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

#app {
  font-family: 'Montserrat' !important;
  color: #0a2240 !important;
  position: relative;
  min-height: 100vh;
  background-color: #f3f2f2;
}
.container-view {
  width: 100% !important;
}
.content {
  padding-bottom: 2.5rem;
}
.orange-btn:hover {
  background-color: #ff5900 !important;
}  
.orange-btn:focus {
  background-color: #ff5900 !important;
}      
.orange-btn {
  background-color: #f37a39 !important;
  color: #ffffff !important;
  font-size: 16px;
}
.orange-spinner {
  color: #f37027 !important;
}
.success-color {
  color: green !important;
}
.btn {
  text-transform: unset !important;
}
.input-signature {
  font-family: 'Lucida Handwriting' !important;
}
.header {
  background-color: #0a2240;
  color: white;
}
.footer {
  padding-top: 4vw;
  padding-bottom: 4vw;
  background-color: #0a2240;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.v-input__details {
  align-items: flex-start;
  margin-top: 8px;
}
.account-link {
  color: white;
  text-decoration: none;
}
.account-link:hover {
  color: #bdbdbd;
}
.font-sm {
  font-size: 16px;
}
.subtitle {
  font-size: 16px;
}
.title-bold {
  font-size: 16px;
  font-weight: 600;
}
.view {
  background-color: #f3f2f2;
}
.view-inner {
  background-color: white;
  min-height: 88dvh;
}
@media screen and (min-width: 1025px) {
  .footer {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
}
@media screen and (min-width: 767px) {
  .container-view {
    width: 80% !important;
  }
}
@media screen and (min-width: 1025px) {
  .container-view {
    width: 45% !important;
    padding-left: 6%;
    padding-right: 6%;
  }
}
</style>
