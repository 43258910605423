<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Title -->
      <v-row>
        <v-col>
          <h3 class="d-flex justify-center"> 
            {{ isEnglish ? 'Forgot password?' : '¿Olvidó su contraseña?' }}
          </h3>
        </v-col>
      </v-row>
      <div v-if="passwordResetSuccess">
        <!-- Alert - Success -->
        <v-row>
          <v-col>
            <v-alert
              border="start"
              type="success"
              variant="tonal"
            >
              {{ isEnglish ? 
                'Your password has been successfully changed. You will be redirected to the login page' :
                'Su contraseña ha sido cambiada. Usted será dirigido a la página de inicio' }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <div v-else>  
        <div v-if="sentPasswordResetCode" class="d-flex justify-center mb-2 mt-2">
          {{ isEnglish ? 
            'Please check your email for confirmation code' : 
            'Por favor revise su correo electrónico y busque el código de confirmación' }}
        </div>
        <!-- Alert - Error -->
        <v-row v-if="error">
          <v-col>
            <v-alert          
              border="start"
              type="error"
              variant="tonal"
            >
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <!-- Form -->
        <v-row v-if="sentPasswordResetCode">
          <v-col>
            <v-form
              @submit.prevent="resetPassword()"
            >
              <div>
                <v-text-field
                  v-model="confirmationCode"
                  :rules="required"
                  :readonly="loadingResetPassword"
                  class="tf-padding"
                  variant="outlined"
                  :label="isEnglish ? 'Confirmation code*' : 'Código de confirmación*'"
                  hide-details
                  required
                  @input="clearPasswordResetAlerts()"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                  :rules="required"
                  :readonly="loadingResetPassword"
                  :type="showPassword ? 'text' : 'password'"
                  class="tf-padding"
                  variant="outlined"
                  :label="isEnglish ? 'New password*' : 'Nueva contraseña*'"
                  hide-details
                  required
                  @click:append-inner="showPassword = !showPassword"
                  @input="clearPasswordResetAlerts()"
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                  :rules="required"
                  :readonly="loadingResetPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  class="tf-padding"
                  variant="outlined"
                  :label="isEnglish ? 'Confirm new password*' : 'Confirme su nueva contraseña*'"
                  hide-details
                  required
                  @click:append-inner="showConfirmPassword = !showConfirmPassword"
                  @input="clearPasswordResetAlerts()"
                ></v-text-field>
              </div>
              <div class="d-flex justify-center">
                <v-btn 
                  :loading="loadingResetPassword"
                  class="btn send-btn orange-btn" 
                  elevation="0"
                  type="submit"
                >
                  {{ isEnglish ? 'Reset password' : 'Resetear contraseña' }}
                </v-btn>
              </div>
              <br>
              <div class="d-flex justify-center">
                {{ isEnglish ? 'Did not receive the confirmation code?' : '¿No recibió el código de confirmación?' }}
              </div>
              <!-- Alert - Sent -->
              <v-row v-if="sentPasswordResetCodeMessage">
                <v-col>
                  <br>
                  <v-alert          
                    border="start"
                    type="success"
                    variant="tonal"
                  >
                    {{ isEnglish ? 'New confirmation code has been sent' : 'Se ha enviado un nuevo código de confirmación' }}
                  </v-alert>
                </v-col>
              </v-row>
              <!-- Send code again -->
              <div class="d-flex justify-center mt-5"> 
                <v-btn 
                  :disabled="loading"
                  :loading="loading"
                  variant="tonal"
                  class="btn"
                  elevation="0"
                  @click="sendEmail(true)"
                >
                  {{ isEnglish ? 'Send code again' : 'Enviar código nuevamente' }}
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-form
              @submit.prevent="sendEmail(false)"
            >
              <div>
                <v-text-field
                  v-model="email"
                  :rules="required"
                  :readonly="loading"
                  prepend-inner-icon="mdi-email-outline"
                  class="tf-padding"
                  variant="outlined"
                  :label="isEnglish ? 'Email address*' : 'Correo electrónico'"
                  hide-details
                  required
                  @input="error = false"
                ></v-text-field>
              </div>
              <div class="d-flex justify-center">
                <v-btn 
                  :loading="loading"
                  class="btn send-btn orange-btn" 
                  elevation="0"
                  type="submit"
                >
                  {{ isEnglish ? 'Send email' : 'Enviar correo electrónico' }}
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row> 
      </div>      
      <br><br>   
      <!-- Login User Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle login-msg">
            {{ isEnglish ? 'Back to ' : 'Regrese a ' }}
            <a class="login-link" :href="'#/login'">
              {{ isEnglish ? 'login' : 'ingrese a su cuenta' }}
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import LabattLogo from '@/components/LabattLogo.vue'
import api from '@/api'
import { ResetUserPassword } from '@/types'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'

const loading = ref(false)
const loadingResetPassword = ref(false)
const error = ref(false)
const errorMessage = ref('')
const email = ref('')
const sentPasswordResetCode = ref(false)
const sentPasswordResetCodeMessage = ref('')
const passwordResetSuccess = ref(false)
const confirmationCode = ref('')
const password = ref('')
const confirmPassword = ref('')
const showPassword = ref(false)
const showConfirmPassword = ref(false)
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const clearPasswordResetAlerts = async () => {
  error.value = false
  errorMessage.value = ''
  sentPasswordResetCodeMessage.value = ''
}

const sendEmail = async (m : boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  sentPasswordResetCodeMessage.value = ''
  try {
    const r = await api.sendUserPasswordResetConfirmationCode(email.value, store.language)
    if (r.includes('confirmation code') || r.includes('código de confirmación')) {
      sentPasswordResetCode.value = true
      if (m) {
        sentPasswordResetCodeMessage.value = r
      }
    } else {
      showError(r)
      sentPasswordResetCode.value = false
      sentPasswordResetCodeMessage.value = ''
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}

const resetPassword = async () => {
  loadingResetPassword.value = true
  error.value = false
  errorMessage.value = ''
  sentPasswordResetCodeMessage.value = ''
  try {
    const user: ResetUserPassword = {
      email: email.value,
      confirmationCode: confirmationCode.value,
      password: password.value,
      confirmPassword: confirmPassword.value,
      language: store.language
    }
    const r = await api.resetUserPassword(user)
    if (r === 'Success') {
      passwordResetSuccess.value = true
      setTimeout(() => {        
        router.push('/login')
      }, 5000)
    } else {
      showError(r)
      passwordResetSuccess.value = false
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loadingResetPassword.value = false   
    
  }
}
</script>

<style>
.tf-padding {
  padding-bottom: 15px;
}
.send-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
.login-msg {
  margin-bottom: 35px;
}
.login-link {
  margin-left: 5px;
  font-weight: 600;
}
</style>
