<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Title -->
      <v-row>
        <v-col>
          <h3 class="d-flex justify-center"> 
            {{ isEnglish ? 'Email verification' : 'Verificación de correo electrónico' }}
          </h3>
          <br>
          <div v-if="isEnglish">
            We need to verify that <b>{{ email }}</b> belongs to you.
          </div>
          <div v-else>
            Necesitamos verificar que <b>{{ email }}</b> pertenezca a usted.
          </div>
          <br>
          <div v-if="isEnglish"> 
            Please check your email for a verification code from <b>applyLabatt@labattfood.com</b>. 
            Input code below to confirm your email.
          </div>
          <div v-else> 
            Por favor revise su correo electrónico y busque un código de verificación de <b>applyLabatt@labattfood.com</b>. 
            Ingrese el código en el lugar asignado para confirmar su correo electrónico.
          </div>
        </v-col>
      </v-row>
      <!-- Alert - Error -->
      <v-row v-if="error">
        <v-col>
          <v-alert
            border="start"
            type="error"
            variant="tonal"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row>
        <v-col>
          <v-form
            @submit.prevent="onSubmit()"
          >
            <div>
              <v-text-field
                v-model="code"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Verification code*' : 'Código de verificación*'"
                hide-details
                required
              ></v-text-field>
            </div>
            <div class="d-flex justify-center">
              <v-btn 
                :loading="loading"
                class="btn verify-btn orange-btn" 
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'Verify' : 'Verificar' }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <br>
      <div class="d-flex justify-center">
        {{ isEnglish ? 'Did not receive the verification code?' : '¿No recibió el código de verificación?' }}
      </div>
      <br>
      <!-- Alert - Resend -->
      <v-row v-if="resend">
        <v-col>
          <v-alert          
            border="start"
            type="success"
            variant="tonal"
          >
            {{ isEnglish ? 'New verification code has been sent' : 'Se ha enviado un nuevo código de verificación' }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Send Verification Code -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center"> 
            <v-btn 
              :disabled="loading"
              :loading="loadingResend"
              variant="tonal"
              class="btn"
              elevation="0"
              @click="sendVerificationCode()"
            >
              {{ isEnglish ? 'Send code again' : 'Enviar código nuevamente' }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import api from '@/api'
import { useRouter } from 'vue-router'
import LabattLogo from '@/components/LabattLogo.vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'

const loading = ref(false)
const loadingResend= ref(false)
const error = ref(false)
const errorMessage = ref('')
const resend = ref(false)
const email = ref('')
const code = ref('')
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const { cookies } = useCookies()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  resend.value = false
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  resend.value = false
  try {
    const r = await api.verifyUser(email.value, code.value, store.language)
    if (r === 'Success') {
      router.push('/dashboard')
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}

const sendVerificationCode = async () => {
  loadingResend.value = true
  error.value = false
  errorMessage.value = ''
  resend.value = false
  try {
    const r = await api.sendUserVerificationCode(email.value)
    if (r === 'Success') {
      resend.value = true
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loadingResend.value = false
  }
}
</script>

<style>
.tf-padding {
  padding-bottom: 15px;
}
.verify-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
</style>
