import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { JobPosting, ApplicationSection } from '@/types'

export const useStore = defineStore('store', () => {
  const jobPostingId = ref('')
  const selectedJobPosting = ref(null as JobPosting | null)
  const webHistory = ref('')
  const applicationPhase = ref(0)
  const applicationId = ref('')
  const applicationSections = ref([] as ApplicationSection[])
  const isApplicationReview = ref(false)
  const temporaryLanguage = ref('')
  const language = ref('')
  const disableLanguage = ref(false)
  const currentEmail = ref('')

  const setJobPostingId = (id: string) => {
    jobPostingId.value = id 
  }

  const setSelectedJobPosting = (job: JobPosting | null) => {
    selectedJobPosting.value = job 
  }

  const setWebHistory = (history: string) => {
    webHistory.value = history 
  }

  const setApplicationPhase = (phase: number) => {
    applicationPhase.value = phase 
  }

  const setApplicationId = (id: string) => {
    applicationId.value = id 
  }

  const setApplicationSections = (sections: ApplicationSection[]) => {
    applicationSections.value = sections 
  }

  const setIsApplicationReview = (isReview: boolean) => {
    isApplicationReview.value = isReview 
  }

  const setTemporaryLanguage = (l: string) => {
    temporaryLanguage.value = l 
  }

  const setLanguage = (l: string) => {
    language.value = l 
  }

  const setDisableLanguage = (d: boolean) => {
    disableLanguage.value = d
  }

  const setCurrentEmail = (email: string) => {
    currentEmail.value = email
  }

  const resetStore = () => {
    jobPostingId.value = ''
    selectedJobPosting.value = null
    webHistory.value = ''
    applicationPhase.value = 0
    applicationId.value = ''
    applicationSections.value = []
    isApplicationReview.value = false
    disableLanguage.value = false
  }
  
  return {
    jobPostingId, setJobPostingId,
    selectedJobPosting, setSelectedJobPosting,
    webHistory, setWebHistory,
    applicationPhase, setApplicationPhase,
    applicationId, setApplicationId,
    applicationSections, setApplicationSections,
    isApplicationReview, setIsApplicationReview,
    temporaryLanguage, setTemporaryLanguage,
    language, setLanguage,
    disableLanguage, setDisableLanguage,
    currentEmail, setCurrentEmail,
    resetStore
  }}, { persist: true }
)