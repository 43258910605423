<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="3" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Form -->
    <v-form
      @submit.prevent="save()"
    > 
      <!-- Education -->
      <v-row>
        <v-col>
          <div class="title-bold"> {{ isEnglish ? 'Education' : 'Educación' }} </div>
        </v-col>
      </v-row> 
      <div v-if="phase === 0 || phase === 1">
        <!-- Sub Section -->
        <v-row>
          <v-col>        
            <div class="mb-3 mt-3"> 
              {{ isEnglish ? 'High School' : 'Preparatoria' }}{{ isOffice ? '*' : '' }} 
            </div>
            <!-- Name -->
            <v-text-field            
              v-model="app.highSchoolName"
              :rules="isOffice ? required : [true]"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Name' : 'Nombre'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <div v-if="app.highSchoolName">
              <div v-if="!isOffice">
                <!-- City -->
                <v-text-field
                  v-model="app.highSchoolCity"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'City' : 'Ciudad'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- State -->
                <v-select
                  v-model="app.highSchoolState"
                  :items="states"
                  :readonly="loading"
                  :item-title="isEnglish ? 'name' : 'spanishName'"
                  item-value="id"
                  class="tf-padding"
                  :label="isEnglish ? 'State' : 'Estado'"
                  variant="outlined"
                  hide-details
                ></v-select>
              </div>
              <!-- Year Started -->
              <v-text-field
                v-model="app.highSchoolYearStarted"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? 'Year started' : 'Año de inicio'"
                variant="outlined"
                v-mask="'####'"
                hide-details
              ></v-text-field>
              <!-- Year Completed -->
              <v-text-field
                v-model="app.highSchoolYearCompleted"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? 'Year completed' : 'Año que terminó'"
                variant="outlined"
                v-mask="'####'"
                hide-details
              ></v-text-field>
              <div v-if="!isOffice">
                <div class="font-sm"> 
                  {{ isEnglish ? 'Graduated?' : '¿Se graduó?' }}
                </div>
                <v-radio-group
                  class="radio-btn tf-padding d-flex justify-center"
                  v-model="app.highSchoolGraduated"
                  :readonly="loading"
                  inline
                >
                  <v-radio 
                    class="mr-12"
                    :label="isEnglish ? 'Yes' : 'Sí'" 
                    :value="true"
                  ></v-radio>
                  <v-radio 
                    class="mr-5"
                    :label="'No'" 
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <!-- Final Year Completed -->
                <v-select
                  v-if="app.highSchoolGraduated === false"
                  v-model="app.highSchoolFinalYearCompleted"
                  :readonly="loading"
                  :items="isEnglish ? schoolYears : schoolYearsSpanish"
                  item-value="id"
                  item-title="title"
                  class="tf-padding"
                  :label="isEnglish ? 'Final year completed' : 'Último año completado'"
                  variant="outlined"
                  hide-details
                ></v-select>
              </div>
            </div>
            <div v-if="!isOffice">
              <!-- GED -->
              <div class="mb-3"> GED </div>
              <!-- Name -->
              <v-text-field
                v-model="app.gedName"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Name' : 'Nombre'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <div v-if="app.gedName">
                <!-- City -->
                <v-text-field
                  v-model="app.gedCity"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'City' : 'Ciudad'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- State -->
                <v-select
                  v-model="app.gedState"
                  :items="states"
                  :readonly="loading"
                  :item-title="isEnglish ? 'name' : 'spanishName'"
                  item-value="id"
                  class="tf-padding"
                  :label="isEnglish ? 'State' : 'Estado'"
                  variant="outlined"
                  hide-details
                ></v-select>
                <!-- Year Started -->
                <v-text-field
                  v-model="app.gedYearStarted"
                  :readonly="loading"
                  type="number"
                  class="tf-padding"
                  :label="isEnglish ? 'Year started' : 'Año de inicio'"
                  variant="outlined"
                  v-mask="'####'"
                  hide-details
                ></v-text-field>
                <!-- Year Completed -->
                <v-text-field
                  v-model="app.gedYearCompleted"
                  :readonly="loading"
                  type="number"
                  class="tf-padding"
                  :label="isEnglish ? 'Year completed' : 'Año que terminó'"
                  variant="outlined"
                  v-mask="'####'"
                  hide-details
                ></v-text-field>
                <!-- Graduated -->
                <div class="font-sm"> 
                  {{ isEnglish ? 'Graduated?' : '¿Se graduó?' }}
                </div>
                <v-radio-group
                  class="radio-btn tf-padding d-flex justify-center"
                  v-model="app.gedGraduated"
                  :readonly="loading"
                  inline
                >
                  <v-radio 
                    class="mr-12"
                    :label="isEnglish ? 'Yes' : 'Sí'" 
                    :value="true"
                  ></v-radio>
                  <v-radio 
                    class="mr-5"
                    :label="'No'" 
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- College/University -->
            <div class="mb-3"> 
              {{ isEnglish ? 'College or University' : 'Universidad' }}{{ isOffice ? '*' : '' }} 
            </div>
            <!-- Name -->
            <v-text-field
              v-model="app.collegeUniversityName"
              :rules="isOffice ? required : [true]"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Name' : 'Nombre'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <div v-if="app.collegeUniversityName">
              <div v-if="!isOffice">
                <!-- City -->
                <v-text-field
                  v-model="app.collegeUniversityCity"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'City' : 'Ciudad'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- State -->
                <v-select
                  v-model="app.collegeUniversityState"
                  :items="states"
                  :readonly="loading"
                  :item-title="isEnglish ? 'name' : 'spanishName'"
                  item-value="id"
                  class="tf-padding"
                  :label="isEnglish ? 'State' : 'Estado'"
                  variant="outlined"
                  hide-details
                ></v-select>
              </div>
              <!-- Year Started -->
              <v-text-field
                v-model="app.collegeUniversityYearStarted"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? 'Year started' : 'Año de inicio'"
                variant="outlined"
                v-mask="'####'"
                hide-details
              ></v-text-field>
              <!-- Year Completed -->
              <v-text-field
                v-model="app.collegeUniversityYearCompleted"
                :readonly="loading"
                type="number"
                class="tf-padding"
                :label="isEnglish ? 'Year completed' : 'Año que terminó'"
                variant="outlined"
                v-mask="'####'"
                hide-details
              ></v-text-field>
              <div v-if="!isOffice">
                <!-- Graduated -->
                <div class="font-sm"> 
                  {{ isEnglish ? 'Graduated?' : '¿Se graduó?' }}
                </div>
                <v-radio-group
                  class="radio-btn tf-padding d-flex justify-center"
                  v-model="app.collegeUniversityGraduated"
                  :readonly="loading"
                  inline
                >
                  <v-radio 
                    class="mr-12"
                    :label="isEnglish ? 'Yes' : 'Sí'" 
                    :value="true"
                  ></v-radio>
                  <v-radio 
                    class="mr-5"
                    :label="'No'" 
                    :value="false"
                  ></v-radio>
                </v-radio-group>           
                <!-- Final Year Completed -->
                <v-select
                  v-if="app.collegeUniversityGraduated === false"
                  v-model="app.collegeUniversityFinalYearCompleted"
                  :readonly="loading"
                  :items="isEnglish ? schoolYears : schoolYearsSpanish"
                  item-value="id"
                  item-title="title"
                  class="tf-padding"
                  :label="isEnglish ? 'Final year completed' : 'Último año completado'"
                  variant="outlined"
                  hide-details
                ></v-select>
                <!-- Degree -->
                <v-select
                  v-if="app.collegeUniversityGraduated"
                  v-model="app.collegeUniversityDegree"
                  :items="[
                    { id: 'Associate', title: isEnglish ? 'Associate' : 'Carrera Técnica' }, 
                    { id: 'Bachelor', title: isEnglish ? 'Bachelor' : 'Licenciatura/Ingenieria' }, 
                    { id: 'Master', title: isEnglish ? 'Master' : 'Maestría' }, 
                    { id: 'PhD', title: isEnglish ? 'PhD' : 'Doctorado' }
                  ]"
                  item-value="id"
                  item-title="title"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'Degree awarded' : 'Título recibido'"
                  variant="outlined"
                  hide-details
                ></v-select>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="phase === 0 || phase === 2">
        <div v-if="!isOffice">
          <!-- Extracurricular Activities -->
          <div class="font-sm mb-3 mt-5"> 
            {{ isEnglish ? 'Extracurricular activities' : 'Actividades extracurriculares' }}
          </div>
          <v-textarea
            v-model="app.extracurricularActivities"
            :readonly="loading"
            row-height="20"
            rows="2"
            auto-grow
            class="tf-padding"
            variant="outlined"
            hide-details
          ></v-textarea>
          <!-- Special Skills -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 'Special skills' : 'Habilidades especiales' }}
          </div>
          <v-textarea
            v-model="app.specialSkills"
            :readonly="loading"
            row-height="20"
            rows="2"
            auto-grow
            class="tf-padding"
            variant="outlined"
            hide-details
          ></v-textarea>
        </div>
        <hr class="mt-3 mb-3">
        <!-- Military -->
        <v-row>
          <v-col>
            <div class="title-bold"> {{ isEnglish ? 'Military' : 'Servicio Militar' }} </div>
          </v-col>
        </v-row>
        <!-- Sub Section -->
        <v-row>
          <v-col>
            <!-- Member of National Guard or Reserve -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Are you a member of either the National Guard or Reserve?*' : 
                 '¿Es usted miembro de la Guardia Nacional o la Reserva?*' }}
            </div>
            <v-select
              v-model="app.militaryNationalGuardOrReserve"
              :rules="required"
              :readonly="loading"
              :items="[
                { id: 'NationalGuard', title: isEnglish ? 'National Guard' : 'Guardia Nacional' }, 
                { id: 'Reserve', title: isEnglish ? 'Reserve' : 'Reserva' }, 
                { id: 'Neither', title: isEnglish ? 'Neither' : 'Ninguno' }
              ]"
              item-value="id"
              item-title="title"
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-select>
            <div v-if="app.militaryNationalGuardOrReserve === 'NationalGuard' || app.militaryNationalGuardOrReserve === 'Reserve'">
              <!-- Service Branch -->
              <v-select
                v-model="app.militaryServiceBranch"
                :readonly="loading"
                :items="[
                  { id: 'AirForce', title: isEnglish ? 'Air Force' : 'Fuerza Aérea' }, 
                  { id: 'Army', title: isEnglish ? 'Army' : 'Ejército' }, 
                  { id: 'CoastGuard', title: isEnglish ? 'Coast Guard' : 'Guardia Costera' }, 
                  { id: 'MarineCorps', title: isEnglish ? 'Marine Corps' : 'Infantería de Marina' }, 
                  { id: 'Navy', title: isEnglish ? 'Navy' : 'Armada' }
                ]"
                item-value="id"
                item-title="title"
                class="tf-padding"
                :label="isEnglish ? 'Service branch' : 'Sucursal de servicio'"
                variant="outlined"
                hide-details
              ></v-select>
              <!-- Date Entered -->
              <v-text-field
                v-model="app.militaryDateEntered"
                :readonly="loading"
                type="date"
                class="tf-padding"
                :label="isEnglish ? 'Date entered' : 'Fecha de ingreso'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Date Discharged -->
              <v-text-field
                v-model="app.militaryDateDischarged"
                :readonly="loading"
                type="date"
                class="tf-padding"
                :label="isEnglish ? 'Date discharged' : 'Fecha de alta'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Specialty -->
              <v-text-field
                v-model="app.militarySpecialty"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Specialty' : 'Especialidad'"
                variant="outlined"
                hide-details
              ></v-text-field>
              <!-- Final Rank/Rate -->
              <v-text-field
                v-model="app.militaryFinalRankOrRate"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Final rank / rate' : 'Último rango'"
                variant="outlined"
                hide-details
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </div>
      <br>
      <!-- Btns -->
      <ApplicationNavigationComponent 
        :loading="loading" 
        @exit="save(true)"
        @previous="previous()"
      >
      </ApplicationNavigationComponent>
    </v-form>
  </div>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import { State, ApplicationEducationMilitary, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const required = [(value: string) => !!value || 'Required']
const errorMessage = ref('')
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const states = ref([] as State[])
const email = ref('')
const app = ref({} as ApplicationEducationMilitary)

const schoolYears = [
  { id: 9, title: 'Freshman' }, 
  { id: 10, title: 'Sophomore' }, 
  { id: 11, title: 'Junior' }, 
  { id: 12, title: 'Senior' }
]
const schoolYearsSpanish = [
  { id: 9, title: 'Primero' }, 
  { id: 10, title: 'Segundo' }, 
  { id: 11, title: 'Tercero' }, 
  { id: 12, title: 'Cuarto' },
  { id: 15, title: 'Quinto' },
  { id: 16, title: 'Sexto' }
]

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isHighSchoolNameValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please enter high school name' : 'Por favor ingrese el nombre de la preparatoria'
  if (app.value.highSchoolName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isCollegeNameValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please enter college or university name' : 'Por favor ingrese el nombre de la universidad'
  if (app.value.collegeUniversityName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isMilitaryNationalGuardOrReserveValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.militaryNationalGuardOrReserve) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhaseOneValid = () : boolean => {
  if (isOffice.value) {
    return isHighSchoolNameValid(true) && isCollegeNameValid(true)
  }
  return true
}

const isPhaseTwoValid = () : boolean => {
  return isMilitaryNationalGuardOrReserveValid(true)
}

const isFormValid = () : boolean => {
  if (phase.value === 1) {
    return isPhaseOneValid()
  } 
  if (phase.value === 2) {
    return isPhaseTwoValid()
  }
  return isPhaseOneValid() && isPhaseTwoValid()
}

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r = await api.getStates()
    if (r) {
      states.value = r
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    } 
    if (store.applicationId) {
      const r2 = await api.getApplicationEducationMilitary(store.applicationId)
      if (r2) {
        app.value = r2
        if (app.value.highSchoolYearStarted === 0) {
          app.value.highSchoolYearStarted = null
        }
        if (app.value.highSchoolYearCompleted === 0) {
          app.value.highSchoolYearCompleted = null
        }
        if (app.value.highSchoolFinalYearCompleted === 0) {
          app.value.highSchoolFinalYearCompleted = null
        }
        if (app.value.gedYearStarted === 0) {
          app.value.gedYearStarted = null
        }
        if (app.value.gedYearCompleted === 0) {
          app.value.gedYearCompleted = null
        }
        if (app.value.gedName) {
          // do nothing
        } else {
          if (app.value.gedGraduated) {
            // do nothing
          } else {
            app.value.gedGraduated = null
          }
        }
        if (app.value.collegeUniversityYearStarted === 0) {
          app.value.collegeUniversityYearStarted = null
        }
        if (app.value.collegeUniversityYearCompleted === 0) {
          app.value.collegeUniversityYearCompleted = null
        }
        if (app.value.collegeUniversityFinalYearCompleted === 0) {
          app.value.collegeUniversityFinalYearCompleted = null
        }
        if (app.value.militaryNationalGuardOrReserve === '') {
          app.value.militaryDateEntered = null
          app.value.militaryDateDischarged = null
        }
      } else {
        app.value.id = store.applicationId
      } 
    }    
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/application/work-eligibility')
  }
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value  

    if (app.value.highSchoolYearStarted === null) {
      app.value.highSchoolYearStarted = 0
    }
    if (app.value.highSchoolYearCompleted === null) {
      app.value.highSchoolYearCompleted = 0
    }
    if (app.value.highSchoolGraduated) {
      app.value.highSchoolFinalYearCompleted = 0
    } else if (app.value.highSchoolGraduated === null) {
      app.value.highSchoolGraduated = false
    }
    if (app.value.highSchoolFinalYearCompleted === null) {
      app.value.highSchoolFinalYearCompleted = 0
    }
    if (app.value.gedYearStarted === null) {
      app.value.gedYearStarted = 0
    }
    if (app.value.gedYearCompleted === null) {
      app.value.gedYearCompleted = 0
    }
    if (app.value.gedGraduated === null) {
      app.value.gedGraduated = false
    }
    if (app.value.collegeUniversityYearStarted === null) {
      app.value.collegeUniversityYearStarted = 0
    }
    if (app.value.collegeUniversityYearCompleted === null) {
      app.value.collegeUniversityYearCompleted = 0
    }
    if (app.value.collegeUniversityGraduated) {
      app.value.collegeUniversityFinalYearCompleted = 0
    } else if (app.value.collegeUniversityGraduated === false) {
      app.value.collegeUniversityDegree = null
    } else if (app.value.collegeUniversityGraduated === null) {
      app.value.collegeUniversityGraduated = false
    }
    if (app.value.collegeUniversityFinalYearCompleted === null) {
      app.value.collegeUniversityFinalYearCompleted = 0
    }
    try {
      const r = await api.updateApplicationEducationMilitary(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'education-military',
          title: phase.value === 1 ? 'Education' : 'Education & Military',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else {
          router.push('/application/previous-employment')
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving education and military info' : 'Error al guardar la información de educación y servicio militar')
    } 
  }  
}
</script>

<style>

</style>
