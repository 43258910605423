import { useDialogs } from '@/plugins/dialogs'
import Axios from 'axios'
import { type Plugin } from 'vue'

const plugin: Plugin = {
  install() {
    const dialogs = useDialogs()

    let reloading = false

    Axios.interceptors.response.use(undefined, async (err: unknown) => {
      if (Axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          if (!reloading) {
            if (await dialogs.confirm('Your Labatt session is expired. Press OK to reload the page.')) {
              reloading = true
              window.location.href = '/login' + window.location.hash
            }
          }
        }
      }
      return Promise.reject(err)
    })
  },
}

export default plugin
