<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="2" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Work Eligibility' : 'Elegibilidad Laboral' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <div v-if="phase === 0 || phase === 1">
            <!-- Is Authorized -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Are you legally authorized to work in the United States?*' : 
                 '¿Está legalmente autorizado para trabajar en los Estados Unidos?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.isAuthorized"
              :rules="isAuthorizedRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>
          <div v-if="phase === 0 || phase === 2">
            <!-- Sponsorship -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Will you now or in the future require sponsorship for employment visa status?*' : 
                 '¿Necesitará patrocinio, ahora o en el futuro, para su estatus de visa de trabajo?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.requireSponsorship"
              :rules="isSponsorshipRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>
            <!-- Start Date -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'What date can you start work?*' : 
                 '¿Qué tan pronto puede empezar a trabajar?*' }}
            </div>
            <v-text-field
              v-model="potentialStartDate"
              :rules="required"
              :readonly="loading"
              type="date"
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Employed -->
            <div class="font-sm mb-3"> 
              {{  isEnglish ? 'Are you employed now?*' : '¿Está empleado ahora?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.isEmployedNow"
              :rules="isEmployedRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>     
          </div>              
          <br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent>
        </v-form>
      </v-col>
    </v-row>
  </div>    
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import { ApplicationWorkEligibility, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const isAuthorizedRule = [() => isAuthorizedValid(false) || 'Required']
const isSponsorshipRule = [() => isSponsorshipValid(false) || 'Required']
const isEmployedRule = [() => isEmployedValid(false) || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const app = ref({} as ApplicationWorkEligibility)
const potentialStartDate = ref('')

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isAuthorizedValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isAuthorized === true || app.value.isAuthorized === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isSponsorshipValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.requireSponsorship === true || app.value.requireSponsorship === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isStartDateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (potentialStartDate.value) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isEmployedValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isEmployedNow === true || app.value.isEmployedNow === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhaseOneValid = () : boolean => {
  return isAuthorizedValid(true)
}

const isPhaseTwoValid = () : boolean => {
  return isSponsorshipValid(true) && 
    isStartDateValid(true) &&
    isEmployedValid(true)
}

const areBothPhasesValid = () : boolean => {
  return isAuthorizedValid(true) && 
    isSponsorshipValid(true) && 
    isStartDateValid(true) &&
    isEmployedValid(true)
}

const isFormValid = () : boolean => {
  if (phase.value === 1) {
    return isPhaseOneValid()
  } 
  if (phase.value === 2) {
    return isPhaseTwoValid()
  }
  return areBothPhasesValid()
}

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    if (store.applicationId) {
      const r = await api.getApplicationWorkEligibility(store.applicationId)
      if (r) {
        app.value = r
        if (app.value.potentialStartDate) {
          potentialStartDate.value = app.value.potentialStartDate
        }
        if (phase.value === 2) {
          if (app.value.requireSponsorship === false) {
            app.value.requireSponsorship = null
          }
          if (app.value.isEmployedNow === false) {
            app.value.isEmployedNow = null
          }
        }
      } else {
        app.value.id = store.applicationId
        potentialStartDate.value = new Date().toISOString().substring(0, 10)
      } 
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/application/personal-information')
  }
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''  
  if (isFormValid()) {
    app.value.email = email.value  
    app.value.potentialStartDate = potentialStartDate.value
    try {
      const r = await api.updateApplicationWorkEligibility(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'work-eligibility',
          title: 'Work Eligibility',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else {
          router.push('/application/education-military')
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving work eligibility info' : 'Error al guardar la información de elegibilidad laboral')
    } 
  }  
}
</script>

<style>

</style>
