<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="5" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Work Experience' : 'Experiencia Laboral' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >            
          <!-- Work Experience -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 
               'Do you have any warehouse, transportation, or clerical work experience?*' : 
               '¿Tiene alguna experiencia en almacén, transporte, o trabajo administrativo?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasWorkExperience"
            :rules="yesOrNoRule"
            :readonly="loading"
            inline
            @update:modelValue="updateWorkExperience()"
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="app.hasWorkExperience">
            <!-- Work Experience - Section -->
            <div v-if="workExperiences.length > 0">
              <hr class="mb-5 mt-3">
              <div v-for="(e, i) in workExperiences">
                <v-row>
                  <v-col cols="8">
                    <div class="title-bold mb-3 mt-3"> 
                      {{ isEnglish ? 'Skill / Experience ' : 'Habilidad / Experiencia ' }} 
                      <span> ({{ workExperiences.indexOf(e) + 1 }}) </span> 
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex justify-end">
                      <v-btn 
                        v-if="workExperiences.indexOf(e) > 0"
                        class="btn mt-1" 
                        variant="tonal"
                        elevation="0"
                        @click="deleteWorkExperience(e)"
                      >
                        {{ isEnglish ? 'Delete' : 'Eliminar' }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- Type -->
                <v-select
                  v-model="e.code"
                  :rules="required"
                  :readonly="loading"
                  :items="getTypes(i)"
                  item-value="id"
                  :item-title="(i: any) => i.subtitle + ' - ' + i.title"
                  class="tf-padding"
                  variant="outlined"
                  :label="isEnglish ? 'Type*' : 'Tipo*'"
                  hide-details
                ></v-select>
                <div v-if="e.code === '18'">
                  <v-text-field
                    v-model="e.description"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Description*' : 'Descripción*'"
                    hide-details
                  ></v-text-field>
                </div>
                <!-- Company -->
                <div class="font-sm mb-3"> 
                  {{ isEnglish ? 'Company*' : 'Compañía*' }}
                </div>
                <v-text-field
                  v-model="e.location"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- How Long -->
                <div class="font-sm mb-3"> 
                  {{ isEnglish ? 'How long?*' : '¿Cuánto tiempo?*' }}
                </div>
                <div class="d-flex">
                  <v-text-field
                    v-model="e.years"
                    :rules="required"
                    :readonly="loading"
                    type="number"
                    width="10%"
                    min="0"
                    step="1"
                    class="tf-padding mr-2"
                    :label="isEnglish ? 'Years*' : 'Años*'"
                    variant="outlined"
                    v-mask="'##'"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-model="e.months"
                    :readonly="loading"
                    type="number"
                    width="10%"
                    min="0"
                    step="1"
                    class="tf-padding ml-2"
                    :label="isEnglish ? 'Months' : 'Meses'"
                    variant="outlined"
                    v-mask="'##'"
                    hide-details
                  ></v-text-field>
                </div>       
              </div>
            </div>
          </div>
          <!-- Add Another Work Experience - Btn -->
          <v-row v-if="app.hasWorkExperience">
            <v-col>
              <div class="d-flex justify-center">
                <v-btn 
                  class="btn mt-2" 
                  variant="tonal"
                  elevation="0"
                  @click="addWorkExperience()"
                >
                  {{ isEnglish ? 'Add another skill / experience' : 'Agregar otra habilidad / experiencia' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <br><br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent>
        </v-form>
      </v-col>
    </v-row>  
  </div>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import type { ApplicationWorkExperience, WorkExperience, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const yesOrNoRule = [(value: string) => isYesOrNoValid(value) || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const app = ref({} as ApplicationWorkExperience)
const workExperiences = ref([] as WorkExperience[])

const isEnglish = computed(() => {
  return store.language === 'english'
})

const types = ref([
  { id: '12', title: isEnglish.value ? 'Hard Physical Labor' : 'Trabajo Físico Pesado', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
  { id: '13', title: isEnglish.value ? 'Lifting For Long Periods' : 'Levantamiento por Períodos Prolongados', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
  { id: '14', title: isEnglish.value ? 'Working Nights' : 'Trabajando de Noche', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
  { id: '15', title: isEnglish.value ? 'Working Weekends' : 'Trabajando en los Fines de Semanas', subtitle: isEnglish.value ? 'Basic' : 'Básico' }, 
  { id: '1', title: isEnglish.value ? 'Supervisor' : 'Supervisor', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '2', title: isEnglish.value ? 'Warehouse Worker' : 'Trabajo de Almacén', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '6', title: isEnglish.value ? 'Order Selector' : 'Seleccionador', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '7', title: isEnglish.value ? 'Loader' : 'Cargador', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '8', title: isEnglish.value ? 'Stock Person' : 'Almacenador', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '9', title: isEnglish.value ? 'Fork-lift Operator' : 'Operador de Fork-lift', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '16', title: isEnglish.value ? 'Operate Pallet Jack' : 'Operador de Pallet Jack', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '17', title: isEnglish.value ? 'Operate Tugger Or Tow Motor' : 'Operador de Tugger o Tow Motor', subtitle: isEnglish.value ? 'Warehouse' : 'Almacén' }, 
  { id: '3', title: isEnglish.value ? 'Shipping Clerk' : 'Oficinista de Envío', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
  { id: '4', title: isEnglish.value ? 'Receiving Clerk' : 'Oficinista de Recibimiento', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
  { id: '5', title: isEnglish.value ? 'Inventory Clerk' : 'Oficinista de Inventario', subtitle: isEnglish.value ? 'Clerical' : 'Oficina' }, 
  { id: '10', title: isEnglish.value ? 'Truck Driver' : 'Conductor de Camión', subtitle: isEnglish.value ? 'Transportation' : 'Transportación' }, 
  { id: '11', title: isEnglish.value ? 'Delivery Person' : 'Repartidor', subtitle: isEnglish.value ? 'Transportation' : 'Transportación' }, 
  { id: '18', title: isEnglish.value ? 'Specify' : 'Especifique', subtitle: isEnglish.value ? 'Other' : 'Otro' }
])

const phase = computed(() => {
  return store.applicationPhase
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {  
    if (store.applicationId) {
      const r = await api.getApplicationWorkExperience(store.applicationId)
      if (r) {
        app.value = r
        if (app.value.workExperiences) {
          workExperiences.value = app.value.workExperiences
        }
      } else {
        app.value.id = store.applicationId
      } 
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const getTypes = (index: number) => {
  let selectedWorkExperiences = workExperiences.value.map(we => we.code)
  let selectedTypes = [] as string[]
  for (let v = 0; v < index; v++) {
    let we = selectedWorkExperiences[v]
    if (we) {
      selectedTypes.push(we)
    }
  }
  return types.value.filter(t => !selectedTypes.includes(t.id))
}

const isYesOrNoValid = (v: any) : boolean => {
  return v || v === true || v === false
}

const isHasWorkExperienceValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasWorkExperience === true || app.value.hasWorkExperience === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isWorkExperienceValid = (alert: boolean) : boolean => {
  if (app.value.hasWorkExperience && workExperiences.value.length) {
    let isValid = true
    workExperiences.value.every((e: WorkExperience) => {
      if (e.code) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Skill / Experience is invalid' : 'Habilidad / Experiencia no es válida')
        }
        return false
      }
      if (e.description) {
        // do nothing
      } else if (e.code === '18') {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Description is invalid' : 'Descripción no es válido')
        }
        return false
      }   
      if (e.location) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Company is invalid' : 'Compañía no es válida')
        }
        return false
      }     
      if (e.years) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Years is invalid' : 'Años no es válido')
        }
        return false
      } 
      return true
    })
    if(!isValid) {
      return false
    }      
  }
  return true 
}

const isFormValid = () : boolean => {
  return isHasWorkExperienceValid(true) &&
    isWorkExperienceValid(true)
}

const updateWorkExperience = () => {
  if (app.value.hasWorkExperience) {
    addWorkExperience()
  } else if (app.value.workExperiences?.length) {
    workExperiences.value = app.value.workExperiences
  } else {
    workExperiences.value = []
  }
}

const addWorkExperience = () => {
  var e : WorkExperience = {
    code: null,
    description: null,
    location: null,
    years: null,
    months: null,
  }
  workExperiences.value.push(e)
}

const deleteWorkExperience = (e: WorkExperience) => {
  const i = workExperiences.value.indexOf(e)
  if (i > -1) { 
    workExperiences.value.splice(i, 1)
  }
}

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/application/previous-employment')
  }
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value
    if (app.value.hasWorkExperience) {
      app.value.workExperiences = workExperiences.value
      app.value.workExperiences.forEach((w: WorkExperience) => {
        if (w.months) {
          // do nothing
        } else {
          w.months = 0
        }
      })
    } else {
      app.value.workExperiences = []
    }
    try {
      const r = await api.updateApplicationWorkExperience(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'work-experience',
          title: 'Work Experience',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else {
          router.push('/application/background-questions')
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving work experience' : 'Error al guardar la información de experiencia laboral')
    } 
  }
}
</script>

<style>

</style>
