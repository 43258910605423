<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Title -->
      <v-row>
        <v-col>
          <h3 class="d-flex justify-center"> 
            {{ isEnglish ? 'Report an issue' : 'Reporte un problema' }}
          </h3>
        </v-col>
      </v-row>
      <!-- Alert - Sent -->
      <v-row v-if="success">
        <v-col>
          <v-alert          
            border="start"
            type="success"
            variant="tonal"
          >
            {{ isEnglish ? 
              'Your issue has been submitted. You will be redirected to the login page' : 
              'Su problema ha sido enviado. Usted será dirigido a la página de inicio' }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Alert -->
      <v-row v-if="error">
        <v-col>
          <v-alert       
            border="start"
            type="error"
            variant="tonal"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row v-if="!success">
        <v-col>
          <v-form
            @submit.prevent="onSubmit()"
          >
            <div>
              <v-text-field
                v-model="name"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Name*' : 'Nombre*'"
                variant="outlined"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Email address*' : 'Correo electrónico*'"
                variant="outlined"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="phoneNumber"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'Phone number*' : 'Número de teléfono*'"
                variant="outlined"
                hide-details
                required
              ></v-text-field>
              <v-select
                v-model="helpCategory"
                :rules="required"
                :readonly="loading"
                :items="helpCategories"
                :item-title="isEnglish ? 'categoryEnglish' : 'categorySpanish'"
                item-value="id"
                class="tf-padding"
                :label="isEnglish ? 'Issue*' : 'Problema*'"
                variant="outlined"
                hide-details
                required
              ></v-select>
              <v-textarea 
                v-model="issueDescription"
                :rules="required"
                :readonly="loading"
                row-height="20"
                rows="2"
                auto-grow
                class="tf-padding"
                :label="isEnglish ? 'Please explain the issue*' : 'Por favor explique el problema*'"
                variant="outlined"
                hide-details
                required
              ></v-textarea>
            </div>
            <div class="d-flex justify-center">
              <v-btn 
                :loading="loading"
                class="btn submit-btn orange-btn" 
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'Submit' : 'Enviar' }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <!-- Login User - Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle">
            {{ isEnglish ? 'Back to ' : 'Regrese a ' }}
            <a class="login-link" :href="'#/login'">
              {{ isEnglish ? 'login' : 'ingrese a su cuenta' }}
            </a>
          </div>
        </v-col>
      </v-row> 
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { HelpUser } from '@/types'
import api from '@/api'
import { useRouter } from 'vue-router'
import LabattLogo from '@/components/LabattLogo.vue'
import { useStore } from '@/stores/store'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const success = ref(false)
const name = ref('')
const email = ref('')
const phoneNumber = ref('')
const helpCategories = ref()
const helpCategory = ref()
const issueDescription = ref('')
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r = await api.getHelpCategories()
    if (r) {
      helpCategories.value = r
    } else {
      error.value = true
      errorMessage.value = 'Error loading form'
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const user: HelpUser = {
      name: name.value,
      email: email.value,
      phoneNumber: phoneNumber.value,
      helpCategoryId: helpCategory.value,
      issueDescription: issueDescription.value,
      language: store.language
    }
    const r = await api.helpUser(user)
    if (r === 'Success') {
      success.value = true
      setTimeout(() => {
        router.push('/login')
      }, 5000)
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
</script>

<style>
.tf-padding {
  padding-bottom: 15px;
}
.submit-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
.login-link {
  margin-left: 5px;
  font-weight: 600;
}
</style>
