<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="4" />
      </v-col>
    </v-row>
    <br>
    <!-- Error - Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Previous Employment' : 'Empleo Previo' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <!-- Message -->
          <div class="font-sm"> 
            {{ isEnglish ? 
               'Please provide your complete employment history. Any gaps in employment must be explained.' : 
               'Por favor provea su historial de empleo completo. Cualquier brecha en el historial debe ser explicada.' }}
          </div>
          <br>            
          <!-- Previous Employment -->
          <div class="font-sm mb-3"> 
            {{ isEnglish ? 'Have you been employed before?*' : '¿Usted ha tenido empleo antes?*' }}
          </div>
          <v-radio-group
            class="radio-btn tf-padding d-flex justify-center"
            v-model="app.hasPreviousJobs"
            :rules="previousJobsRule"
            :readonly="loading"
            inline
            @update:modelValue="updateJob()"
          >
            <v-radio 
              class="mr-10"
              :label="isEnglish ? 'Yes' : 'Sí'" 
              :value="true"
            ></v-radio>
            <v-radio 
              class="mr-5"
              :label="'No'" 
              :value="false"
            ></v-radio>
          </v-radio-group>
          <div v-if="app.hasPreviousJobs">          
            <!-- Jobs - Section -->
            <div v-if="jobs.length > 0">
              <hr class="mb-5 mt-3">
              <div v-for="(j, i) in jobs">
                <v-row>
                  <v-col cols="8">
                    <div class="title-bold mb-3 mt-3"> {{ isEnglish ? 'Job' : 'Empleo' }} {{ jobs.indexOf(j) + 1 }} </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex justify-end">
                      <v-btn 
                        v-if="jobs.indexOf(j) > 0"
                        class="btn mt-1" 
                        variant="tonal"
                        elevation="0"
                        @click="deleteJob(j)"
                      >
                        {{ isEnglish ? 'Delete' : 'Eliminar' }} 
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- Company -->
                <v-text-field
                  v-model="j.company"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'Company name*' : 'Compañía*'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- Job Title -->
                <v-text-field
                  v-model="j.jobTitle"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'Job title*' : 'Título*'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <div v-if="phase === 0 || phase === 2">
                  <!-- Supervisor Name -->
                  <v-text-field
                    v-model="j.supervisorName"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'Supervisor name*' : 'Nombre del supervisor*'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Supervisor Phone Number -->
                  <v-text-field
                    v-model="j.supervisorPhoneNumber"
                    :rules="required"
                    :readonly="loading"
                    type="tel"
                    class="tf-padding"
                    :label="isEnglish ? 'Phone number*' : 'Número de teléfono*'"
                    variant="outlined"
                    v-mask="'###############'"
                    hide-details
                  ></v-text-field>
                  <!-- Address -->
                  <v-text-field
                    v-model="j.address.address1"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'Address*' : 'Dirección*'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- City -->
                  <v-text-field
                    v-model="j.address.city"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'City*' : 'Ciudad*'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- State -->
                  <v-select
                    v-model="j.address.state"
                    :rules="required"
                    :items="states"
                    :item-title="isEnglish ? 'name' : 'spanishName'"
                    item-value="id"
                    class="tf-padding"
                    :label="isEnglish ? 'State*' : 'Estado*'"
                    variant="outlined"
                    hide-details
                  ></v-select>
                  <!-- Zip code -->
                  <v-text-field
                    v-model="j.address.zipCode"
                    :rules="required"
                    :readonly="loading"
                    type="number"
                    class="tf-padding"
                    :label="isEnglish ? 'Zip code*' : 'Código postal*'"
                    variant="outlined"
                    v-mask="'#####'"
                    hide-details
                  ></v-text-field>
                </div>
                <div v-if="phase === 0 || phase === 1">
                  <!-- Start Date -->
                  <v-text-field
                    v-model="j.startDate"
                    :rules="required"
                    :readonly="loading"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Start date*' : 'Fecha de inicio*'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <div class="d-flex">
                    <!-- Starting Pay -->
                    <v-text-field
                      v-model="j.startingPay"
                      :rules="required"
                      :readonly="loading"
                      type="number"
                      width="10%"
                      class="tf-padding"
                      :label="isEnglish ? 'Starting pay*' : 'Paga inicial*'"
                      variant="outlined"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <v-icon
                          icon="mdi-currency-usd"
                          title="$"
                          size="small"
                        />
                      </template>
                    </v-text-field>  
                    <!-- Starting Pay Frequency -->    
                    <v-select
                      v-model="j.startingFrequency"
                      :rules="required"
                      :readonly="loading"
                      :items="[
                        { id: 'Hour', title: isEnglish ? 'Per hour' : 'Por hora' }, 
                        { id: 'Week', title: isEnglish ? 'Per week' : 'Por semana' }, 
                        { id: 'Year', title: isEnglish ? 'Per year' : 'Por año' }]"
                      item-value="id"
                      item-title="title"
                      width="10%"
                      class="tf-padding ml-2"
                      variant="outlined"
                      hide-details
                    ></v-select>
                  </div>
                  <!-- Currently Employed -->
                  <div class="font-sm mb-3"> 
                    {{ isEnglish ? 'Currently employed?*' : '¿Actualmente empleado?*' }}
                  </div>
                  <v-radio-group
                    class="radio-btn tf-padding d-flex justify-center"
                    v-model="jobsCurrentlyEmployed[i]"
                    :rules="yesOrNoRule"
                    :readonly="loading"
                    inline
                    @update:modelValue="updateCurrentlyEmployed(i)"
                  >
                    <v-radio 
                      class="mr-10"
                      :label="isEnglish ? 'Yes' : 'Sí'" 
                      :value="true"
                    ></v-radio>
                    <v-radio 
                      class="mr-5"
                      :label="'No'" 
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                  <div v-if="jobsCurrentlyEmployed[i] === false">
                    <!-- End Date -->
                    <v-text-field
                      v-model="j.endDate"
                      :rules="required"
                      :readonly="loading"
                      type="date"
                      class="tf-padding"
                      :label="isEnglish ? 'End date*' : 'Fecha de final*'"
                      variant="outlined"
                      hide-details
                    ></v-text-field>
                  </div>
                  <div 
                    v-if="jobsCurrentlyEmployed[i] === true || jobsCurrentlyEmployed[i] === false" 
                    class="d-flex"
                  >
                    <!-- Ending Pay -->
                    <v-text-field
                      v-model="j.endingPay"
                      :rules="required"
                      :readonly="loading"
                      type="number"
                      width="10%"
                      class="tf-padding"
                      :label="isEnglish ? jobsCurrentlyEmployed[i] ? 'Current pay*' : 'Ending pay*' : jobsCurrentlyEmployed[i] ? 'Paga actual*' : 'Paga final*'"
                      variant="outlined"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <v-icon
                          icon="mdi-currency-usd"
                          title="$"
                          size="small"
                        />
                      </template>
                    </v-text-field>  
                    <!-- Ending Pay Frequency -->    
                    <v-select
                      v-model="j.endingFrequency"
                      :rules="required"
                      :readonly="loading"
                      :items="[
                        { id: 'Hour', title: isEnglish ? 'Per hour' : 'Por hora' }, 
                        { id: 'Week', title: isEnglish ? 'Per week' : 'Por semana' }, 
                        { id: 'Year', title: isEnglish ? 'Per year' : 'Por año' }]"
                      item-value="id"
                      item-title="title"
                      width="10%"
                      class="tf-padding ml-2"
                      variant="outlined"
                      hide-details
                    ></v-select>
                  </div>
                </div>
                <div v-if="(phase === 0 || phase === 2) && !isOffice">      
                  <!-- Responsibilities -->
                  <div class="font-sm mb-3"> 
                    {{ isEnglish ? 'Responsibilities*' : 'Responsabilidades*' }}
                  </div>
                  <v-textarea
                    v-model="j.responsibilities"
                    :rules="required"
                    :readonly="loading"
                    row-height="20"
                    rows="2"
                    auto-grow
                    class="tf-padding"
                    variant="outlined"
                    hide-details
                  ></v-textarea> 
                </div>
                <div v-if="phase === 0 || phase === 1"> 
                  <div v-if="isTransportation">
                    <!-- Federal Regulations -->
                    <div class="font-sm mb-3"> 
                      {{ isEnglish ? 
                        'Were you subject to federal motor carrier safety regulations?*' : 
                        '¿Estuvo sujeto a regulaciones federales para vehículos de transporte?*' }}
                    </div>
                    <v-radio-group
                      class="radio-btn tf-padding d-flex justify-center"
                      v-model="j.isSubjectToFederalRegulations"
                      :rules="yesOrNoRule"
                      :readonly="loading"
                      inline
                    >
                      <v-radio 
                        class="mr-10"
                        :label="isEnglish ? 'Yes' : 'Sí'" 
                        :value="true"
                      ></v-radio>
                      <v-radio 
                        class="mr-5"
                        :label="'No'" 
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                    <!-- Safety Safety Function -->
                    <div class="font-sm mb-3"> 
                      {{ isEnglish ? 
                        'Was this job designated as a safety sensitive function in any DOT regulated mode ' + 
                        'subject to alcohol and controlled substance testing required by 49CFR Part 40?*' : 

                        '¿Era este empleo considerado una función de alta seguridad de acuerdo al Departamento de ' +
                        'Transporte y estaba sujeto a pruebas de drogas y alcohol requeridas por el código  ' +
                        '49CFR parte 40?*' }}
                    </div>
                    <v-radio-group
                      class="radio-btn tf-padding d-flex justify-center"
                      v-model="j.isSafetySensitiveFunction"
                      :rules="yesOrNoRule"
                      :readonly="loading"
                      inline
                    >
                      <v-radio 
                        class="mr-10"
                        :label="isEnglish ? 'Yes' : 'Sí'" 
                        :value="true"
                      ></v-radio>
                      <v-radio 
                        class="mr-5"
                        :label="'No'" 
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>  
                  <!-- Reasons -->
                  <div class="font-sm mb-3"> 
                    {{ isEnglish ? 
                       'Please state clearly your full reason(s) for leaving this job*' : 
                       'Indique la razón (o razones) por las que dejó este empleo*' }}
                  </div>
                  <v-textarea
                    v-model="j.reasonForLeaving"
                    :rules="required"
                    :readonly="loading"
                    row-height="20"
                    rows="2"
                    auto-grow
                    class="tf-padding"
                    variant="outlined"
                    hide-details
                  ></v-textarea>   
                </div>  
                <div v-if="phase === 0 || phase === 2">
                  <!-- Contact Employer -->
                  <div class="font-sm mb-3"> 
                    {{ isEnglish ? 'May we contact this employer?*' : '¿Podemos contactar a este empleador?*' }}
                  </div>
                  <v-radio-group
                    class="radio-btn tf-padding d-flex justify-center"
                    v-model="j.canContactEmployer"
                    :rules="yesOrNoRule"
                    :readonly="loading"
                    inline
                  >
                    <v-radio 
                      class="mr-10"
                      :label="isEnglish ? 'Yes' : 'Sí'" 
                      :value="true"
                    ></v-radio>
                    <v-radio 
                      class="mr-5"
                      :label="'No'" 
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </div>                 
              </div>
            </div>  
          </div>  
          <!-- Add Another Job - Btn -->
          <v-row v-if="app.hasPreviousJobs">
            <v-col>
              <div class="d-flex justify-center">
                <v-btn 
                  class="btn mt-2" 
                  variant="tonal"
                  elevation="0"
                  @click="addJob()"
                >
                  {{ isEnglish ? 'Add another job' : 'Agregar otro empleo' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <!-- Warning - Alert -->
          <div 
            v-if="warning"
            class="d-flex justify-center mt-4"
          >
            <v-alert
              border="start"
              type="warning"
              variant="tonal"
            >
              {{ warningMessage }}
            </v-alert>
          </div>
          <!-- Not Enough Previous Employment -->
          <div 
            v-if="notEnoughPreviousEmployment"
            class="d-flex" 
          >
            <v-checkbox 
              v-model="app.noMoreJobs"
              :rules="required"
              :readonly="loading"
              class="tf-padding mr-2"
              hide-details
            ></v-checkbox>
            <div class="mt-4"> 
              {{ isEnglish ? 
                 'I certify that I have entered all employment information available.' : 
                 'Certifico que he ingresado toda la información de empleo disponible.' }}
            </div>           
          </div>
          <br><br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent>
        </v-form>
      </v-col>
    </v-row>  
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import { State, PreviousJob, Address, ApplicationPreviousEmployment, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const warning = ref(false)
const warningMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const previousJobsRule = [() => isHasPreviousJobsValid(false) || 'Required']
const yesOrNoRule = [(value: string) => isYesOrNoValid(value) || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const states = ref([] as State[])
const app = ref({} as ApplicationPreviousEmployment)
const jobs = ref([] as PreviousJob[])
const notEnoughPreviousEmployment = ref(false as boolean | null)
const jobsCurrentlyEmployed = ref([] as any)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  warning.value = false
  warningMessage.value = ''
  try {    
    const r = await api.getStates()
    if (r) {
      states.value = r
    } else {
      error.value = true
      errorMessage.value = 'Error loading form'
    }
    if (store.applicationId) {
      const r2 = await api.getApplicationPreviousEmployment(store.applicationId)
      if (r2) {
        app.value = r2
        if (phase.value !== 2) {
          notEnoughPreviousEmployment.value = app.value.noMoreJobs
        }
        if (app.value.jobs) {
          jobs.value = app.value.jobs
          jobs.value.forEach(j => {
            if (phase.value === 2) {
              if (j.canContactEmployer === false) {
                j.canContactEmployer = null
              }
            }
            if (j.endDate) {
              if (j.endDate === new Date().toISOString().substring(0, 10)) {
                jobsCurrentlyEmployed.value.push(true)
              } else {
                jobsCurrentlyEmployed.value.push(false)
              }
              if (j.supervisorPhoneNumber === 0) {
                j.supervisorPhoneNumber = null
              }
              if (j.address.zipCode === 0) {
                j.address.zipCode = null
              }
            } else {
              jobsCurrentlyEmployed.value.push(null)
            }
          })
        }
      } else {
        app.value.id = store.applicationId
      } 
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const showWarning = (message: string) => {
  warning.value = true
  warningMessage.value = message
  loading.value = false
}

const isYesOrNoValid = (v: any) : boolean => {
  return v || v === true || v === false
}

const isHasPreviousJobsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasPreviousJobs === true || app.value.hasPreviousJobs === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousJobValid = (alert: boolean) : boolean => {
  let smallestDate =  new Date(0)
  let largestDate = new Date(0)
  if (app.value.hasPreviousJobs && jobs.value.length) {
    let isValid = true
    jobs.value.every((j: PreviousJob, i: number) => {
      if (phase.value === 0 || phase.value === 1) {
        if (j.company) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Company name is invalid' : 'Compañía no es válida')
          }
          return false
        }
        if (j.jobTitle) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Job title is invalid' : 'Título de solicitante no es válido')
          }
          return false
        }
      }
      if (phase.value === 0 || phase.value === 2) {
        if (j.supervisorName) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Supervisor name is invalid' : 'Nombre del supervisor no es válido')
          }
          return false
        }
        if (j.supervisorPhoneNumber) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Phone number is invalid' : 'Número de teléfono no es válido')
          }
          return false
        }
        if (j.address.address1) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Address is invalid' : 'Dirección no es válido')
          }
          return false
        }
        if (j.address.city) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'City is invalid' : 'Ciudad no es válido')
          }
          return false
        }
        if (j.address.state) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'State is invalid' : 'Estado no es válido')
          }
          return false
        }
        if (j.address.zipCode) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Zip code is invalid' : 'Código postal no es válido')
          }
          return false
        }
      }
      if (phase.value === 0 || phase.value === 1) {
        if (j.startDate) {
          const date = new Date(j.startDate)
          if (date && (smallestDate.getFullYear() === new Date(0).getFullYear() || date < smallestDate)) {
            smallestDate = date
          }
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Start date is invalid' : 'Fecha de inicio no es válida')
          }
          return false
        }
        if (j.startingPay && j.startingFrequency) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Starting pay is invalid' : 'Pago inicial no es válido')
          }
          return false
        }
        if (jobsCurrentlyEmployed.value[i] === true || jobsCurrentlyEmployed.value[i] === false) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas resaltadas')
          }
          return false
        }
        if (j.endDate) {
          const date = new Date(j.endDate)
          if (date && (largestDate.getFullYear() === new Date(0).getFullYear() || date > largestDate)) {
            largestDate = date
          }
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'End date is invalid' : 'Fecha de término no es válida')
          }
          return false
        }
        if (j.endingPay && j.startingFrequency) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Ending pay is invalid' : 'Pago final no es válido')
          }
          return false
        }
      }
      if ((phase.value === 0 || phase.value === 2) && !isOffice.value) {
        if (j.responsibilities) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Responsibilities is invalid' : 'Responsabilidades no es válido')
          }
          return false
        }
      }
      if (phase.value === 0 || phase.value === 1) {
        if (isTransportation.value === false || j.isSubjectToFederalRegulations === true || j.isSubjectToFederalRegulations === false) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
          }
          return false
        }
        if (isTransportation.value === false || j.isSafetySensitiveFunction === true || j.isSafetySensitiveFunction === false) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
          }
          return false
        }
        if (j.reasonForLeaving) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
          }
          return false
        }
      }
      if (phase.value === 0 || phase.value === 2) {
        if (j.canContactEmployer === true || j.canContactEmployer === false) {
          // do nothing
        } else {
          isValid = false
          if (alert) {
            showError(isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas')
          }
          return false
        }
      }
      return true
    })

    if(!isValid) {
      return false
    } 
  }
  if (!app.value.noMoreJobs && alert) {
    var maxTimeNeededInDays = 1095 // 3 years (1095 days)
    if (isTransportation.value) {
      maxTimeNeededInDays = 3650 // if applicant is driver get 10 years (3650 days) of jobs
    }
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const timeInDays = Math.round(Math.abs((largestDate.getTime() - smallestDate.getTime()) / (oneDay)))
    if (timeInDays < maxTimeNeededInDays) {

      const msg = isEnglish.value ? 'You have entered less than ' + (maxTimeNeededInDays / 365) + ' years of employment history. ' +
      'If you have more employment, press the button above and add another job. Otherwise, select the box below.' : 

      'Ha ingresado menos de ' + (maxTimeNeededInDays / 365) + ' años de historial de empleo. ' +
      'Si ha tenido más empleos, presione el botón de arriba y agregue otro empleo. De no ser así, seleccione la opción abajo.'

      showWarning(msg)
      app.value.noMoreJobs = false
      notEnoughPreviousEmployment.value = true
      return false
    }
  }
  return true 
}

const isFormValid = () : boolean => {
  return isHasPreviousJobsValid(true) && 
    isPreviousJobValid(true)
}

const updateJob = () => {
  if (app.value.hasPreviousJobs) {
    addJob()
  } else if (app.value.jobs?.length) {
    jobs.value = app.value.jobs
  } else {
    jobs.value = []
  }
}

const updateCurrentlyEmployed = (i: number) => {
  if (jobs.value && jobs.value[i]) {
    if (jobsCurrentlyEmployed.value[i]) {
      // @ts-ignore 
      jobs.value[i].endDate = new Date().toISOString().substring(0, 10)
    } else {
      // @ts-ignore 
      jobs.value[i].endDate = null
    }
  }
}

const addJob = () => {
  var address : Address = {
    address1: null,
    address2: null,
    apartmentNumber: null,
    city: null,
    state: null,
    zipCode: phase.value === 1 ? 0 : null,
    phoneNumber: 0,
    alternatePhoneNumber: 0,
    years: 0,
    months: 0,
  }
  var job: PreviousJob = {
    company: null,
    jobTitle: null,
    supervisorName: null,
    supervisorPhoneNumber: phase.value === 1 ? 0 : null,
    address: address,
    responsibilities: null,
    startDate: null,
    endDate: null,
    startingPay: null,
    startingFrequency: null,
    endingPay: null,
    endingFrequency: null,
    reasonForLeaving: null,
    canContactEmployer: phase.value === 1 ? false : null,
    isSubjectToFederalRegulations: isTransportation.value ? null : false,
    isSafetySensitiveFunction: isTransportation.value ? null : false,
  }
  jobs.value.push(job) 
}

const deleteJob = (j: PreviousJob) => {
  const i = jobs.value.indexOf(j)
  if (i > -1) { 
    jobs.value.splice(i, 1)
  }
}

const previous = () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/application/education-military')
  }
}

const save = async (exit?: boolean) => { 
  loading.value = true
  error.value = false
  errorMessage.value = ''
  warning.value = false
  warningMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value
    if (app.value.hasPreviousJobs) {
      app.value.jobs = jobs.value
    } else {
      app.value.jobs = []
    }
    try {
      const r = await api.updateApplicationPreviousEmployment(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'previous-employment',
          title: 'Previous Employment',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else if (phase.value === 1 || isOffice.value) {
          router.push('/application/background-questions')
        } else {
          router.push('/application/work-experience')
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving previous employment info' : 'Error al guardar la información de empleo previo')
    } 
  }
}
</script>

<style>

</style>
