<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Title -->
      <v-row>
        <v-col>
          <h3 class="d-flex justify-center"> 
            {{ isEnglish ? 'Log in to your account' : 'Ingrese a su cuenta' }}
          </h3>
        </v-col>
      </v-row>
      <!-- Alert -->
      <v-row v-if="error">
        <v-col>
          <v-alert
            border="start"
            type="error"
            variant="tonal"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row>
        <v-col>
          <v-form
            @submit.prevent="onSubmit()"
          >
            <div>
              <v-text-field
                v-model="email"
                :rules="required"
                :readonly="loading"
                prepend-inner-icon="mdi-email-outline"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Email address' : 'Correo electrónico'"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                :rules="required"
                :readonly="loading"
                :type="showPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock-outline"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Password' : 'Contraseña'"
                hide-details
                required
                @click:append-inner="showPassword = !showPassword"
              ></v-text-field>
            </div>
            <div class="subtitle forgot-password">
              <a :href="'#/reset-password'">
                {{ isEnglish ? 'Forgot password?' : '¿Olvidó su contraseña?' }}
              </a>
            </div>
            <div class="d-flex justify-center">
              <v-btn 
                :loading="loading"
                class="btn login-btn orange-btn" 
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'Log in' : 'Ingresar' }}  
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <br><br>
      <!-- Create User - Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle"> 
            {{ isEnglish ? 'New to Labatt?' : '¿Nuevo a Labatt?' }}  
            <a class="create-user-link" :href="'#/create'">
              {{ isEnglish ? 'Create an account' : 'Cree una cuenta' }}              
            </a>
          </div>
        </v-col>
      </v-row>
      <br><br><br>
      <!-- Help - Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle help-link"> 
            <a :href="'#/help'"> 
              {{ isEnglish ? 'Help' : 'Soporte' }}    
            </a>
          </div>
        </v-col>
      </v-row>  
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { LoginUser } from '@/types'
import api from '@/api'
import { useRouter, useRoute } from 'vue-router'
import LabattLogo from '@/components/LabattLogo.vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const email = ref('')
const password = ref('')
const showPassword = ref(false)
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const route = useRoute()
const { cookies } = useCookies()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (route.query.j) {
    store.setJobPostingId(route.query.j.toString())
  }
  if (route.query.h) {
    store.setWebHistory(route.query.h.toString())
  } else {
    store.setWebHistory(document.referrer)
  }
  if (cookies.get('labatt-user-email')) {
    router.push('/dashboard')
  } else {
    store.setCurrentEmail('')
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const user: LoginUser = {
      email: email.value,
      password: password.value,
      language: store.language
    }
    const r = await api.loginUser(user)
    if (r === 'Success') {
      cookies.set('labatt-user-email', email.value, '1d')
      store.setCurrentEmail(email.value)
      router.push('/dashboard')
    } else if (r === 'Verify email') {
      cookies.set('labatt-user-email', email.value, '1d')
      store.setCurrentEmail(email.value)
      router.push('/verify')
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
</script>

<style>
.tf-padding {
  padding-bottom: 15px;
}
.login-btn {
  margin-top: 22px;
  margin-bottom: 15px;
}
.forgot-password {
  margin-left: 10px;
  font-weight: 600;
}
.create-user-link {
  margin-left: 5px;
  font-weight: 600;
}
.help-link {
  margin-bottom: 15px;
  font-weight: 600;
}
</style>
