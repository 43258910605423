<template>
  <div class="d-flex justify-center align-center">
    <v-progress-linear
      class="activity-progress"
      bg-color="#92aed9"
      color="#f37027"
      height="18"
      :max="max"
      :min="0"
      :model-value="current"
      rounded
      striped
    ></v-progress-linear>
    <div class="ml-10 title-bold"> {{ current }}/{{ max }} </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'

const current = ref(0)
const max = ref(0)

const props = defineProps({
  activity: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
})

const reload = async () => {
  current.value = props.activity > props.total ? props.total : props.activity
  max.value = props.total
}
onMounted(reload)

</script>

<style>
.activity-progress {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .activity-progress {
    width: 60%;
  }
}
@media screen and (min-width: 1025px) {
  .activity-progress {
    width: 40%;
  }
}
</style>
