<template>
  <div class="account">
    <!-- Logout -->
    <v-container class="container-view logout-view elevation-2">
      <LogoutComponent :view="isEnglish ? 'My Account' : 'Mi Cuenta'" />
    </v-container>
    <!-- View -->
    <v-container class="container-view account-inner elevation-2">
      <!-- Loading -->
      <div v-if="loading">
        <br>
        <div class="d-flex justify-center">
          <v-progress-circular
            class="orange-spinner"
            indeterminate
          ></v-progress-circular>
        </div>      
      </div>
      <div v-else class="mt-3">
        <!-- Alert -->
        <div 
          v-if="error"
          class="d-flex justify-center mb-5"
        >
          <div class="alert">
            <v-alert
              border="start"
              type="error"
              variant="tonal"
            >
              {{ errorMessage }}
            </v-alert>
          </div> 
        </div>
        <!-- Form -->
        <v-row>
          <v-col>
            <v-form
              @submit.prevent="onSubmit()"
            >
              <div>
                <div class="font-sm mb-4">
                  <span>
                    <b> {{ isEnglish ? 'Personal Information' : 'Información Personal' }} </b>
                  </span>
                  <v-btn 
                    class="btn ml-8" 
                    variant="tonal"
                    elevation="0"
                    @click="editPersonalInformation = true"
                  >
                    {{ isEnglish ? 'Edit' : 'Editar' }}
                  </v-btn>
                </div>
                <div v-if="editPersonalInformation">
                  <v-text-field
                    v-model="accountInfo.firstName"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    :label="isEnglish ? 'First name*' : 'Nombre*'"
                    variant="outlined"
                    hide-details
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="accountInfo.middleName"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Middle name' : 'Segundo nombre'"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-model="accountInfo.lastName"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Last name*' : 'Apellido*'"
                    hide-details
                    required
                  ></v-text-field>
                </div>
                <div v-else>
                  <div class="mb-2">
                    <label><b> {{ isEnglish ? 'First name' : 'Nombre' }}: </b></label> 
                    <span class="ml-1"> {{ accountInfo.firstName }} </span>
                  </div>
                  <div class="mb-2">
                    <label><b> {{ isEnglish ? 'Middle name' : 'Segundo nombre' }}: </b></label> 
                    <span class="ml-1"> {{ accountInfo.middleName }} </span>
                  </div>
                  <div class="mb-2">
                    <label><b> {{ isEnglish ? 'Last name' : 'Apellido' }}: </b></label> 
                    <span class="ml-1"> {{ accountInfo.lastName }} </span>
                  </div>
                </div>
                <div class="font-sm mb-4 mt-6">
                  <span>
                    <b> {{ isEnglish ? 'Login Credentials' : 'Credenciales de Acceso' }} </b>
                  </span>
                  <v-btn 
                    class="btn ml-8" 
                    variant="tonal"
                    elevation="0"
                    @click="editLoginCredentials = true"
                  >
                    {{ isEnglish ? 'Edit' : 'Editar' }}
                  </v-btn>
                </div>
                <div v-if="editLoginCredentials">
                  <v-text-field
                    v-model="accountInfo.email"
                    :rules="required"
                    :readonly="loading"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Email address*' : 'Correo electrónico*'"
                    hide-details
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="accountInfo.currentPassword"
                    :append-inner-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                    :rules="required"
                    :readonly="loading"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Current password*' : 'Contraseña actual*'"
                    hide-details
                    required
                    @click:append-inner="showCurrentPassword = !showCurrentPassword"
                  ></v-text-field>
                  <v-text-field
                    v-model="accountInfo.newPassword"
                    :append-inner-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                    :rules="required"
                    :readonly="loading"
                    :type="showNewPassword ? 'text' : 'password'"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'New password*' : 'Nueva contraseña*'"
                    hide-details
                    required
                    @click:append-inner="showNewPassword = !showNewPassword"
                  ></v-text-field>
                  <v-text-field
                    v-model="accountInfo.confirmNewPassword"
                    :append-inner-icon="showConfirmNewPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                    :rules="required"
                    :readonly="loading"
                    :type="showConfirmNewPassword ? 'text' : 'password'"
                    class="tf-padding"
                    variant="outlined"
                    :label="isEnglish ? 'Confirm new password*' : 'Confirme su nueva contraseña*'"
                    hide-details
                    required
                    @click:append-inner="showConfirmNewPassword = !showConfirmNewPassword"
                  ></v-text-field>
                </div>
                <div v-else>
                  <div class="mb-2">
                    <label><b> {{ isEnglish ? 'Email address' : 'Correo electrónico' }}: </b></label> 
                    <span class="ml-1"> {{ accountInfo.email }} </span>
                  </div>
                  <div class="mb-2">
                    <label><b> {{ isEnglish ? 'Password' : 'Contraseña' }}: </b></label> 
                    <span class="ml-1"> ********** </span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-center mt-6">
                <!-- Return - Btn -->
                <v-btn 
                  :loading="loading"
                  class="btn orange-btn mr-15" 
                  :width="113" 
                  elevation="0"
                  @click="backToDashboard()"
                >
                  {{ isEnglish ? 'Return' : 'Regresar' }}
                </v-btn>
                <!-- Save - Btn -->
                <v-btn 
                  :loading="loading"
                  :disabled="!editLoginCredentials && !editPersonalInformation"
                  class="btn orange-btn"
                  :width="113"  
                  elevation="0"
                  type="submit"
                >
                  {{ isEnglish ? 'Save' : 'Guardar' }}
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </div>    
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import LogoutComponent from '@/components/LogoutComponent.vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'
import api from '@/api'
import type { CurrentUser, AccountInformation } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const user = ref({} as CurrentUser)
const accountInfo = ref({} as AccountInformation)
const showCurrentPassword = ref(false)
const showNewPassword = ref(false)
const showConfirmNewPassword = ref(false)
const editPersonalInformation = ref(false)
const editLoginCredentials = ref(false)
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const { cookies } = useCookies()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reset = () => {
  showCurrentPassword.value = false
  showNewPassword.value = false
  showConfirmNewPassword.value = false
  editPersonalInformation.value = false
  editLoginCredentials.value = false
}

const reload = async () => {
  loading.value = true
  reset()
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    const r = await api.getCurrentUser(userEmail)
    if (r) {
      user.value = r
      accountInfo.value.firstName = user.value.firstName
      accountInfo.value.middleName = user.value.middleName
      accountInfo.value.lastName = user.value.lastName
      accountInfo.value.email = user.value.username
      accountInfo.value.language = store.language
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
  } else {
    router.push('/login')
  }
  loading.value = false
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    var r = await api.updateAccountInformation(accountInfo.value)
    if (r === 'Success') {
      reload()
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}

const backToDashboard = () => { 
  router.push('/dashboard')
}
</script>

<style>
.account {
  background-color: #f3f2f2;
}
.account-inner {
  background-color: white;
  min-height: 81.5dvh;
}
.logout-view {
  background-color: white;
}
.alert {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .account-inner {
    min-height: 85dvh;
  }
}
@media screen and (min-width: 1025px) {
  .account-inner {
    min-height: 81.5dvh;
  }
}
</style>
