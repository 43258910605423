<template>
  <v-row>
    <v-col>
      <!-- Previous - Btn -->
      <div class="d-flex justify-start">
        <v-btn 
          :loading="props.loading"
          class="btn orange-btn"
          :width="113" 
          elevation="0"
          @click="emitPrevious()"
        >
          <span v-if="isEnglish">
            {{ store.isApplicationReview ? 'Return' : 'Previous' }}
          </span>
          <span v-else>
            Regresar
          </span>
        </v-btn>
      </div>
    </v-col>
    <v-col>
      <!-- Next - Btn -->
      <div class="d-flex justify-end">
        <v-btn 
          :loading="props.loading"
          class="btn orange-btn" 
          :width="113" 
          elevation="0"
          type="submit"
        >
          <span v-if="isEnglish">
            {{ store.isApplicationReview ? 'Save' : 'Next' }}
          </span>
          <span v-else>
            {{ store.isApplicationReview ? 'Guardar' : 'Continuar' }}
          </span>
        </v-btn>
      </div>        
    </v-col>
  </v-row>
  <br>
  <!-- Save & Exit - Btn -->
  <div class="d-flex justify-center mb-5">
    <v-btn 
      v-if="!store.isApplicationReview"
      :loading="props.loading"
      class="btn orange-btn" 
      elevation="0"
      type="submit"
      @click="emitExit()"
    >
      {{ isEnglish ? 'Save & exit' : 'Guardar y salir' }}
    </v-btn>
  </div> 
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useStore } from '@/stores/store'

const store = useStore()
const emit = defineEmits(['exit', 'previous'])

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  }
})

const isEnglish = computed(() => {
  return store.language === 'english'
})

const emitExit = () => {
  emit('exit')  
}

const emitPrevious = () => {
  emit('previous')  
}
</script>
