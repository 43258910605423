<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Loading -->
      <div v-if="loading">
        <br><br>
        <div class="d-flex justify-center">
          <v-progress-circular
            class="orange-spinner"
            indeterminate
          ></v-progress-circular>
        </div>      
      </div>
      <div v-else>
        <div v-if="jobNoLongerAvailable">
          <div class="d-flex justify-center title-bold mt-10 mb-2"> 
            This job is no longer available 
          </div>
        </div>
        <div v-else>
          <!-- Job Posting -->
          <v-row>
            <v-col>
              <div class="d-flex justify-center title-bold mt-4 mb-2">
                {{ jobPosting?.title }}
              </div>
            </v-col>
          </v-row>
          <!-- Title -->
          <v-row>
            <v-col>
              <div class="d-flex justify-center title-bold"> 
                Submit Resume
              </div>
            </v-col>
          </v-row>
          <!-- Subtitle -->
          <div class="d-flex justify-center subtitle s-title"> 
            Please use First name and Last name as seen on official ID
          </div>
          <!-- Alert -->
          <v-row v-if="error">
            <v-col>
              <v-alert          
                border="start"
                type="error"
                variant="tonal"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
          <!-- Form -->
          <v-row>
            <v-col>
              <v-form
                @submit.prevent="onSubmit()"
              >
                <!-- First Name -->
                <v-text-field
                  v-model="resume.firstName"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  label="First name*"
                  variant="outlined"
                  hide-details
                  required
                ></v-text-field>
                <!-- Middle Name -->
                <v-text-field
                  v-model="resume.middleName"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  label="Middle name"
                  hide-details
                ></v-text-field>
                <!-- Last Name -->
                <v-text-field
                  v-model="resume.lastName"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  label="Last name*"
                  hide-details
                  required
                ></v-text-field>
                <!-- Email -->
                <v-text-field
                  v-model="resume.email"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  label="Email address*"
                  hide-details
                  required
                ></v-text-field>        
                <!-- Phone Number -->
                <v-text-field
                  v-model="phoneNumber"
                  :rules="phoneNumberRule"
                  :readonly="loading"
                  type="tel"
                  class="tf-padding"
                  label="Phone number*"
                  variant="outlined"
                  v-mask="'(###) ###-####'"
                  hide-details
                  required
                ></v-text-field>
                <!-- Preferred Name -->
                <v-text-field
                  v-model="resume.preferredName"
                  :readonly="loading"
                  class="tf-padding"
                  label="Preferred name"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- Referral Source -->
                <v-select
                  v-model="resume.referralSourceCode"
                  :rules="required"
                  :readonly="loading"
                  :items="referralSources"
                  item-value="code"
                  item-title="description"
                  class="tf-padding"
                  label="How were you referred to us?*"
                  variant="outlined"
                  @update:modelValue="updateReferralSource()"
                  hide-details
                  required
                ></v-select>            
                <!-- Referral Source Details -->
                <v-text-field
                  v-if="resume.hasReferralSourceDetails"
                  v-model="resume.referralSourceDetails"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  :label="formatReferralSourceDetailLabel"
                  hide-details
                  required
                ></v-text-field>
                <!-- Has College Education -->
                <div class="font-sm mb-3"> 
                  College education?*
                </div>
                <v-radio-group
                  class="radio-btn tf-padding d-flex justify-center"
                  v-model="resume.hasCollegeEducation"
                  :rules="yesOrNoRule"
                  :readonly="loading"
                  inline
                  @update:modelValue="updateCollege()"
                >
                  <v-radio 
                    class="mr-10"
                    :label="'Yes'" 
                    :value="true"
                  ></v-radio>
                  <v-radio 
                    class="mr-5"
                    :label="'No'" 
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <!-- College -->
                <div 
                  v-if="resume.collegeId"
                  class="mb-2"
                >
                  Selected College: <br>
                  <div class="mt-1">
                    <span class="mr-3"> {{ colleges.find(c => c.id === resume.collegeId)?.fullName }} </span>
                    <v-btn 
                      class="btn" 
                      size="small"
                      variant="tonal"
                      elevation="0"
                      @click="resume.collegeId = null"
                    >
                      Change
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <v-autocomplete
                    v-if="resume.hasCollegeEducation"
                    v-model="resume.collegeId"
                    :rules="collegeRule"
                    :readonly="loading"
                    :items="colleges"
                    item-value="id"
                    item-title="fullName"
                    class="tf-padding"
                    variant="outlined"
                    label="Please select college*"
                    @update:modelValue="updateCollegeAutocomplete()"
                    hide-details
                  ></v-autocomplete>
                </div>
                <!-- Other College -->
                <v-checkbox 
                  v-if="resume.hasCollegeEducation"
                  v-model="resume.isOtherCollege"
                  :readonly="loading"
                  class="radio-btn tf-padding"
                  label="Other college (not listed)"
                  density="compact"
                  @update:modelValue="updateOtherCollege()"
                  hide-details
                ></v-checkbox>            
                <!-- Other College Name -->
                <v-text-field
                  v-if="resume.hasCollegeEducation && resume.isOtherCollege"
                  v-model="resume.otherCollegeName"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  variant="outlined"
                  label="Enter college name*"
                  hide-details
                  required
                ></v-text-field>
                <!-- Is Internship -->
                <div v-if="resume.isInternship && resume.hasCollegeEducation">
                  <div class="font-sm mb-3"> 
                    Expected Graduation Date
                  </div>
                  <div class="d-flex">
                    <!-- Expected Graduation Month -->
                    <v-select
                      v-model="resume.expectedGraduationMonth"
                      :rules="required"
                      :readonly="loading"
                      :items="[
                        { id: 1, title: 'January' }, 
                        { id: 2, title: 'February' }, 
                        { id: 3, title: 'March' }, 
                        { id: 4, title: 'April' }, 
                        { id: 5, title: 'May' }, 
                        { id: 6, title: 'June' }, 
                        { id: 7, title: 'July' }, 
                        { id: 8, title: 'August' }, 
                        { id: 9, title: 'September' }, 
                        { id: 10, title: 'October' }, 
                        { id: 11, title: 'November' }, 
                        { id: 12, title: 'December' }, 
                      ]"
                      item-value="id"
                      item-title="title"
                      class="tf-padding"
                      variant="outlined"
                      label="Month"
                      hide-details
                      required
                    ></v-select>
                    <!-- Expected Graduation Year -->
                    <v-text-field
                      v-model="resume.expectedGraduationYear"
                      :rules="required"
                      :readonly="loading"
                      type="number"
                      width="10%"
                      min="0"
                      step="1"
                      class="tf-padding ml-2"
                      label="Year"
                      variant="outlined"
                      v-mask="'####'"
                      hide-details
                      required
                    ></v-text-field>
                  </div>
                </div>
                <div v-if="resume.email"> 
                  <div class="mt-2 mb-4">
                    Attach Documents 
                  </div>
                  <div v-if="resumeLoading">
                    <div>
                      <v-progress-circular
                        class="orange-spinner"
                        indeterminate
                      ></v-progress-circular>
                    </div>      
                  </div>
                  <div v-else>
                    <!-- Resume Upload -->
                    <v-file-input 
                      v-model="resumeFiles"
                      :rules="required"
                      :readonly="loading"
                      class="tf-padding"
                      label="Resume (.pdf only)*"
                      variant="outlined"
                      hint="PDF Max Size: 4MB"
                      persistent-hint
                      @update:modelValue="handleResume()"
                      show-size
                      required
                    >
                    </v-file-input>
                  </div>
                  <div v-if="coverLetterLoading">
                    <div>
                      <v-progress-circular
                        class="orange-spinner"
                        indeterminate
                      ></v-progress-circular>
                    </div>      
                  </div>
                  <div v-else>
                    <!-- Cover Letter Upload -->
                    <v-file-input 
                      v-model="coverLetterFiles"
                      :readonly="loading"
                      class="tf-padding mt-2"
                      label="Cover Letter (.pdf only)"
                      variant="outlined"
                      hint="PDF Max Size: 4MB"
                      persistent-hint 
                      @update:modelValue="handleCoverLetter()"
                      show-size
                    >
                    </v-file-input>
                  </div>
                </div>
                <!-- Submit -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :loading="loading"
                    class="btn submit-btn orange-btn" 
                    elevation="0"
                    type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave  } from 'vue-router'
import { useStore } from '@/stores/store'
import api from '@/api'
import LabattLogo from '@/components/LabattLogo.vue'
import type { Resume, ReferralSource, JobPosting, College, FileDoc } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const jobPosting = ref(null as JobPosting | null)
const required = [(value: string) => !!value || 'Required']
const phoneNumberRule = [() => isPhoneNumberValid(false) || 'Invalid']
const yesOrNoRule = [(value: string) => isYesOrNoValid(value) || 'Required']
const collegeRule = [(value: string) => isCollegeValid(false) || 'Invalid']
const router = useRouter()
const route = useRoute()
const store = useStore()
const resume = ref({} as Resume)
const phoneNumber = ref('')
const referralSources = ref([] as ReferralSource[])
const colleges = ref([] as College[])
const jobNoLongerAvailable = ref(false)
const resumeFiles = ref([] as File[])
const resumeLoading = ref(false)
const resumeUploaded = ref(null as FileDoc | null)
const coverLetterFiles = ref([] as File[])
const coverLetterLoading = ref(false)
const coverLetterUploaded = ref(null as FileDoc | null)

const reload = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  store.setDisableLanguage(true)
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    if (route.query.j) {
      jobPosting.value = await api.getJobPosting(route.query.j.toString())
      if (jobPosting.value) {
        resume.value.isInternship = jobPosting.value.isInternship
        if (route.query.h) {
          store.setWebHistory(route.query.h.toString())
        } else {
          store.setWebHistory(document.referrer)
        }
        const r = await api.getReferralSources()
        if (r) {
          referralSources.value = r
          if (jobPosting.value) {
            const officeCode = jobPosting.value.officeCode
            if (officeCode) {
              referralSources.value = r.filter(rs => rs.offices.includes(officeCode))
            }
            if (jobPosting.value.positionType === 'Office' || jobPosting.value.positionType === 'DSMOffice') {
              referralSources.value = referralSources.value.filter(rs => rs.isForResume === true)
            }
            referralSources.value.sort((a, b) => a.description.localeCompare(b.description))
          }
        } else {
          showError('Error loading form')
        }
        const r2 = await api.getColleges()
        if (r2) {
          colleges.value = r2
        } else {
          showError('Error loading form')
        }
      } else {
        jobNoLongerAvailable.value = true
      }
    } else {
      jobNoLongerAvailable.value = true
    }
  } catch (e) {
    showError('Please reload the page and try again')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

onBeforeRouteLeave((to, from) => {
  store.setDisableLanguage(false)
})

const updateReferralSource = () => {
  const selected = referralSources.value.find(r => r.code === resume.value.referralSourceCode)
  if (selected) {
    resume.value.hasReferralSourceDetails = selected.needDetails
  }
}

const updateCollege = () => {
  resume.value.collegeId = null
  resume.value.isOtherCollege = false
  resume.value.otherCollegeName = null
}

const updateOtherCollege = () => {
  resume.value.collegeId = null
  resume.value.otherCollegeName = null
}

const updateCollegeAutocomplete = () => {
  resume.value.isOtherCollege = false
  resume.value.otherCollegeName = null
}

const formatReferralSourceDetailLabel = computed(() => {
  const found = referralSources.value.find(rs => rs.code === resume.value.referralSourceCode) 
  if (found) {
    if(found.description.includes('Labatt employee')) {
      return 'Employee\'s name*'
    }
    if(found.description.includes('Driving school')) {
      return 'Name*'
    }
  }
  return 'Please explain*'
})

const isYesOrNoValid = (v: any) : boolean => {
  return v || v === true || v === false
}

const isFirstNameValid = (alert: boolean) : boolean => {
  const msg = 'First name is invalid'
  if (resume.value.firstName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isLastNameValid = (alert: boolean) : boolean => {
  const msg = 'Last name is invalid'
  if (resume.value.lastName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isEmailValid = (alert: boolean) : boolean => {
  const msg = 'Email address is invalid'
  if (resume.value.email) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhoneNumberValid = (alert: boolean) : boolean => {
  const msg = 'Phone number is invalid'
  if (phoneNumber.value) {
    const phone = phoneNumber.value.toString()
    const pattern = new RegExp('^\\(?\\d{3}\\)?\\s ?\\d{3}-\\d{4}$')
    if(!phone.match(pattern)) {
      if (alert) {
        showError(msg)
      }
      return false
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceValid = (alert: boolean) : boolean => {
  const msg = 'Please answer the required question(s)'
  if (resume.value.referralSourceCode) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceDetailsValid = (alert: boolean) : boolean => {
  const msg = 'Please answer the required question(s)'
  if (resume.value.hasReferralSourceDetails === false || resume.value.referralSourceDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isHasCollegeEducationValid = (alert: boolean) : boolean => {
  const msg = 'Please answer the required question(s)'
  if (resume.value.hasCollegeEducation === true || resume.value.hasCollegeEducation === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isCollegeValid = (alert: boolean) : boolean => {
  const msg = 'Please answer the required question(s)'
  if (resume.value.hasCollegeEducation === false || resume.value.isOtherCollege === true || resume.value.collegeId) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isOtherCollegeNameValid = (alert: boolean) : boolean => {
  const msg = 'Please answer the required question(s)'
  if (resume.value.hasCollegeEducation === false || resume.value.isOtherCollege === false || resume.value.otherCollegeName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isExpectedGraduationMonthValid = (alert: boolean) : boolean => {
  const msg = 'Expected graduation month is invalid'
  if (resume.value.hasCollegeEducation === false || resume.value.isInternship === false || resume.value.expectedGraduationMonth) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isExpectedGraduationYearValid = (alert: boolean) : boolean => {
  const msg = 'Expected graduation year is invalid'
  if (resume.value.hasCollegeEducation === false || resume.value.isInternship === false || resume.value.expectedGraduationYear) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isResumeFileValid = (alert: boolean) : boolean => {
  const msg = 'Please attach a resume'
  if (resumeUploaded.value) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const convertToNumber = (v: string) : number => {
  if (v) {
    return parseInt(v.replace(/[^0-9]/g, ''))
  } 
  return 0
}

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isFormValid = () : boolean => {
  return isFirstNameValid(true) && 
    isLastNameValid(true) &&
    isEmailValid(true) &&
    isPhoneNumberValid(true) &&
    isReferralSourceValid(true) &&
    isReferralSourceDetailsValid(true) &&
    isHasCollegeEducationValid(true) &&
    isCollegeValid(true) &&
    isOtherCollegeNameValid(true) &&
    isExpectedGraduationMonthValid(true) &&
    isExpectedGraduationYearValid(true) &&
    isResumeFileValid(true)
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    try {
      if (jobPosting.value) {
        resume.value.phoneNumber = convertToNumber(phoneNumber.value)
        resume.value.jobPostingId = jobPosting.value?.id
        resume.value.middleName = resume.value.middleName ?? ''
        resume.value.preferredName = resume.value.preferredName ?? ''
        resume.value.collegeId = resume.value.collegeId ?? ''
        resume.value.otherCollegeName = resume.value.otherCollegeName ?? ''
        resume.value.referralSourceDetails = resume.value.referralSourceDetails ?? ''
        const r = await api.submitResume(resume.value)
        if (r === 'Success') {
          router.push('/resume-submitted')
        } else {
          showError(r)
        }
      } else {
        showError('Please reload the page and try again')
      }
    } catch (e) {
      showError('Please reload the page and try again')
    } finally {
      loading.value = false
    }
  }
}

const toBase64 = (f: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(f)
    reader.onload = () => {
      const result = reader.result as string
      const base64 = result.split(',')[1]
      if (base64) {
        resolve(base64)
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

const handleResume = async () => {
  if (resumeFiles.value && resumeFiles.value[0]) {
    resumeLoading.value = true
    try {
      const f = resumeFiles.value[0]
      const data = await toBase64(f)

      const file: FileDoc = {
        email: resume.value.email,
        fileName: f.name,
        fileType: 'ResumePDF',
        fileContents: data,
        jobPostingId: jobPosting.value ? jobPosting.value.id : '',
        userId: ''
      }
      const r = await api.uploadFile(file)
      if (r === 'Success') {
        resumeUploaded.value = file
      } else {
        resumeFiles.value = []
        showError(r)
      }
    } catch (e) {
      resumeFiles.value = []
      showError('Error uploading resume')
    } finally {
      resumeLoading.value = false
    }
  } else {
    if (resumeUploaded.value) {
      resumeLoading.value = true
      try {
        const r = await api.deleteFile(resumeUploaded.value)
        if (r === 'Success') {
          resumeUploaded.value = null
        } else {
          showError(r)
        }
      } catch (e) {
        showError('Error deleting resume')
      } finally {
        resumeLoading.value = false
      }
    }
  }
}

const handleCoverLetter = async () => {
  if (coverLetterFiles.value && coverLetterFiles.value[0]) {
    coverLetterLoading.value = true
    try {
      const f = coverLetterFiles.value[0]
      const data = await toBase64(f)

      const file: FileDoc = {
        email: resume.value.email,
        fileName: f.name,
        fileType: 'CoverLetterPDF',
        fileContents: data,
        jobPostingId: jobPosting.value ? jobPosting.value.id : '',
        userId: ''
      }
      const r = await api.uploadFile(file)
      if (r === 'Success') {
        coverLetterUploaded.value = file
      } else {
        coverLetterFiles.value = []
        showError(r)
      }
    } catch (e) {
      coverLetterFiles.value = []
      showError('Error uploading cover letter')
    } finally {
      coverLetterLoading.value = false
    }
  } else {
    if (coverLetterUploaded.value) {
      coverLetterLoading.value = true
      try {
        const r = await api.deleteFile(coverLetterUploaded.value)
        if (r === 'Success') {
          coverLetterUploaded.value = null
        } else {
          showError(r)
        }
      } catch (e) {
        showError('Error deleting cover letter')
      } finally {
        coverLetterLoading.value = false
      }
    }
  }
}
</script>

<style>
.s-title {
  margin-top: 15px;
  margin-bottom: 15px;
}
.submit-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
</style>
