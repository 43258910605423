<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <!-- Title -->
      <v-row>
        <v-col>
          <h3 class="d-flex justify-center"> 
            {{ isEnglish ? 'Create an account to apply' : 'Cree una cuenta para aplicar' }}
          </h3>
        </v-col>
      </v-row>
      <!-- Subtitle -->
      <div class="d-flex justify-center subtitle s-title"> 
        {{ isEnglish ? 
          'Please use First name and Last name as seen on official ID' : 
          'Por favor use su Nombre y Apellido como está escrito en su identificación oficial' }} 
      </div>
      <!-- Alert -->
      <v-row v-if="error">
        <v-col>
          <v-alert          
            border="start"
            type="error"
            variant="tonal"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-row>
        <v-col>
          <v-form
            @submit.prevent="onSubmit()"
          >
            <div>
              <v-text-field
                v-model="firstName"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                :label="isEnglish ? 'First name*' : 'Nombre*'"
                variant="outlined"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="middleName"
                :readonly="loading"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Middle name' : 'Segundo nombre'"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Last name*' : 'Apellido*'"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Email address*' : 'Correo electrónico*'"
                hide-details
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                :rules="required"
                :readonly="loading"
                :type="showPassword ? 'text' : 'password'"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Password*' : 'Contraseña*'"
                hide-details
                required
                @click:append-inner="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                :rules="required"
                :readonly="loading"
                :type="showConfirmPassword ? 'text' : 'password'"
                class="tf-padding"
                variant="outlined"
                :label="isEnglish ? 'Confirm password*' : 'Confirme su contraseña*'"
                hide-details
                required
                @click:append-inner="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </div>
            <div class="d-flex justify-center">
              <v-btn 
                :loading="loading"
                class="btn create-account-btn orange-btn" 
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'Create account' : 'Crear cuenta' }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <!-- Login User - Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle"> 
            {{ isEnglish ? 'Already have an account?' : '¿Ya tiene una cuenta?' }}
            <a class="login-link" :href="'#/login'">
              {{ isEnglish ? 'Log in' : 'Ingrese a su cuenta' }}
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- Help - Link -->
      <v-row>
        <v-col>
          <div class="d-flex justify-center subtitle help-link"> 
            <a :href="'#/help'"> 
              {{ isEnglish ? 'Help' : 'Soporte' }}
            </a>
          </div>
        </v-col>
      </v-row>  
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { NewUser } from '@/types'
import api from '@/api'
import { useRouter, useRoute } from 'vue-router'
import LabattLogo from '@/components/LabattLogo.vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const firstName = ref('')
const middleName = ref('')
const lastName = ref('')
const email = ref('')
const password = ref('')
const showPassword = ref(false)
const confirmPassword = ref('')
const showConfirmPassword = ref(false)
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const route = useRoute()
const { cookies } = useCookies()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {  
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (route.query.j) {
    store.setJobPostingId(route.query.j.toString())
  }  
  if (route.query.h) {
    store.setWebHistory(route.query.h.toString())
  } else {
    store.setWebHistory(document.referrer)
  }
  if (cookies.get('labatt-user-email')) {
    router.push('/dashboard')
  } else {
    store.setCurrentEmail('')
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const onSubmit = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const user: NewUser = {
      firstName: firstName.value,
      middleName: middleName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      confirmPassword: confirmPassword.value,
      language: store.language
    }
    const r = await api.createUser(user)
    if (r === 'Success') {
      cookies.set('labatt-user-email', email.value, '1d')
      store.setCurrentEmail(email.value)
      router.push('/dashboard')
    } else if (r === 'Verify email') {
      cookies.set('labatt-user-email', email.value, '1d')
      store.setCurrentEmail(email.value)
      router.push('/verify')
    } else {
      showError(r)
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
</script>

<style>
.tf-padding {
  padding-bottom: 15px;
}
.s-title {
  margin-top: 10px;
  margin-bottom: 15px;
}
.create-account-btn {
  margin-top: 14px;
  margin-bottom: 15px;
}
.login-link {
  margin-left: 5px;
  font-weight: 600;
}
.help-link {
  margin-bottom: 15px;
  font-weight: 600;
}
</style>
