import { createRouter, createWebHashHistory } from 'vue-router'
import CreateUserView from '@/views/CreateUserView.vue'
import LoginUserView from '@/views/LoginUserView.vue'
import ResetUserPasswordView from '@/views/ResetUserPasswordView.vue'
import VerifyUserView from '@/views/VerifyUserView.vue'
import HelpUserView from '@/views/HelpUserView.vue'
import DashboardView from '@/views/DashboardView.vue'
import ApplicationView from '@/views/ApplicationView.vue'
import PersonalInformationView from '@/views/PersonalInformationView.vue'
import WorkEligibilityView from '@/views/WorkEligibilityView.vue'
import EducationAndMilitaryView from '@/views/EducationAndMilitaryView.vue'
import PreviousEmploymentView from '@/views/PreviousEmploymentView.vue'
import WorkExperienceView from '@/views/WorkExperienceView.vue'
import BackgroundQuestionsView from '@/views/BackgroundQuestionsView.vue'
import DriverInformationView from '@/views/DriverInformationView.vue'
import EEOAndAAView from '@/views/EEOAndAAView.vue'
import ApplicationReviewView from '@/views/ApplicationReviewView.vue'
import AdditionalFormsView from '@/views/AdditionalFormsView.vue'
import ApplicationSubmittedView from '@/views/ApplicationSubmittedView.vue'
import QuickApplyView from '@/views/QuickApplyView.vue'
import QuickApplySubmittedView from '@/views/QuickApplySubmittedView.vue'
import ResumeView from '@/views/ResumeView.vue'
import ResumeSubmittedView from '@/views/ResumeSubmittedView.vue'
import ActivitiesView from '@/views/ActivitiesView.vue'
import AccountView from '@/views/AccountView.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    { 
      path: '/:pathMatch(.*)*', 
      redirect: '/' 
    },
    {
      path: '/',
      redirect: '/create'
    },
    {
      path: '/create',
      name: 'create',
      component: CreateUserView,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginUserView,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetUserPasswordView,
    },
    {
      path: '/verify',
      name: 'verify',
      component: VerifyUserView,
    },
    {
      path: '/help',
      name: 'help',
      component: HelpUserView,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
    },
    {
      path: '/application',
      name: 'application',
      component: ApplicationView,
      children: [
        {
          path: 'personal-information',
          name: 'personal-information',
          component: PersonalInformationView,
        },
        {
          path: 'work-eligibility',
          name: 'work-eligibility',
          component: WorkEligibilityView,
        },
        {
          path: 'education-military',
          name: 'education-military',
          component: EducationAndMilitaryView,
        },
        {
          path: 'previous-employment',
          name: 'previous-employment',
          component: PreviousEmploymentView,
        },
        {
          path: 'work-experience',
          name: 'work-experience',
          component: WorkExperienceView,
        },
        {
          path: 'background-questions',
          name: 'background-questions',
          component: BackgroundQuestionsView,
        },
        {
          path: 'driver-information',
          name: 'driver-information',
          component: DriverInformationView,
        },
        {
          path: 'eeo-aa',
          name: 'eeo-aa',
          component: EEOAndAAView,
        },
        {
          path: 'review',
          name: 'review',
          component: ApplicationReviewView,
        },
        {
          path: 'additional-forms',
          name: 'additional-forms',
          component: AdditionalFormsView,
        },
        {
          path: 'submitted',
          name: 'submitted',
          component: ApplicationSubmittedView,
        },
      ]
    },
    {
      path: '/quick-apply',
      name: 'quick-apply',
      component: QuickApplyView,
    },
    {
      path: '/quick-apply-submitted',
      name: 'quick-apply-submitted',
      component: QuickApplySubmittedView,
    },
    {
      path: '/resume',
      name: 'resume',
      component: ResumeView,
    },
    {
      path: '/resume-submitted',
      name: 'resume-submitted',
      component: ResumeSubmittedView,
    },
    {
      path: '/activities',
      name: 'activities',
      component: ActivitiesView,
    },
    {
      path: '/account',
      name: 'account',
      component: AccountView,
    },
  ],
})

export default router
