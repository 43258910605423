<template>
  <div class="d-flex align-center">
    <v-progress-linear
      bg-color="#92aed9"
      color="#f37027"
      height="18"
      :max="max"
      :min="0"
      :model-value="current"
      rounded
      striped
    ></v-progress-linear>
    <div class="ml-10 title-bold"> {{ current }}/{{ max }} </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from '@/stores/store'

const current = ref(0)
const max = ref(0)
const store = useStore()

const props = defineProps({
  phase: {
    type: Number,
    required: true,
  },
  section: {
    type: Number,
    required: true,
  },
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const reload = async () => {
  current.value = props.section
  if (props.phase === 1) {
    if (isTransportation.value) {
      max.value = 8
    } else {
      max.value = 7
    }
  } else if (props.phase === 2) {
    if (isOffice.value) {
      max.value = 9
    } else {
      max.value = 10
    }
  } else {
    if (isTransportation.value) {
      max.value = 11
    } else if (isOffice.value) {
      max.value = 9
    } else {
      max.value = 10
    }
  }
}
onMounted(reload)

</script>

<style>

</style>
