<template>
  <div class="application">
    <!-- Logout -->
    <v-container class="container-view logout-view elevation-2">
      <LogoutComponent :view="isEnglish ? 'Application' : 'Aplicación'" />   
    </v-container>
    <!-- View -->
    <v-container class="container-view application-inner elevation-2">
      <v-row>
        <v-col>
          <RouterView />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { onMounted , computed } from 'vue'
import { useCookies } from 'vue3-cookies'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import LogoutComponent from '@/components/LogoutComponent.vue'

const { cookies } = useCookies()
const router = useRouter()
const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})

const reload = async () => {
  if (cookies.get('labatt-user-email')) {
    // load normally
  } else {
    router.push('/login')
  }
}
onMounted(reload)
</script>

<style>
.application {
  background-color: #f3f2f2;
}
.application-inner {
  background-color: white;
  min-height: 81.5dvh;
}
.logout-view {
  background-color: white;
}
@media screen and (min-width: 767px) {
  .application-inner {
    min-height: 85dvh;
  }
}
@media screen and (min-width: 1025px) {
  .application-inner {
    min-height: 81.5dvh;
  }
}
</style>
