<template>
  <div class="view">
    <v-container class="container-view view-inner elevation-2">
      <!-- Image -->
      <v-row>
        <v-col>
          <LabattLogo />
        </v-col>
      </v-row>
      <br><br>
      <!-- Title -->
      <v-row>
        <v-col>
          <div class="title-bold"> {{ isEnglish ? 'Information Submitted' : 'Información Enviada' }} </div>
        </v-col>
      </v-row>
      <!-- Message -->
      <v-row>
        <v-col>
          <div> {{ isEnglish ? 'Thank you for submitting your information.' : 'Gracias por enviar su información.' }} </div>
          <div> {{ isEnglish ? 
                  'A member of our hiring team will be contacting you shortly to schedule the next steps.' : 
                  'Un miembro de nuestro equipo se comunicará pronto con usted para programar los próximos pasos.' }} 
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useStore } from '@/stores/store'
import LabattLogo from '@/components/LabattLogo.vue'

const store = useStore()

const isEnglish = computed(() => {
  return store.language === 'english'
})
</script>

<style>

</style>
