<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="section" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> 
          {{ isEnglish ? 
             'Equal Employment Opportunity & Affirmative Action Statement' : 
             'Declaración de Igualdad de Oportunidades de Empleo y Acción Afirmativa' }} 
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <!-- Message -->
          <div class="font-sm mb-6"> 
            {{ isEnglish ? 
               'Select each document to review. Do not submit until you have reviewed and/or signed all documents.' : 
               'Seleccione cada documento para revisar. No continúe hasta que haya revisado y/o firmado todos los documentos.' }}
          </div>
          <!-- Panels -->
          <v-expansion-panels 
            v-model="panel"
            multiple
          >
            <!-- EEO/AA-->
            <v-expansion-panel>
              <v-expansion-panel-title> 
                <v-icon
                  v-if="isEEOFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  title="Signed"
                ></v-icon>
                {{ isEnglish ? 
                   'Equal Employment Opportunity/Affirmative Action Statement' : 
                   'Declaración de Igualdad de Oportunidades de Empleo / Acción Afirmativa' }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                <span v-if="isEnglish">
                  Labatt Food Service is an Equal Opportunity Employer. Labatt Food Service takes affirmative 
                  action in employing and advancing in employment qualified individuals without regard to race, color, 
                  religion, sex, national origin, age, disability, veteran status, and other protected factors.
                </span>
                <span v-else>
                  Labatt Food Service es una Empresa con Igualdad de Oportunidades. Labatt Food Service toma acción 
                  afirmativa al emplear y promover a individuos calificados para el empleo sin tomar en consideración 
                  raza, color, religión, sexo, origen nacional, edad, discapacidades, estatus de veterano, y otros 
                  factores protegidos.
                </span>
                <div 
                  v-if="!readMoreEEO"
                  class="d-flex justify-center mt-3 mb-4 read-more-toggle"
                  @click="readMoreEEO = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreEEO">
                  <br>
                  <span v-if="isEnglish">
                    To assist in this effort, all applicants are asked to complete this form. Your cooperation
                    is strictly voluntary. This form and this information it contains will be kept in a confidential file
                    separate from the employment applications. This form will be used only for reporting purposes in
                    accordance with Federal laws and regulations.
                  </span>
                  <span v-else>
                    Para ayudar en este esfuerzo, se les pide a todos los solicitantes que llenen este formulario. 
                    Su cooperación es estrictamente voluntaria. Este formulario y la información que contiene serán 
                    archivados en un expediente confidencial separado de la solicitud de empleo. Este formulario será 
                    utilizado solamente con el propósito de reportar información de acuerdo con las leyes y regulaciones 
                    Federales.
                  </span>
                </div> 
                <div 
                  v-if="readMoreEEO"      
                  class="d-flex justify-center read-more-toggle"            
                  @click="readMoreEEO = false"
                > 
                  <v-icon
                    class="ml-1 mt-3 mb-3"
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>  
                {{ isEnglish ? 'Date:' : 'Fecha:' }} {{ formattedDate }} <br>
                {{ isEnglish ? 'Name:' : 'Nombre:' }} {{ user.firstName + ' ' + user.lastName }} <br>
                {{ isEnglish ? 'Position applied for:' : 'La posición solicitada:' }} {{ jobTitle }} <br>                              
                <!-- Ethnicity -->
                <v-select
                  v-model="app.ethnicityCode"
                  :rules="required"
                  :readonly="(loading || isEEOFormSigned) && !store.isApplicationReview"
                  :items="ethnicities"
                  :item-title="isEnglish ? 'description' : 'spanishDescription'"
                  item-value="code"
                  :label="isEnglish ? 'Race/national origin' : 'Raza/nacionalidad de origen'"
                  class="tf-padding mt-5"
                  variant="outlined"
                  hide-details
                  @update:modelValue="updateEEOAA()"
                ></v-select>
                <!-- Ethnicity Details -->
                <v-text-field
                  v-if="app.ethnicityCode === 'OT'"
                  v-model="app.ethnicityDetails"
                  :rules="required"
                  :readonly="(loading || isEEOFormSigned) && !store.isApplicationReview"
                  class="tf-padding"
                  :label="isEnglish ? 'Please describe' : 'Por favor describa'"
                  variant="outlined"
                  hide-details
                  @update:modelValue="updateEEOAA()"
                ></v-text-field>
                <!-- Gender -->
                <v-select
                  v-model="app.gender"
                  :rules="required"
                  :readonly="(loading || isEEOFormSigned) && !store.isApplicationReview"
                  :items="[
                    { id: 'Female', description: isEnglish ? 'Female' : 'Mujer' }, 
                    { id: 'Male', description: isEnglish ? 'Male' : 'Hombre' }
                  ]"
                  item-title="description"
                  item-value="id"
                  :label="isEnglish ? 'Sex' : 'Sexo'"
                  class="tf-padding mt-5"
                  variant="outlined"
                  hide-details
                  @update:modelValue="updateEEOAA()"
                ></v-select>  
                <!-- EEOAA Omitted-->
                <div class="d-flex">
                  <v-checkbox 
                    v-model="app.isEEOAAOmitted"
                    :readonly="(loading || isEEOFormSigned) && !store.isApplicationReview"
                    :ripple="!isEEOFormSigned"
                    class="radio-btn mr-2"
                    hide-details
                    @update:modelValue="updateEEOAAOmitted()"
                    :label="isEnglish ? 'I do not wish to provide the information above' : 'No deseo proveer la información anteriormente mencionada'"
                  ></v-checkbox>
                </div>                
                <br>
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="isEEOFormSigned"
                    class="btn orange-btn" 
                    elevation="0"
                    @click="signEEOForm()"
                    type="submit"
                  >
                    <span v-if="isEnglish">
                      {{ isEEOFormSigned ? 'Signed' : 'Sign' }}
                    </span>
                    <span v-else>
                      {{ isEEOFormSigned ? 'Firmado' : 'Firmar' }}
                    </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- Disability -->
            <v-expansion-panel>
              <v-expansion-panel-title> 
                <v-icon
                  v-if="isDisabilityFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  title="Signed"
                ></v-icon>
                {{ isEnglish ? 'Voluntary Self-Identification of Disability' : 'Auto-Identificación Voluntaria de Discapacidad' }} 
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                {{ isEnglish ? 'Form CC-305' : 'Formulario CC-305' }} <br>
                {{ isEnglish ? 'OMB Control Number 1250-0005' : 'Número de Control OMB 1250-0005' }} <br>
                {{ isEnglish ? 'Expires 04/30/2026' : 'Expira el 04/30/2026' }} <br>
                {{ isEnglish ? 'Page 1 of 1' : 'Página 1 de 1' }} <br>
                <br>
                {{ isEnglish ? 'Date:' : 'Fecha:' }} {{ formattedDate }} <br>
                <br>
                <!-- Signature -->
                <div class="d-flex justify-center"> 
                  {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                </div>
                <v-text-field
                  v-model="app.disabledSignature"
                  :rules="required"
                  :readonly="(loading || isDisabilityFormSigned)"
                  class="tf-padding input-signature"
                  variant="outlined"
                  hide-details
                ></v-text-field> 
                <div class="d-flex justify-center  mb-5">
                  {{ isEnglish ? 'Signature' : 'Firma' }}
                </div>  
                {{ isEnglish ? 
                    'Why are you being asked to complete this form?' : 
                    '¿Por qué se le pide que rellene este formulario?' }} 
                <br>
                <div 
                  v-if="!readMoreDisability"
                  class="d-flex justify-center mt-3 mb-3 read-more-toggle"
                  @click="readMoreDisability = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreDisability">
                  <br>
                  <span v-if="isEnglish">
                    We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified 
                    people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we 
                    must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability 
                    or have ever had one. People can become disabled, so we need to ask this question at least every five years. <br>
                  </span>
                  <span v-else>
                    Somos un contratista o subcontratista federal. La ley nos obliga a ofrecer 
                    igualdad de oportunidades de empleo a las personas con discapacidad cualificadas. Nuestro objetivo
                    es que al menos el 7% de nuestros trabajadores sean personas con discapacidad. La ley 
                    dice que debemos medir nuestro progreso hacia este objetivo. Para ello, debemos preguntar a 
                    los solicitantes y a los empleados si tienen o han tenido alguna discapacidad. Las personas pueden 
                    convertirse en discapacitadas, por lo que debemos hacer esta pregunta al menos cada cinco años. <br>
                  </span>                   
                  <br>
                  <span v-if="isEnglish">
                    Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who 
                    makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you
                    want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance 
                    Programs (OFCCP) website at <u>www.dol.gov/ofccp</u>. <br>
                  </span>
                  <span v-else>
                    Rellenar este formulario es voluntario y esperamos que decida hacerlo. 
                    Su respuesta es confidencial. Nadie que tome decisiones de contratación la verá. Su decisión de rellenar 
                    el formulario y su respuesta no le perjudicarán en modo alguno. Si desea obtener más información sobre 
                    la ley o este formulario, visite el sitio web de la Oficina de Programas de Cumplimiento de Contratos 
                    Federales (OFCCP) del Departamento de Trabajo de EE.UU. en <u>www.dol.gov/ofccp</u>. <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    How do you know if you have a disability? <br>
                  </span>
                  <span v-else>
                    ¿Cómo saber si tiene una discapacidad? <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    A disability is a condition that substantially limits one or more of your "major life activities." If you have or have ever had
                    such a condition, you are a person with a disability. <i> Disabilities include, but are not limited to:</i> <br>
                  </span>
                  <span v-else>
                    Una discapacidad es una condición que limita 
                    sustancialmente una o más de sus "actividades vitales principales". Si tiene o ha tenido 
                    alguna vez una afección de este tipo, usted es una persona con discapacidad. <i>Las discapacidades 
                    incluyen, pero no se limitan a:</i> <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    • Alcohol or other substance use disorder (not currently using drugs illegally) <br>
                    • Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, HIV/AIDS <br>
                    • Blind or low vision <br>
                    • Cancer (past or present) <br>
                    • Cardiovascular or heart disease <br>
                    • Celiac disease <br>
                    • Cerebral palsy <br>
                    • Deaf or serious difficulty hearing <br>
                    • Diabetes <br>
                    • Disfigurement, for example, disfigurement caused by burns, wounds, accidents, or congenital disorders <br>
                    • Epilepsy or other seizure disorder <br>
                    • Gastrointestinal disorders, for example, Crohn's Disease, irritable bowel syndrome <br>
                    • Intellectual or developmental disability <br>
                    • Mental health conditions, for example, depression, bipolar disorder, anxiety disorder, schizophrenia, PTSD <br>
                    • Missing limbs or partially missing limbs <br>
                    • Mobility impairment, benefiting from the use of a wheelchair, scooter, walker, leg brace(s) and/or other supports <br>
                    • Nervous system condition, for example, migraine headaches, Parkinson’s disease, multiple sclerosis (MS) <br>
                    • Neurodivergence, for example, attention-deficit/hyperactivity disorder (ADHD), autism spectrum disorder, dyslexia, dyspraxia, other learning disabilities <br>
                    • Partial or complete paralysis (any cause) <br>
                    • Pulmonary or respiratory conditions, for example, tuberculosis, asthma, emphysema <br>
                    • Short stature (dwarfism) <br>
                    • Traumatic brain injury <br>
                  </span> 
                  <span v-else>
                    • Trastorno por consumo de alcohol u otras sustancias (sin consumir drogas ilegalmente en la actualidad) <br>
                    • Trastorno autoinmune, por ejemplo, lupus, fibromialgia, artritis reumatoide, VIH/sida <br>
                    • Ceguera o problemas de visión <br>
                    • Cáncer (pasado o presente) <br>
                    • Enfermedad cardiovascular o del corazón <br>
                    • Enfermedad celíaca <br>
                    • Parálisis cerebral <br>
                    • Sordera o dificultades auditivas graves <br>
                    • Diabetes <br>
                    • Desfiguración, por ejemplo, desfiguración causada por quemaduras, heridas, accidentes o trastornos congénitos <br>
                    • Epilepsia u otro trastorno convulsivo <br>
                    • Trastornos gastrointestinales, por ejemplo, enfermedad de Crohn, síndrome del intestino irritable <br>
                    • Discapacidad intelectual o del desarrollo <br>
                    • Enfermedades mentales, por ejemplo, depresión, trastorno bipolar, trastorno de ansiedad, esquizofrenia o trastorno de estrés postraumático <br>
                    • Ausencia total o parcial de miembros <br>
                    • Problemas de movilidad que requieran el uso de una silla de ruedas, un escúter, un andador, una pierna ortopédica u otros dispositivos de apoyo <br>
                    • Afección del sistema nervioso, por ejemplo, migrañas, enfermedad de Parkinson, esclerosis múltiple (EM) <br>
                    • Neurodivergencia, por ejemplo, trastorno por déficit de atención con hiperactividad (TDAH), trastorno del espectro autista, dislexia, dispraxia u otras dificultades de aprendizaje <br>
                    • Parálisis parcial o total (por cualquier causa) <br>
                    • Afecciones pulmonares o respiratorias, por ejemplo, tuberculosis, asma, enfisema <br>
                    • Baja estatura (enanismo) <br>
                    • Lesión cerebral traumática <br>
                  </span> 
                </div>
                <div 
                  v-if="readMoreDisability"      
                  class="d-flex justify-center mt-3 mb-3 read-more-toggle"            
                  @click="readMoreDisability = false"
                > 
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                  <br>
                </div> 
                <!-- Disability Question -->
                <div class="mb-2"> 
                  {{ isEnglish ? 
                     'Please check one of the boxes below:' : 
                     'Marque una de las casillas siguientes:' }} 
                </div>
                <v-radio-group
                  class="radio-btn"
                  v-model="app.isDisabled"
                  :rules="required"
                  :readonly="(loading || isDisabilityFormSigned) && !store.isApplicationReview"
                  :ripple="!isDisabilityFormSigned"
                  hide-details
                  @update:modelValue="updateDisabled()"
                >
                  <v-radio 
                    :label="isEnglish ? 'Yes, I have a disability, or have had one in the past' : 'Sí, tengo una discapacidad o la he tenido en el pasado'" 
                    :value="true"
                  ></v-radio>
                  <v-radio 
                    :label="isEnglish ? 'No, I do not have a disability and have not had one in the past' : 'No, no tengo ninguna discapacidad ni la he tenido en el pasado'" 
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <!-- Disabled Omitted-->
                <div class="d-flex">
                  <v-checkbox 
                    v-model="app.isDisabledOmitted"
                    :readonly="(loading || isDisabilityFormSigned) && !store.isApplicationReview"
                    :ripple="!isDisabilityFormSigned"
                    class="radio-btn mr-2"
                    hide-details
                    @update:modelValue="updateDisabledOmitted()"
                    :label="isEnglish ? 'I do not want to answer' : 'No quiero contestar'"
                  ></v-checkbox>
                </div> 
                <br>
                <div v-if="readMoreDisability">
                  <span v-if="isEnglish">
                    PUBLIC BURDEN STATEMENT: According to the Paperwork Reduction Act of 1995 no persons are required to respond
                    to a collection of information unless such collection displays a valid OMB control number. This survey should take about 5 
                    minutes to complete. <br>
                  </span>
                  <span v-else>
                    DECLARACIÓN PÚBLICA DE CARGA: De acuerdo con la Ley de Reducción de Trámites de 1995, 
                    ninguna persona está obligada a responder a una recopilación de información a menos que dicha recopilación 
                    muestre un número de control OMB válido. Esta encuesta debería completarse en unos 5 minutos. <br>
                  </span> 
                  <br>
                </div>            
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="isDisabilityFormSigned"
                    class="btn orange-btn" 
                    elevation="0"
                    @click="signDisabilityForm()"
                    type="submit"
                  >
                    <span v-if="isEnglish">
                      {{ isDisabilityFormSigned ? 'Signed' : 'Sign' }}
                    </span>
                    <span v-else>
                      {{ isDisabilityFormSigned ? 'Firmado' : 'Firmar' }}
                    </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- Veterans -->
            <v-expansion-panel>
              <v-expansion-panel-title> 
                <v-icon
                  v-if="isVeteranFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  title="Signed"
                ></v-icon>
                {{ isEnglish ? 'Voluntary Invitation to Self-Identify for Veterans' : 'Invitación para Autoidentificarse Voluntariamente para Veteranos' }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                {{ isEnglish ? 'Date:' : 'Fecha:' }} {{ formattedDate }} <br>
                {{ isEnglish ? 'Name:' : 'Nombre:' }} {{ user.firstName + ' ' + user.lastName }} <br>
                {{ isEnglish ? 'Position sought:' : 'Posición deseada:' }} {{ jobTitle }} <br><br>
                <!-- Signature -->
                <div class="d-flex justify-center"> 
                  {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                </div>
                <v-text-field
                  v-model="app.veteranSignature"
                  :rules="required"
                  :readonly="(loading || isVeteranFormSigned)"
                  class="tf-padding input-signature"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <div class="d-flex justify-center  mb-5">
                  {{ isEnglish ? 'Signature' : 'Firma' }}
                </div>  
                <span v-if="isEnglish">
                  Labatt Food Service is a Government contractor or 
                  subcontractor subject to the Vietnam Era Veterans' Readjustment Assistance Act of 1974, as 
                  amended by the Jobs for Veterans Act of 2002, 38 U.S.C. 4212 (VEVRAA), which requires Government 
                  contractors to affirmatively employ and advance qualified veterans. <br>
                </span>
                <span v-else>
                  Labatt Food Service es un contratista o subcontratista del Gobierno sujeto a la a Ley de Asistencia 
                  para el Reajuste de los Veteranos de la Era de Vietnam de 1974, según las reformas por la Ley de 
                  Empleos para Veteranos de 2002, 38 U.S.C. 4212 (VEVRAA por sus siglas en inglés), la cual requiere que 
                  los contratistas del Gobierno tomen medidas afirmativas para emplear y promover a veteranos protegidos.
                  <br>
                </span>
                <br>
                <span v-if="isEnglish">
                  If you believe you belong to any of the categories of protected veterans 
                  listed below in the definition section, please indicate by checking the appropriate box below. 
                  Submission of this information is voluntary and refusal to provide it will not subject you to any 
                  adverse treatment. This information will assist Labatt with its recordkeeping obligations and in 
                  measuring the effectiveness of its outreach and positive recruitment efforts we undertake 
                  pursuant to VEVRAA. <br>
                </span>
                <span v-else>
                  Si cree que pertenece a alguna de las categorías de veteranos protegidos que se enumeran a 
                  continuación en la sección de definiciones, por favor indíquelo marcando la casilla correspondiente. 
                  Esta información se proporciona de manera voluntaria y el negarse a proveer dicha información no le 
                  someterá a ningún trato desfavorable. Esta información ayudará a Labatt con su obligación de mantener
                   registros y a medir la efectividad de los esfuerzos de divulgación y reclutamiento llevados a cabo de 
                   acuerdo con VEVRAA. <br>
                </span>
                <div v-if="readMoreVeteran">
                  <br>
                  {{ isEnglish ? 'Definitions:' : 'Definiciones:' }} <br>
                  <br>
                  <span v-if="isEnglish">
                    • A "<u>disabled veteran</u>" is one of the following: <br>
                  </span>
                  <span v-else>
                    • Un "<u>veterano discapacitado</u>" es uno de los siguientes: <br>
                  </span>
                  <div class="ml-8">
                    <span v-if="isEnglish">
                      • a veteran of the U.S. military, ground, naval or air service who is entitled to compensation 
                      (or who but for the receipt of military retired pay would be entitled to compensation) 
                      under laws administered by the Secretary of Veterans Affairs; or
                    </span>
                    <span v-else>
                      • un veterano del servicio militar, terrestre, naval o aéreo de los Estados Unidos con derecho 
                      a indemnización (o que, de no haber recibido pago de jubilación, tendría derecho a indemnización) 
                      según las leyes administradas por la Secretaría de Asuntos de Veteranos; o
                    </span>
                  </div>
                  <div class="ml-8"> 
                    <span v-if="isEnglish">
                      • a person who was discharged or released from active duty because of a service-connected disability.
                    </span>
                    <span v-else>
                      • una persona que fue dada de alta o liberada del servicio activo debido a una discapacidad relacionada 
                      con el servicio.
                    </span>
                  </div>
                  <br>
                  <span v-if="isEnglish">
                    • A "<u>recently separated veteran</u>" means
                    any veteran during the three-year period beginning on the date of such veteran's discharge or
                    release from active duty in the U.S. military, ground, naval, or air service. <br>
                  </span>
                  <span v-else>
                    • Un "<u>veterano recientemente separado</u>" 
                    se refiere a cualquier veterano durante el período de tres años a partir de la fecha del alta o 
                    liberación del servicio activo en el servicio militar, terrestre, naval o aéreo de los Estados Unidos.
                    <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    • An "<u>active duty wartime or campaign badge veteran</u>" means
                    "a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, 
                    or in a campaign or expedition for which a campaign badge has been authorized under the laws 
                    administered by the Department of Defense. <br>
                  </span>
                  <span v-else>
                    • Un "<u>veterano en servicio activo en tiempo de guerra o insignia de campaña</u>" 
                    se refiere a un veterano que prestó servicio activo en el servicio militar, terrestre, naval o aéreo 
                    de los Estados Unidos durante una guerra, o en una campaña o expedición para la cual se autorizó una 
                    insignia de campaña conforme a las leyes administradas por el Departamento de Defensa. <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    • An "<u>Armed forces service medal veteran</u>" means a veteran who, 
                    while serving on active duty in the U.S. military, ground, naval or air service, participated in 
                    a United States military operation for which an Armed Forces service medal was awarded pursuant 
                    to Executive Order 12985. <br>
                  </span>
                  <span v-else>
                    • Un "<u>veterano de las Fuerzas Armadas con medallas por servicio</u>"
                    se refiere a un veterano que, mientras prestaba servicio activo en el servicio del ejército militar, 
                    terrestre, naval o aéreo de los Estados Unidos, participó en una operación militar de los Estados 
                    Unidos por la cual se le otorgó una medalla de servicio de las Fuerzas Armadas de acuerdo con la 
                    Orden Ejecutiva 12985. <br>
                  </span>
                  <br>
                  <span v-if="isEnglish">
                    Labatt affirmatively seeks to employ, and advance in employment,
                    veterans through targeted outreach and recruiting efforts, promotion and training.
                    Labatt also complies with its data collection, analysis, and recordkeeping obligations 
                    on veterans. Affirmative action program (Section B). <br>
                  </span>
                  <span v-else>
                    Labatt afirmativamente procura contratar y promover a veteranos a través de campañas de divulgación 
                    y esfuerzos de reclutamiento, promoción y capacitación. Labatt también cumple con sus obligaciones 
                    de recopilación de datos, análisis y control de registros sobre veteranos. Programa de Discriminación 
                    Positiva (Sección B). <br>
                  </span>
                </div>
                <div 
                  v-if="!readMoreVeteran"
                  class="d-flex justify-center mt-3 mb-4 read-more-toggle"
                  @click="readMoreVeteran = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>                
                <div 
                  v-if="readMoreVeteran"      
                  class="d-flex justify-center mt-3 mb-3 read-more-toggle"            
                  @click="readMoreVeteran = false"
                > 
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                  <br>
                </div> 
                <!-- Veteran Question -->
                <div class="mb-4"> 
                  {{ isEnglish ? 
                     'Please check one of the boxes below:' : 
                     'Marque una de las casillas siguientes:' }} 
                </div>
                <v-radio-group
                  class="radio-btn"
                  v-model="app.isVeteran"
                  :rules="required"
                  :readonly="(loading || isVeteranFormSigned) && !store.isApplicationReview"
                  :ripple="!isVeteranFormSigned"
                  hide-details
                  @update:modelValue="updateVeteran()"
                >
                  <v-radio :label="isEnglish ? 'I identify as one or more of the classifications of protected veteran listed above' : 'Me identifico con una o más de las clasificaciones de veterano protegido'" :value="true"></v-radio>
                  <v-radio :label="isEnglish ? 'I am not a protected veteran' : 'No soy un veterano protegido'" :value="false"></v-radio>
                </v-radio-group>
                <!-- Veteran Omitted-->
                <div class="d-flex">
                  <v-checkbox 
                    v-model="app.isVeteranOmitted"
                    :readonly="(loading || isVeteranFormSigned) && !store.isApplicationReview"
                    :ripple="!isVeteranFormSigned"
                    class="radio-btn mr-2"
                    hide-details
                    @update:modelValue="updateVeteranOmitted()"
                    :label="isEnglish ? 'I do not wish to answer' : 'No deseo contestar'"
                  ></v-checkbox> 
                </div>                
                <br>
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="isVeteranFormSigned"
                    class="btn orange-btn" 
                    elevation="0"
                    @click="signVeteranForm()"
                    type="submit"
                  >                    
                    <span v-if="isEnglish">
                      {{ isVeteranFormSigned ? 'Signed' : 'Sign' }}
                    </span>
                    <span v-else>
                      {{ isVeteranFormSigned ? 'Firmado' : 'Firmar' }}
                    </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>    
          <br><br><br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent> 
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import type { Ethnicity, CurrentUser, ApplicationEEOAA, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const app = ref({} as ApplicationEEOAA)
const ethnicities = ref([] as Ethnicity[])
const user = ref({} as CurrentUser)
const signedDate = ref('' as string | null)
const panel = ref([])
const readMoreEEO = ref(false)
const readMoreDisability = ref(false)
const readMoreVeteran = ref(false)
const isEEOFormSigned = ref(false)
const isDisabilityFormSigned = ref(false)
const isVeteranFormSigned = ref(false)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const jobTitle = computed(() => {
  return store.selectedJobPosting?.title
})

const section = computed(() => {
  if (isOffice.value) {
    return 6
  }
  if (phase.value === 0 && isTransportation.value) {
    return 8
  }
  return 7
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const formattedDate = computed(() => {
  var d = signedDate.value
  return  d?.substring(5, 7) + '/' + d?.substring(8, 10) + '/' + d?.substring(0, 4)
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r = await api.getEthnicities()
    if (r) {
      ethnicities.value = r
      if (isEnglish.value) {
        ethnicities.value.sort((a, b) => a.description.localeCompare(b.description))
      } else {
        ethnicities.value.sort((a, b) => a.spanishDescription.localeCompare(b.spanishDescription))
      }
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    const r2 = await api.getCurrentUser(email.value)
    if (r2) {
      user.value = r2
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    if (store.applicationId) {
      const r3 = await api.getApplicationEEOAA(store.applicationId)
      if (r3) {
        app.value = r3
        signedDate.value = app.value.signedDate
        isEEOFormSigned.value = true
        isDisabilityFormSigned.value = true
        isVeteranFormSigned.value = true
      } else {
        signedDate.value = new Date().toISOString().substring(0, 10)
        app.value.id = store.applicationId
        app.value.isEEOAAOmitted = null
        app.value.ethnicityCode = null
        app.value.ethnicityDetails = null
        app.value.gender = null
        app.value.isDisabledOmitted = null
        app.value.isDisabled = null
        app.value.disabledSignature = null
        app.value.isVeteranOmitted = null
        app.value.isVeteran = null
        app.value.veteranSignature = null
        app.value.signedDate = signedDate.value        
      } 
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isEthnicityCodeValid = (alert: boolean) : boolean => {
  if (app.value.isEEOAAOmitted || app.value.ethnicityCode) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please select your race/national origin' : 'Por favor seleccione su raza/nacionalidad de origen')
    }
    return false
  }
}

const isEthnicityDetailsValid = (alert: boolean) : boolean => {
  if (app.value.isEEOAAOmitted || app.value.ethnicityCode !== 'OT' || app.value.ethnicityDetails) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please describe your race/national origin' : 'Por favor describa su raza/nacionalidad de origen')
    }
    return false
  }
}

const isGenderValid = (alert: boolean) : boolean => {
  if (app.value.isEEOAAOmitted || app.value.gender) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please select your sex' : 'Por favor seleccione su sexo')
    }
    return false
  }
}

const isDisabledSignatureValid = (alert: boolean) : boolean => {
  if (app.value.disabledSignature) {
    return true
  } else {    
    if (alert) {
      showError(isEnglish.value ? 'Please enter your name' : 'Por favor ingrese su nombre')
    }
    return false
  }
}

const isDisabledValid = (alert: boolean) : boolean => {
  if (app.value.isDisabledOmitted || app.value.isDisabled === true || app.value.isDisabled === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer disability question' : 'Por favor responda la pregunta sobre discapacidad')
    }
    return false
  }
}

const isVeteranSignatureValid = (alert: boolean) : boolean => {
  if (app.value.veteranSignature) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please enter your name in veteran form' : 'Por favor ingrese su nombre en el formulario de veterano')
    }
    return false
  }
}

const isVeteranValid = (alert: boolean) : boolean => {
  if (app.value.isVeteranOmitted || app.value.isVeteran === true || app.value.isVeteran === false) {
    return true
  } else {
    if (alert) {
      showError(isEnglish.value ? 'Please answer veteran question' : 'Por favor responda la pregunta sobre veterano')
    }
    return false
  }
}

const resetPanel = () => {
  panel.value = []
  readMoreEEO.value = false
  readMoreDisability.value = false
  readMoreVeteran.value = false
}

const signEEOForm = () => {
  error.value = false
  errorMessage.value = ''
  if (isEthnicityCodeValid(true) && isEthnicityDetailsValid(true) && isGenderValid(true)) {
    isEEOFormSigned.value = true
    resetPanel()
  }  
}

const signDisabilityForm = () => {
  error.value = false
  errorMessage.value = ''
  if (isDisabledSignatureValid(true) && isDisabledValid(true)) {
    isDisabilityFormSigned.value = true
    resetPanel()
  }
}

const signVeteranForm = () => {
  error.value = false
  errorMessage.value = ''
  if (isVeteranSignatureValid(true) && isVeteranValid(true)) {
    isVeteranFormSigned.value = true
    resetPanel()
  }
}

const areAllFormsSigned = () => {
  if (!isEEOFormSigned.value) {
    showError(isEnglish.value ? 
      'Please sign the form Equal Employment Opportunity/Affirmative Action Statement' : 
      'Por favor firme el formulario de La Igualdad de Oportunidades en el Empleo y Declaración de Acción Afirmativa')
    return false
  }
  if (!isDisabilityFormSigned.value) {
    showError(isEnglish.value ? 
      'Please sign the form Voluntary Self-Identification of Disability' : 
      'Por favor firme el formulario de la Auto-Identificación Voluntaria de Discapacidad')
    return false
  }
  if (!isVeteranFormSigned.value) {
    showError(isEnglish.value ? 
      'Please sign the form Voluntary Invitation to Self-Identify for Veterans' : 
      'Por favor firme el formulario de la Invitación para Identificarse Voluntariamente como Veteran')
    return false
  }
  return true
}

const updateEEOAA = () => {
  if (app.value.ethnicityCode || app.value.ethnicityDetails || app.value.gender) {
    app.value.isEEOAAOmitted = null
  }
}

const updateEEOAAOmitted = () => {
  if (app.value.isEEOAAOmitted) {
    app.value.ethnicityCode = null
    app.value.ethnicityDetails = null
    app.value.gender = null
  }
}

const updateDisabled = () => {
  if (app.value.isDisabled === true || app.value.isDisabled === false) {
    app.value.isDisabledOmitted = null
  }
}

const updateDisabledOmitted = () => {
  if (app.value.isDisabledOmitted) {
    app.value.isDisabled = null
  }
}

const updateVeteran = () => {
  if (app.value.isVeteran === true || app.value.isVeteran === false) {
    app.value.isVeteranOmitted = null
  }
}

const updateVeteranOmitted = () => {
  if (app.value.isVeteranOmitted) {
    app.value.isVeteran = null
  }
}

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else if (isTransportation.value) {
    router.push('/application/driver-information')
  } else {    
    router.push('/application/background-questions') 
  }
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (areAllFormsSigned()) {
    app.value.email = email.value
    try {
      const r = await api.updateApplicationEEOAA(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'eeo-aa',
          title: 'Equal Employment Opportunity & Affirmative Action',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else {
          router.push('/application/review')
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 
        'Error saving Equal Employment Opportunity & Affirmative Action Statement info' : 
        'Error al guardar la información de La Igualdad de Oportunidades en el Empleo y Declaración de Acción Afirmativa')
    }
  }  
}
</script>

<style>
.read-more-toggle {
  font-size: 13px;
}
.read-more-toggle:hover {
  cursor: pointer;
}
.radio-btn .v-label {
  opacity: 1 !important;
  letter-spacing: 0 !important;
}
</style>
