<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="section" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Background Questions' : 'Preguntas Sobre Antecedentes' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <div v-if="phase === 0 || phase === 1">
            <div v-if="requireBackgroundQuestion">
              <!-- Criminal Convictions -->
              <div class="font-sm mb-3"> 
                <span v-if="isEnglish">
                  Have you ever (i) been convicted of, or (ii) pled guilty to, or (iii) pled no contest to a violation of law,
                  other than a minor traffic violation? Do not include in your response any convictions that have been sealed
                  or expunged*
                </span>
                <span v-else>
                  ¿Alguna vez (i) ha sido condenado, (ii) se ha declarado culpable, o (iii) ha declarado no oposición a una 
                  violación de la ley que no haya sido una violación menor de tráfico? No incluya en su respuesta ninguna 
                  condena que haya sido sellada o expurgada*
                </span>
              </div>
              <v-radio-group
                class="radio-btn tf-padding d-flex justify-center"
                v-model="app.hasCriminalConvictions"
                :rules="yesOrNoRule"
                :readonly="loading"
                inline
              >
                <v-radio 
                  class="mr-10"
                  :label="isEnglish ? 'Yes' : 'Sí'" 
                  :value="true"
                ></v-radio>
                <v-radio 
                  class="mr-5"
                  :label="'No'" 
                  :value="false"
                ></v-radio>
              </v-radio-group>
              <!-- Criminal Conviction Details -->
              <div v-if="app.hasCriminalConvictions">
                <div class="font-sm mb-3"> 
                  {{ isEnglish ? 
                    'If yes, state the date, court, and place where offense occurred. Specify offense*' : 
                    'Anote la fecha, el tribunal, y el lugar donde ocurrió la ofensa. Especifique la ofensa*' }}
                </div>
                <v-textarea
                  v-model="app.criminalConvictionDetails"
                  :rules="required"
                  :readonly="loading"
                  row-height="20"
                  rows="2"
                  auto-grow
                  class="tf-padding"
                  variant="outlined"
                  :hint="isEnglish ? 'Conviction of a crime is not an automatic bar to consideration for employment. ' + 
                  'Labatt will conduct an individualized assessment of any criminal history record, considering, for example, ' + 
                  'the nature and severity of the violation, the duties of the position sought, the date and amount of time ' + 
                  'that has passed since the offense or completion of the sentence, and providing the applicant with the ' + 
                  'opportunity to provide relevant additional information. Any determinations will be consistent with any ' +
                  'applicable local, state, and federal laws.' : 
                  'La convicción de un crimen no es una barra automática de consideración para el empleo. ' +
                  'Labatt llevará a cabo una evaluación individualizada de cualquier incidente en su historia ' +
                  'criminal, tomando en cuenta, por ejemplo, la naturaleza y la gravedad de la violación, ' +
                  'las responsabilidades de la posición, la fecha y la cantidad de tiempo transcurrido desde ' +
                  'el delito o la finalización de la sentencia y dándole al solicitante la oportunidad de ' +
                  'proveer información adicional. Cualquier determinación será consistente con cualquier ' +
                  'ley local, estatal, y federal aplicable.'"
                  persistent-hint
                ></v-textarea> 
              </div>
            </div>
            <!-- Prior Terminations -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Have you ever been discharged or requested to resign?*' : 
                 '¿Alguna vez ha sido despedido o se le ha pedido su renuncia?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.hasPriorTerminations"
              :rules="yesOrNoRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>
            <!-- Prior Termination Details -->
            <div v-if="app.hasPriorTerminations">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'If yes, please explain*' : 'Por favor explique*' }}
              </div>
              <v-textarea
                v-model="app.priorTerminationDetails"
                :rules="required"
                :readonly="loading"
                row-height="20"
                rows="2"
                auto-grow
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-textarea> 
            </div> 
            <!-- License Violations -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Has your license ever been revoked, denied, or suspended?*' : 
                 '¿Alguna vez se le ha revocado, negado, o suspendido su licencia de conducir?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.hasLicenseViolations"
              :rules="yesOrNoRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>
            <!-- License Violation Details -->
            <div v-if="app.hasLicenseViolations">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'If yes, please explain*' : 'Por favor explique*' }}
              </div>
              <v-textarea
                v-model="app.licenseViolationDetails"
                :rules="required"
                :readonly="loading"
                row-height="20"
                rows="2"
                auto-grow
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-textarea> 
            </div>     
            <!-- Vehicular Accidents -->
            <div class="font-sm mb-3">
              {{ isEnglish ? 
                 'If applying for a warehouse or driving job, how many vehicular accidents have you had in the past 10 years?*' : 
                 'Si está aplicando a un trabajo de almacén o transporte, ¿cuántos accidentes de tránsito ha tenido en los últimos 10 años?*' }}
            </div>
            <v-text-field
              v-model="app.numberOfVehicularAccidents"
              :rules="numberRule"
              :readonly="loading"
              type="number"
              class="tf-padding"
              variant="outlined"
              v-mask="'###'"
              hide-details
            ></v-text-field>
            <!-- Vehicular Accident Details -->
            <div v-if="app.numberOfVehicularAccidents && app.numberOfVehicularAccidents > 0">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'If yes, please give details*' : 'Por favor explique*' }}
              </div>
              <v-textarea
                v-model="app.vehicularAccidentDetails"
                :rules="required"
                :readonly="loading"
                row-height="20"
                rows="2"
                auto-grow
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-textarea> 
            </div>    
            <!-- Moving Violations -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'If applying for a warehouse or driving job, how many moving violations have you had in the past 3 years?*' : 
                 'Si está aplicando a un trabajo de almacén o transporte, ¿cuántos infracciones de tránsito ha tenido en los últimos 3 años?*' }}
            </div>
            <v-text-field
              v-model="app.numberOfMovingViolations"
              :rules="numberRule"
              :readonly="loading"
              type="number"
              class="tf-padding"
              variant="outlined"
              v-mask="'###'"
              hide-details
            ></v-text-field>            
            <!-- Moving Violation Details -->
            <div v-if="app.numberOfMovingViolations && app.numberOfMovingViolations > 0">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'If yes, please give details*' : 'Por favor explique*' }}
              </div>
              <v-textarea
                v-model="app.movingViolationDetails"
                :rules="required"
                :readonly="loading"
                row-height="20"
                rows="2"
                auto-grow
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-textarea> 
            </div>      
            <!-- Labatt Family Member -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Do you have family members who work for Labatt?*' : 
                 '¿Tiene algún familiar que trabaje para Labatt?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.hasFamilyAtLabatt"
              :rules="yesOrNoRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>                      
            <!-- Labatt Family Member Details -->
            <div v-if="app.hasFamilyAtLabatt">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'Who?*' : '¿Quién?*' }}
              </div>
              <v-text-field
                v-model="app.familyAtLabattDetails"
                :rules="required"
                :readonly="loading"
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-text-field> 
            </div>   
          </div>
          <div v-if="phase === 0 || phase === 2">               
            <!-- Getting to Work -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'How do you plan on getting to work every day?*' : 
                 '¿Cómo planea llegar al trabajo todos los días?*' }}
            </div>
            <v-text-field
              v-model="app.getToWorkTransportation"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Days or Weeks Unavailable -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Are there any days or hours of the week you would not be able to work? Please specify*' : 
                 '¿Hay algún día u horas de la semana que no pueda trabajar? Por favor especifique*' }}
            </div>
            <v-text-field
              v-model="app.daysAndWeeksUnavailable"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Previous Applicant -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Have you ever filed an application with Labatt?*' : 
                 '¿Ha solicitado trabajo en Labatt previamente?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.isPreviousApplicant"
              :rules="yesOrNoRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>                         
            <!-- Previous Applicant Details -->
            <div v-if="app.isPreviousApplicant">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'When?*' : '¿Cuándo?*' }}
              </div>
              <v-text-field
                v-model="app.previousApplicantDate"
                :rules="required"
                :readonly="loading"
                type="date"
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-text-field> 
            </div>   
            <!-- Previous Employee -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 'Have you worked for Labatt before?*' : '¿Ha sido empleado de Labatt previamente?*' }}
            </div>
            <v-radio-group
              class="radio-btn tf-padding d-flex justify-center"
              v-model="app.isPreviousEmployee"
              :rules="yesOrNoRule"
              :readonly="loading"
              inline
            >
              <v-radio 
                class="mr-10"
                :label="isEnglish ? 'Yes' : 'Sí'" 
                :value="true"
              ></v-radio>
              <v-radio 
                class="mr-5"
                :label="'No'" 
                :value="false"
              ></v-radio>
            </v-radio-group>
            <!-- Previous Employee Details -->
            <div v-if="app.isPreviousEmployee">
              <div class="font-sm mb-3"> 
                {{ isEnglish ? 'When?*' : '¿Cuándo?*' }}
              </div>
              <v-text-field
                v-model="app.previousEmployeeDate"
                :rules="required"
                :readonly="loading"
                type="date"
                class="tf-padding"
                variant="outlined"
                hide-details
              ></v-text-field> 
            </div>  
            <!-- Job Attractions -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'What attracts you to this job? Please select all that apply*' : 
                 '¿Qué lo atrae a este trabajo? Por favor marque todo lo que aplique*' }}
            </div>
            <v-select
              v-model="app.jobAttractions"
              :rules="required"
              :readonly="loading"
              :items="[
                { id: 'Pay', title: isEnglish ? 'Pay' : 'Paga' }, 
                { id: 'Secure', title: isEnglish ? 'Secure company' : 'Compañía estable' }, 
                { id: 'Place', title: isEnglish ? 'Nice place to work' : 'Lugar agradable para trabajar' }, 
                { id: 'Benefits', title: isEnglish ? 'Benefits' : 'Beneficios' }, 
                { id: 'Inside', title: isEnglish ? 'Working inside' : 'Trabajar adentro' }, 
                { id: 'Similar', title: isEnglish ? 'Similar work experience' : 'Experiencia de trabajo semejante' }, 
                { id: 'Close', title: isEnglish ? 'Close to home' : 'Cerca de casa' }, 
                { id: 'Products', title: isEnglish ? 'Products sold' : 'Los productos que se venden' }, 
                { id: 'Size', title: isEnglish ? 'Size of company' : 'El tamaño de la compañía' }, 
                { id: 'Type', title: isEnglish ? 'Type of work' : 'El tipo de trabajo' }, 
                { id: 'Need', title: isEnglish ? 'Need a job' : 'Necesita un trabajo' }, 
                { id: 'Friends', title: isEnglish ? 'Friends who work here' : 'Los amigos que trabajan aquí' }, 
              ]"
              item-value="id"
              item-title="title"
              class="tf-padding"
              variant="outlined"
              chips
              multiple
              hide-details
            ></v-select>
            <!-- Referral Source -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'How were you referred to us?*' : 
                 '¿Cómo fue referido a nuestra compañía?*' }}
            </div>
            <!-- Referral Source -->
            <v-select
              v-model="app.referralSourceCode"
              :rules="required"
              :readonly="loading"
              :items="referralSources"
              item-value="code"
              :item-title="isEnglish ? 'description' : 'spanishDescription'"
              class="tf-padding"
              variant="outlined"
              @update:modelValue="updateReferralSource()"
              hide-details
            ></v-select>            
            <!-- Referral Source Details -->
            <v-text-field
              v-if="app.hasReferralSourceDetails"
              v-model="app.referralSourceDetails"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              variant="outlined"
              :label="formatReferralSourceDetailLabel"
              hide-details
            ></v-text-field>
            <!-- Job Importance -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 
                 'Getting this job is important to me because:*' : 
                 'Obtener este trabajo es importante para mí porque:*' }}
            </div>
            <v-textarea
              v-model="app.whyIsJobImportant"
              :rules="required"
              :readonly="loading"
              row-height="20"
              rows="2"
              auto-grow
              class="tf-padding"
              variant="outlined"
              hide-details
            ></v-textarea>
          </div>
          <br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent> 
        </v-form>
      </v-col>
    </v-row> 
  </div>   
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import type { ApplicationBackground, ApplicationSection, ReferralSource } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const yesOrNoRule = [(value: string) => isYesOrNoValid(value) || 'Required']
const numberRule = [(value: string) => isNumberValid(value) || 'Invalid']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const app = ref({} as ApplicationBackground)
const referralSources = ref([] as ReferralSource[])
const requireBackgroundQuestion = ref(true)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const section = computed(() => {
  if (phase.value === 1 || isOffice.value) {
    return 5
  }
  return 6
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const formatReferralSourceDetailLabel = computed(() => {
  const found = referralSources.value.find(rs => rs.code === app.value.referralSourceCode) 
  if (found) {
    if(found.description.includes('Labatt employee')) {
      return isEnglish.value ? 'Employee\'s name' : 'Nombre del empleado'
    }
    if(found.description.includes('Driving school')) {
      return isEnglish.value ? 'Name' : 'Nombre'
    }
  }
  return isEnglish.value ? 'Please explain' : 'Por favor explique'
})

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isYesOrNoValid = (v: any) : boolean => {
  return v || v === true || v === false
}

const isNumberValid = (v: any) : boolean => {
  return v !== null && v >= 0
}

const isHasCriminalConvictionsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (requireBackgroundQuestion.value === false || app.value.hasCriminalConvictions === true || app.value.hasCriminalConvictions === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isCriminalConvictionDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (requireBackgroundQuestion.value === false || app.value.hasCriminalConvictions === false || app.value.criminalConvictionDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isHasPriorTerminationsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasPriorTerminations === true || app.value.hasPriorTerminations === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPriorTerminationDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasPriorTerminations === false || app.value.priorTerminationDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isHasLicenseViolationsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasLicenseViolations === true || app.value.hasLicenseViolations === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isLicenseViolationDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasLicenseViolations === false || app.value.licenseViolationDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isNumberOfVehicularAccidentsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Number of vehicular accidents is invalid' : 'Número de accidentes de tránsito no es válido'
  if (app.value.numberOfVehicularAccidents !== null && app.value.numberOfVehicularAccidents >= 0) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isVehicularAccidentDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.numberOfVehicularAccidents == 0 || app.value.vehicularAccidentDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isNumberOfMovingViolationsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Number of moving violations is invalid' : 'Número de violaciones de tránsito no es válido'
  if (app.value.numberOfMovingViolations !== null && app.value.numberOfMovingViolations >= 0) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isMovingViolationDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.numberOfMovingViolations == 0 || app.value.vehicularAccidentDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isHasFamilyAtLabattValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasFamilyAtLabatt === true || app.value.hasFamilyAtLabatt === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isFamilyAtLabattDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasFamilyAtLabatt === false || app.value.familyAtLabattDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isGetToWorkTransportationValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.getToWorkTransportation) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isDaysAndWeeksUnavailableValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.daysAndWeeksUnavailable) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousApplicantValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isPreviousApplicant === true || app.value.isPreviousApplicant === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousApplicantDateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isPreviousApplicant === false || app.value.previousApplicantDate) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousEmployeeValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isPreviousEmployee === true || app.value.isPreviousEmployee === false) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousEmployeeDateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.isPreviousEmployee === false || app.value.previousEmployeeDate) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isJobAttractionsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.jobAttractions) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceCodeValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.referralSourceCode) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isReferralSourceDetailsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.hasReferralSourceDetails === false || app.value.referralSourceDetails) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isWhyIsJobImportantValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Please answer the required question(s)' : 'Por favor responda las preguntas requeridas'
  if (app.value.whyIsJobImportant) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhaseOneValid = () : boolean => {
  return isHasCriminalConvictionsValid(true) &&
    isCriminalConvictionDetailsValid(true) &&
    isHasPriorTerminationsValid(true) &&
    isPriorTerminationDetailsValid(true) &&
    isHasLicenseViolationsValid(true) &&
    isLicenseViolationDetailsValid(true) &&
    isNumberOfVehicularAccidentsValid(true) &&
    isVehicularAccidentDetailsValid(true) &&
    isNumberOfMovingViolationsValid(true) &&
    isMovingViolationDetailsValid(true) &&
    isHasFamilyAtLabattValid(true) &&
    isFamilyAtLabattDetailsValid(true)
}

const isPhaseTwoValid = () : boolean => {
  return isGetToWorkTransportationValid(true) &&
    isDaysAndWeeksUnavailableValid(true) &&
    isPreviousApplicantValid(true) &&
    isPreviousApplicantDateValid(true) &&
    isPreviousEmployeeValid(true) &&
    isPreviousEmployeeDateValid(true) &&
    isJobAttractionsValid(true) &&
    isReferralSourceCodeValid(true) &&
    isReferralSourceDetailsValid(true) &&
    isWhyIsJobImportantValid(true)
}

const areBothPhasesValid = () : boolean => {
  return isHasCriminalConvictionsValid(true) &&
    isCriminalConvictionDetailsValid(true) && 
    isHasPriorTerminationsValid(true) &&
    isPriorTerminationDetailsValid(true) &&
    isHasLicenseViolationsValid(true) &&
    isLicenseViolationDetailsValid(true) &&
    isNumberOfVehicularAccidentsValid(true) &&
    isVehicularAccidentDetailsValid(true) &&
    isNumberOfMovingViolationsValid(true) &&
    isMovingViolationDetailsValid(true) &&
    isHasFamilyAtLabattValid(true) &&
    isFamilyAtLabattDetailsValid(true) &&
    isGetToWorkTransportationValid(true) &&
    isDaysAndWeeksUnavailableValid(true) &&
    isPreviousApplicantValid(true) &&
    isPreviousApplicantDateValid(true) &&
    isPreviousEmployeeValid(true) &&
    isPreviousEmployeeDateValid(true) &&
    isJobAttractionsValid(true) &&
    isReferralSourceCodeValid(true) &&
    isReferralSourceDetailsValid(true) &&
    isWhyIsJobImportantValid(true)
}

const isFormValid = () : boolean => {
  if (phase.value === 1) {
    return isPhaseOneValid()
  } 
  if (phase.value === 2) {
    return isPhaseTwoValid()
  }
  return areBothPhasesValid()
}

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r = await api.getOffices()
    if (r) {
      const office = r.find(o => o.code == store.selectedJobPosting?.officeCode)
      if (office?.requireBackgroundQuestion) {
        requireBackgroundQuestion.value = true
      } else {
        requireBackgroundQuestion.value = false
      }
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    const r2 = await api.getReferralSources()
    if (r2) {
      var officeCode = ''
      if (store.selectedJobPosting?.officeCode) {
        officeCode = store.selectedJobPosting?.officeCode
      }
      referralSources.value = r2.filter(rs => rs.offices.includes(officeCode))
      
      if (isOffice.value) {
        referralSources.value = referralSources.value.filter(rs => rs.isForResume === true)
      }
      if (isEnglish.value) {
        referralSources.value.sort((a, b) => a.description.localeCompare(b.description))
      } else {
        referralSources.value.sort((a, b) => a.spanishDescription.localeCompare(b.spanishDescription))
      }
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    if (store.applicationId) {
      const r3 = await api.getApplicationBackground(store.applicationId)
      if (r3) {
        app.value = r3
        if (app.value.referralSourceCode) {
          // do nothing
        } else {
          app.value.referralSourceCode = null
        }
        if (phase.value === 2) {
          if (app.value.isPreviousApplicant === false) {
            app.value.isPreviousApplicant = null
            app.value.previousApplicantDate = null
          }
          if (app.value.isPreviousEmployee === false) {
            app.value.isPreviousEmployee = null
            app.value.previousEmployeeDate = null
          }
        }
      } else {
        app.value.id = store.applicationId
      }
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const updateReferralSource = async () => {
  const selected = referralSources.value.find(r => r.code === app.value.referralSourceCode)
  if (selected) {
    app.value.referralSourceDescription = selected.description
    app.value.hasReferralSourceDetails = selected.needDetails
  }
}

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else if (phase.value === 1 || isOffice.value) {
    router.push('/application/previous-employment')
  } else {
    router.push('/application/work-experience') 
  }
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value
    if (app.value.jobAttractions) {
      // do nothing
    } else {
      app.value.jobAttractions = []
    }
    try {
      const r = await api.updateApplicationBackground(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'background-questions',
          title: 'Background Questions',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else if (phase.value === 1) {
          if (isTransportation.value) {
            router.push('/application/driver-information')
          } else {
            router.push('/application/review')
          }
        } else if (phase.value === 2) {        
          router.push('/application/eeo-aa') 
        } else {
          if (isTransportation.value) {
          router.push('/application/driver-information') 
          } else {
            router.push('/application/eeo-aa') 
          }
        }
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving background questions' : 'Error al guardar la información de preguntas sobre antecedentes')
    }
  }
}
</script>

<style>

</style>
