<template>
  <!-- Job -->
  <JobTitleComponent class="mb-2"></JobTitleComponent>
  <!-- Progress -->
  <v-row>
    <v-col>
      <ApplicationProgressComponent :phase="phase" :section="section" />
    </v-col>
  </v-row>
  <br>
  <!-- View -->
  <v-row>
    <v-col>
      <div class="title-bold"> {{ isEnglish ? 'Application Submitted' : 'Aplicación Enviada' }} </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-form
        @submit.prevent="saveAndContinue()"
      > 
        <div>
          <span v-if="phase === 1">
            {{ isEnglish ? 'You have successfully sent your submission for ' : 'Usted ha enviado su solicitud de ' }}
          </span>
          <span v-else>
            {{ isEnglish ? 'You have successfully submitted your application for ' : 'Usted ha enviado su solicitud de ' }}
          </span>
          <b>{{ jobTitle }}</b> {{ isEnglish ? ' on ' : ' hoy el ' }} <b>{{ formatDate(today) }}</b>.
        </div>
        <br>
        <div>
          <span v-if="phase === 1">
            {{ isEnglish ? 
             'If you meet the qualifications, our recruiter will give you a call within a week. If you do not hear back ' + 
             'from us, you are welcome to reapply after 90 days. We do not accept calls for application status.' : 
             'Si cumple con los requisitos del trabajo, nuestro representante le dará una ' +
             'llamada dentro de una semana. Si usted no recibe una respuesta de nosotros, le invitamos a volver a ' +
             'aplicar despúes de 90 días. No aceptamos llamadas para información del estado de su aplicación.' }}
          </span>
          <span v-else>
            {{ isEnglish ? 
             'If you meet the qualifications, our recruiter will give you a call within a week. If you do not hear back ' + 
             'from us, you are welcome to reapply after 90 days. We do not accept calls for application status.' : 
             'Si cumple con los requisitos del trabajo, nuestro representante le dará una ' +
             'llamada dentro de una semana. Si usted no recibe una respuesta de nosotros, le invitamos a volver a ' +
             'aplicar despúes de 90 días. No aceptamos llamadas para información del estado de su aplicación.' }}
          </span>
        </div>
        <br>
        <div>
          {{ isEnglish ? 'Thank you for applying with Labatt Food Service!' : '¡Gracias por aplicar con Labatt Food Service!' }}
        </div>
        <br><br><br>
        <!-- Btns -->
        <v-row>
          <v-col>
            <!-- Continue - Btn -->
            <div class="d-flex justify-center">
              <v-btn 
                class="btn orange-btn" 
                :width="113" 
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'Continue' : 'Continuar' }}
              </v-btn>
            </div>        
          </v-col>
        </v-row>
        <br>
      </v-form>
    </v-col>
  </v-row>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/stores/store'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'

const router = useRouter()
const store = useStore()
const today = new Date().toISOString().substring(0, 10)
const timeout = ref(null as any)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const jobTitle = computed(() => {
  return store.selectedJobPosting?.title
})

const section = computed(() => {
  if (phase.value === 1) {
    return isTransportation.value ? 8 : 7
  } 
  if (phase.value === 2) {
    return isOffice.value ? 9 : 10
  }
  if (isOffice.value) {
    return 9
  }
  if (isTransportation.value) {
    return 11
  }
  return 10
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const reload = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  timeout.value = setTimeout(() => {
    saveAndContinue()
  }, 30000)
}
onMounted(reload)

onBeforeRouteLeave((to, from) => {
  clearTimeout(timeout.value)
})

const formatDate = (d: string | null | undefined) => {
  if (d && d !== '0001-01-01') {
    return d.substring(5, 7) + '/' + d.substring(8, 10) + '/' + d.substring(0, 4)
  }
  return ''
}

const saveAndContinue = async () => {
  store.resetStore()
  router.push('/dashboard') 
}
</script>

<style>

</style>
