<template>
  <div class="activities">
    <!-- Logout -->
    <v-container class="container-view logout-view">
      <LogoutComponent :view="isEnglish ? 'Activities' : 'Actividades'" />
    </v-container>
    <!-- View -->
    <v-container class="container-activities-view activities-inner">
      <div v-if="!loading">
        <!-- Job -->
        <JobTitleComponent class="mb-2"></JobTitleComponent>
        <!-- Progress -->
        <ActivityProgressComponent 
          :key="activityNumber + activitiesTotal" 
          :activity="activityNumber" 
          :total="activitiesTotal"
        ></ActivityProgressComponent>
      </div>
      <br>
      <!-- Loading -->
      <div v-if="loading">
        <br>
        <div class="d-flex justify-center">
          <v-progress-circular
            class="orange-spinner"
            indeterminate
          ></v-progress-circular>
        </div>      
      </div>
      <div v-else>
        <v-row>
          <v-col>
            <!-- Completed Activities -->
            <div v-if="hasCompletedAllActivities">
              <div class="d-flex justify-center title-bold mb-8">
                {{ isEnglish ? 'All Activities Completed' : 'Ha Terminado Todas Las Actividades' }}
              </div>
              <div class="d-flex justify-center">
                {{ isEnglish ? 
                  'You have completed all the required activities for this position. Please log out of your account and notify the hiring assistant.' :
                  'Ha terminado todas las actividades requeridas para esta posición. Por favor cierre la sesión de su cuenta y notifique al asistente.' }}
              </div>
              <br><br>
              <!-- Log out - Btn -->
              <div class="d-flex justify-center">
                <v-btn 
                  :loading="loading"
                  class="btn orange-btn" 
                  elevation="0"
                  type="submit"
                  @click="logout()"
                >
                  {{ isEnglish ? 'Log out' : 'Cerrar sesión' }}
                </v-btn>
              </div>
            </div>
            <!-- Activity -->
            <div v-else-if="activity && activity.testQuestions">
              <!-- Alert -->
              <div 
                v-if="error"
                class="d-flex justify-center mb-5"
              >
                <div class="alert">
                  <v-alert
                    border="start"
                    type="error"
                    variant="tonal"
                  >
                    {{ errorMessage }}
                  </v-alert>
                </div> 
              </div>
              <!-- Activity Name -->
              <div class="d-flex justify-center title-bold mb-8">
                {{ isEnglish ? activity.name : activity.spanishName }}
              </div>
              <div v-if="!hasStartedActivity">
                <!-- Activity Instructions -->
                <div class="d-flex justify-center">
                  <div class="instructions">
                    {{ isEnglish ? activity.englishInstructions : activity.spanishInstructions }}
                  </div>
                </div>
                <br><br>
                <!-- Start Activity - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :loading="loading"
                    class="btn orange-btn" 
                    elevation="0"
                    type="submit"
                    @click="startActivity()"
                  >
                    {{ isEnglish ? 'Start ' + activity.name : 'Iniciar ' + activity.spanishName }}
                  </v-btn>
                </div>
              </div>
              <div v-else-if="hasCompletedActivityTest">
                <!-- Activity Instructions -->
                <div class="d-flex justify-center">
                  {{ isEnglish ? 
                      'You have completed the ' + activity.name + '.' : 
                      'Ha terminado ' + formatActivitySpanish(activity.spanishName) + '.' }}
                </div>
                <br><br>
                <!-- Next Activity - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :loading="loading"
                    class="btn orange-btn" 
                    elevation="0"
                    type="submit"
                    @click="setNextActivity()"
                  >
                    {{ isEnglish ? 'Continue' : 'Continuar' }}
                  </v-btn>
                </div>
              </div>
              <!-- Checkbox - Activity -->
              <div v-else-if="activity.type === 'Checkbox'">
                <!-- Practice -->
                <div v-if="activity.hasPracticeQuestions && !hasCompletedPracticeActivity && activity.practiceQuestions">
                  <div class="d-flex justify-center mb-3">
                    {{ isEnglish ? 'Practice Question' : 'Pregunta de Práctica' }}
                  </div>
                  <!-- Question -->
                  <div class="d-flex justify-center">
                    {{ currentActivityQuestion + 1 }}.
                    {{ isEnglish ? 
                      activity.practiceQuestions[currentActivityQuestion]?.englishQuestion : 
                      activity.practiceQuestions[currentActivityQuestion]?.spanishQuestion }}
                  </div>
                  <br>
                  <!-- Answers -->
                  <div class="d-flex justify-center">
                    <ul>
                      <li 
                        v-for="(a, i) in activity.practiceQuestions[currentActivityQuestion]?.answers"
                        style="list-style-type: none;"
                      >
                        <v-checkbox 
                          v-model="applicantAnswers[i]"
                          class="radio-btn"
                          density="compact"
                          :value="a.code"
                          :label="isEnglish ? formatDescription(a.englishDescription) : formatDescription(a.spanishDescription)"
                          hide-details
                        ></v-checkbox>
                      </li>
                    </ul>
                  </div>
                  <!-- Result -->
                  <br>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Incorrect!' : '¡Incorrecto!' }}
                  </div>
                  <div 
                    v-else-if="isApplicantAnswerCorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Correct!' : '¡Correcto!' }}
                  </div>
                  <br>
                  <div
                    v-if="isApplicantAnswerIncorrect || isApplicantAnswerCorrect" 
                    class="d-flex justify-center"
                  >
                    <!-- Continue - Btn -->
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="nextPracticeActivity()"
                    >
                      {{ isEnglish ? 'Continue' : 'Continuar' }}
                    </v-btn>
                  </div>
                  <div 
                    v-else
                    class="d-flex justify-center"
                  >
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="savePracticeActivity()"
                    >
                      {{ isEnglish ? 'Continue' : 'Continuar' }}
                    </v-btn>
                  </div>
                </div>
                <!-- Completed Practice -->
                <div v-else-if="activity.hasPracticeQuestions && hasCompletedPracticeActivity && !hasStartedActivityTest">
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 
                      'You have finished the practice section of the assessment. Click one of the buttons below to continue.' :
                      'Ha terminado la sección de práctica de la evaluación. Seleccione uno de los botones debajo para continuar.' }}
                  </div>
                  <br><br>
                  <v-row>
                    <v-col class="hidden-xs"></v-col>
                    <v-col>
                      <!-- Retake - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn" 
                          elevation="0"
                          type="submit"
                          @click="retakePracticeActivity()"
                        >
                          {{ isEnglish ? 'Retake the practice section' : 'Volver a tomar la sección de práctica' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col>
                      <!-- Continue - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn ml-15" 
                          elevation="0"
                          type="submit"
                          @click="startActivityTest()"
                        >
                          {{ isEnglish ? 'Continue with the assessment' : 'Continuar con la evaluación' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col class="hidden-xs"></v-col>
                  </v-row>
                </div>
                <!-- Test -->
                <div v-else>
                  <div v-if="activity.hasTimeLimit" class="d-flex justify-center mb-3">
                    {{ isEnglish ? 'Time:' : 'Tiempo:' }} {{ formattedActivityTime }}
                  </div>
                  <div class="d-flex justify-center">
                    {{ currentActivityQuestion + 1 }}.
                    {{ isEnglish ? 
                      activity.testQuestions[currentActivityQuestion]?.englishQuestion : 
                      activity.testQuestions[currentActivityQuestion]?.spanishQuestion }}
                  </div>
                  <br>
                  <div class="d-flex justify-center">
                    <ul>
                      <li 
                        v-for="(a, i) in activity.testQuestions[currentActivityQuestion]?.answers"
                        style="list-style-type: none;"
                      >
                        <v-checkbox 
                          v-model="applicantAnswers"
                          class="radio-btn"
                          density="compact"
                          :value="a.code"
                          :label="isEnglish ? formatDescription(a.englishDescription) : formatDescription(a.spanishDescription)"
                          @update:modelValue="updateApplicantAnswers(a.code)"
                          hide-details
                        ></v-checkbox>
                      </li>
                    </ul>
                  </div>
                  <br><br>
                  <!-- Continue - Btn -->
                  <div class="d-flex justify-center">
                    <v-btn 
                      :loading="loading"
                      :disabled="btnDisabled"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="saveActivity()"
                    >
                      {{ isEnglish ? 'Continue' : 'Continuar' }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- Same Or Different - Activity -->
              <div v-else-if="activity.type === 'SameOrDifferent'">
                <!-- Practice -->
                <div v-if="activity.hasPracticeQuestions && !hasCompletedPracticeActivity && activity.practiceQuestions">
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 'Practice Question' : 'Pregunta de Práctica' }}
                    {{ currentActivityQuestion + 1 }} {{ isEnglish ? 'of' : 'de' }} {{ activity.practiceQuestions.length }}
                  </div>
                  <!-- Question -->
                  <div class="d-flex justify-center">
                    <v-img
                      height="150px"
                      :src="'data:image/png;base64,' + activity.practiceQuestions[currentActivityQuestion]?.image1"
                    ></v-img>
                  </div>
                  <!-- Result -->
                  <br>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Incorrect!' : '¡Incorrecto!' }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Your answer: ' : 'Su respuesta: ' }} {{ selectedAnswer }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Correct answer: ' : 'Respuesta correcta: ' }} {{ actualAnswer }}
                  </div>
                  <div 
                    v-else-if="isApplicantAnswerCorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Correct!' : '¡Correcto!' }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerCorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Your answer: ' : 'Su respuesta: ' }} {{ selectedAnswer }}
                  </div>
                  <br>
                  <!-- Continue - Btn -->
                  <div
                    v-if="isApplicantAnswerIncorrect || isApplicantAnswerCorrect" 
                    class="d-flex justify-center"
                  >
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="nextPracticeActivity()"
                    >
                      {{ isEnglish ? 'Continue' : 'Continuar' }}
                    </v-btn>
                  </div>
                  <div 
                    v-else
                    class="d-flex justify-center"
                  >
                    <!-- Same - Btn -->
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      :width="113" 
                      elevation="0"
                      type="submit"
                      @click="savePracticeActivity('S')"
                    >
                      {{ isEnglish ? 'Same' : 'Igual' }}
                    </v-btn>
                    <!-- Different - Btn -->
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn ml-15" 
                      :width="113" 
                      elevation="0"
                      type="submit"
                      @click="savePracticeActivity('D')"
                    >
                      {{ isEnglish ? 'Different' : 'Diferente' }}
                    </v-btn>
                  </div>
                </div>
                <!-- Completed Practice -->
                <div v-else-if="activity.hasPracticeQuestions && hasCompletedPracticeActivity && !hasStartedActivityTest">
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 
                      'You have finished the practice section of the assessment. Click one of the buttons below to continue.' :
                      'Ha terminado la sección de práctica de la evaluación. Seleccione uno de los botones debajo para continuar.' }}
                  </div>
                  <br><br>
                  <v-row>
                    <v-col class="hidden-xs"></v-col>
                    <v-col>
                      <!-- Retake - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn" 
                          elevation="0"
                          type="submit"
                          @click="retakePracticeActivity()"
                        >
                          {{ isEnglish ? 'Retake the practice section' : 'Volver a tomar la sección de práctica' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col>
                      <!-- Continue - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn" 
                          elevation="0"
                          type="submit"
                          @click="startActivityTest()"
                        >
                          {{ isEnglish ? 'Continue with the assessment' : 'Continuar con la evaluación' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col class="hidden-xs"></v-col>
                  </v-row>
                </div>
                <!-- Test -->
                <div v-else>
                  <div class="d-flex justify-center mb-3">
                    {{ isEnglish ? 'Time:' : 'Tiempo:' }} {{ formattedActivityTime }}
                  </div>
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 'Question' : 'Pregunta' }} 
                    {{ currentActivityQuestion + 1 }} {{ isEnglish ? 'of' : 'de' }} {{ activity.testQuestions.length }}
                  </div>
                  <!-- Question -->
                  <div class="d-flex justify-center">
                    <v-img
                      height="150px"
                      :src="'data:image/png;base64,' + activity.testQuestions[currentActivityQuestion]?.image1"
                    ></v-img>
                  </div>
                  <br><br>
                  <div class="d-flex justify-center">
                    <!-- Same - Btn -->
                    <v-btn 
                      :loading="loading"
                      :disabled="btnDisabled"
                      class="btn orange-btn" 
                      :width="113" 
                      elevation="0"
                      type="submit"
                      @click="saveActivity('S')"
                    >
                      {{ isEnglish ? 'Same' : 'Igual' }}
                    </v-btn>
                    <!-- Different - Btn -->
                    <v-btn 
                      :loading="loading"
                      :disabled="btnDisabled"
                      class="btn orange-btn ml-15" 
                      :width="113" 
                      elevation="0"
                      type="submit"
                      @click="saveActivity('D')"
                    >
                      {{ isEnglish ? 'Different' : 'Diferente' }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- Select Letter - Activity -->
              <div v-else-if="activity.type === 'SelectLetter'">
                <!-- Practice -->
                <div v-if="activity.hasPracticeQuestions && !hasCompletedPracticeActivity && activity.practiceQuestions">
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 'Practice Question' : 'Pregunta de Práctica' }}
                    {{ currentActivityQuestion + 1 }} {{ isEnglish ? 'of' : 'de' }} {{ activity.practiceQuestions.length }}
                  </div>
                  <!-- Question -->
                  <div class="d-flex justify-center">
                    <v-img
                      height="125px"
                      :src="'data:image/png;base64,' + activity.practiceQuestions[currentActivityQuestion]?.image1"
                    ></v-img>
                  </div>
                  <br><br>
                  <!-- Answers -->
                  <v-row>
                    <!-- Option - A -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="savePracticeActivity('A')"
                        >
                          A)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.practiceQuestions[currentActivityQuestion]?.answerImage1"
                      ></v-img>
                    </v-col>
                    <!-- Option - B -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="savePracticeActivity('B')"
                        >
                          B)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.practiceQuestions[currentActivityQuestion]?.answerImage2"
                      ></v-img>
                    </v-col>
                    <!-- Option - C -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="savePracticeActivity('C')"
                        >
                          C)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.practiceQuestions[currentActivityQuestion]?.answerImage3"
                      ></v-img>
                    </v-col>
                    <!-- Option - D -->
                    <v-col>
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="savePracticeActivity('D')"
                        >
                          D) {{ isEnglish ? 'No Match' : 'No Coinciden' }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- Result -->
                  <br>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Incorrect!' : '¡Incorrecto!' }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Your answer: ' : 'Su respuesta: ' }} {{ selectedAnswer }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerIncorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Correct answer: ' : 'Respuesta correcta: ' }} {{ actualAnswer }}
                  </div>
                  <div 
                    v-else-if="isApplicantAnswerCorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Correct!' : '¡Correcto!' }}
                  </div>
                  <div 
                    v-if="isApplicantAnswerCorrect"
                    class="d-flex justify-center"
                  >
                    {{ isEnglish ? 'Your answer: ' : 'Su respuesta: ' }} {{ selectedAnswer }}
                  </div>
                  <br>
                  <!-- Continue - Btn -->
                  <div
                    v-if="isApplicantAnswerIncorrect || isApplicantAnswerCorrect" 
                    class="d-flex justify-center"
                  >
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="nextPracticeActivity()"
                    >
                      {{ isEnglish ? 'Continue' : 'Continuar' }}
                    </v-btn>
                  </div>
                </div>
                <!-- Completed Practice -->
                <div v-else-if="activity.hasPracticeQuestions && hasCompletedPracticeActivity && !hasStartedActivityTest">
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 
                      'You have finished the practice section of the assessment. Click one of the buttons below to continue.' :
                      'Ha terminado la sección de práctica de la evaluación. Seleccione uno de los botones debajo para continuar.' }}
                  </div>
                  <br><br>
                  <v-row>
                    <v-col class="hidden-xs"></v-col>
                    <v-col>
                      <!-- Retake - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn" 
                          elevation="0"
                          type="submit"
                          @click="retakePracticeActivity()"
                        >
                          {{ isEnglish ? 'Retake the practice section' : 'Volver a tomar la sección de práctica' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col>
                      <!-- Continue - Btn -->
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          class="btn orange-btn ml-15" 
                          elevation="0"
                          type="submit"
                          @click="startActivityTest()"
                        >
                          {{ isEnglish ? 'Continue with the assessment' : 'Continuar con la evaluación' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col class="hidden-xs"></v-col>
                  </v-row>
                </div>
                <!-- Test -->
                <div v-else>
                  <div class="d-flex justify-center mb-3">
                    {{ isEnglish ? 'Time:' : 'Tiempo:' }} {{ formattedActivityTime }}
                  </div>
                  <div class="d-flex justify-center">
                    {{ isEnglish ? 'Question' : 'Pregunta' }} 
                    {{ currentActivityQuestion + 1 }} {{ isEnglish ? 'of' : 'de' }} {{ activity.testQuestions.length }}
                  </div>
                  <!-- Question -->
                  <div class="d-flex justify-center">
                    <v-img
                      height="150px"
                      :src="'data:image/png;base64,' + activity.testQuestions[currentActivityQuestion]?.image1"
                    ></v-img>
                  </div>
                  <br><br>
                  <!-- Answers -->
                  <v-row>
                    <!-- Option - A -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          :disabled="btnDisabled"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="saveActivity('A')"
                        >
                          A)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.testQuestions[currentActivityQuestion]?.answerImage1"
                      ></v-img>
                    </v-col>
                    <!-- Option - B -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          :disabled="btnDisabled"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="saveActivity('B')"
                        >
                          B)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.testQuestions[currentActivityQuestion]?.answerImage2"
                      ></v-img>
                    </v-col>
                    <!-- Option - C -->
                    <v-col>
                      <div class="d-flex justify-center mb-2">
                        <v-btn 
                          :loading="loading"
                          :disabled="btnDisabled"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="saveActivity('C')"
                        >
                          C)
                        </v-btn>
                      </div>
                      <!-- Image -->
                      <v-img
                        height="200px"
                        :src="'data:image/png;base64,' + activity.testQuestions[currentActivityQuestion]?.answerImage3"
                      ></v-img>
                    </v-col>
                    <!-- Option - D -->
                    <v-col>
                      <div class="d-flex justify-center">
                        <v-btn 
                          :loading="loading"
                          :disabled="btnDisabled"
                          class="btn orange-btn"
                          elevation="0"
                          type="submit"
                          @click="saveActivity('D')"
                        >
                          D) {{ isEnglish ? 'No Match' : 'No Coinciden' }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <!-- No Activities -->
            <div v-else>
              <div class="d-flex justify-center title-bold mb-8">
                {{ isEnglish ? 'No Activities' : 'No Hay Actividades' }}
              </div>
              <div class="d-flex justify-center">
                {{ isEnglish ? 
                  'You have no activities for this position. Please log out of your account and notify the hiring assistant.' :
                  'No tiene actividades para esta posición. Por favor cierre la sesión de su cuenta y notifique al asistente de contratación.' }}
              </div>
              <br><br>
              <!-- Log out - Btn -->
              <div class="d-flex justify-center">
                <v-btn 
                  :loading="loading"
                  class="btn orange-btn" 
                  elevation="0"
                  type="submit"
                  @click="logout()"
                >
                  {{ isEnglish ? 'Log out' : 'Cerrar sesión' }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { Activity, SaveActivity } from '@/types'
import api from '@/api'
import { useCookies } from 'vue3-cookies'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/stores/store'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import ActivityProgressComponent from '@/components/ActivityProgressComponent.vue'
import LogoutComponent from '@/components/LogoutComponent.vue'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const email = ref('')
const activities = ref([] as Activity[])
const activitiesCompleted = ref(0)
const { cookies } = useCookies()
const router = useRouter()
const store = useStore()
const activity = ref(null as Activity | null)
const currentActivityQuestion = ref(0)
const hasStartedActivity = ref(false)
const hasStartedActivityTest = ref(false)
const hasCompletedActivityTest = ref(false)
const hasCompletedPracticeActivity = ref(false)
const hasCompletedAllActivities = ref(false)
const applicantAnswers = ref([] as string[])
const isApplicantAnswerIncorrect = ref(false)
const isApplicantAnswerCorrect = ref(false)
const activityTime = ref(0 as number)
const activityStartTimestamp = ref(null as Date | null)
const timeInterval = ref(null as any)
const btnDisabled = ref(false)
const selectedAnswer = ref('' as string | null)
const actualAnswer = ref('' as string | null)
const timeout = ref(null as any)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const activityNumber = computed(() => {
  if (hasCompletedActivityTest.value) {
    return activitiesCompleted.value 
  }
  return activitiesCompleted.value + 1
})

const activitiesTotal = computed(() => {
  return activities.value.length
})

const formattedActivityTime = computed(() => {
  if (activity.value && activity.value.hasTimeLimit && activity.value.maxTime && activityTime.value !== activity.value.maxTime) {
    let seconds = 0 
    let minutes = 0
    if (activityTime.value) {
      seconds = Math.floor(activityTime.value) % 60
      minutes = Math.floor(activityTime.value / 60) % (activity.value.maxTime / 60)
    }
    return `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
  }
  return '--:--'
})

const reload = async () => {
  loading.value = true
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
    activities.value = await api.getActivities(email.value)
    activitiesCompleted.value = await api.getActivitiesCompleted(email.value)
    setNextActivity()
  } else {
    router.push('/login')
  }
  loading.value = false
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const logout = () => {
  cookies.remove('labatt-user-email')
  store.setCurrentEmail('')
  store.setTemporaryLanguage('')
  store.setLanguage('english')
  store.resetStore()
  router.push('/login')
}

const formatDescription = (d: string | null) => {
  if (d) {
    return d
  }
  return ''
}

const formatActivitySpanish = (a: string | null) => {
  if (a) {
    if (a.includes('Perfil')) {
      return 'el ' + a
    } else if (a.includes('Evaluación')) {
      return 'la ' + a
    }
  }
  return ''
}

const startActivity = () => {
  hasStartedActivity.value = true
  applicantAnswers.value = []
  if (activity.value?.hasPracticeQuestions) {
    store.setDisableLanguage(false)
    hasCompletedPracticeActivity.value = false
    hasStartedActivityTest.value = false
    hasCompletedActivityTest.value = false
  } else {
    store.setDisableLanguage(true)
    hasStartedActivityTest.value = true
    setActivityTime()
  }
}

const setActivityTime = () => {
  activityStartTimestamp.value = new Date()
  if (activity.value?.hasTimeLimit && activity.value?.maxTime) {
    activityTime.value = activity.value?.maxTime
    if (timeInterval.value) {
      clearInterval(timeInterval.value)
    }
    timeInterval.value = setInterval(() => {
      if (activityTime.value) {
        activityTime.value -= 1
      } else {
        clearInterval(timeInterval.value)
      }
    }, 1000)
  }
}

const setNextActivity = () => {
  store.setDisableLanguage(false)
  applicantAnswers.value = []
  if (activitiesCompleted.value < activities.value.length) {
    const a = activities.value[activitiesCompleted.value]
    if (a) {
      activity.value = a
      currentActivityQuestion.value = 0
      hasStartedActivity.value = false
      hasCompletedActivityTest.value = false
    }
  } else {
    if (timeInterval.value) {
      clearInterval(timeInterval.value)
    }
    hasCompletedAllActivities.value = true
    // log out after 60 seconds
    timeout.value = setTimeout(() => {
      logout()
    }, 60000)
  }
}

const updateApplicantAnswers = (a: string| null) => {
  if (activity.value?.testQuestions) {
    const current = activity.value?.testQuestions[currentActivityQuestion.value]
    if (current?.numberOfSelections && applicantAnswers.value.length > current?.numberOfSelections && a) {
      applicantAnswers.value = []
      applicantAnswers.value.push(a)
    }
  }
}

const saveActivity = async (answer?: string) => {
  btnDisabled.value = true
  error.value = false
  if (activity.value?.testQuestions) {
    if (answer) {
      applicantAnswers.value.push(answer)
    }
    const currentQuestion = activity.value.testQuestions[currentActivityQuestion.value]
    const nextQuestion = activity.value.testQuestions[currentActivityQuestion.value + 1]
    const noTimeLeft = activity.value.hasTimeLimit && activityTime.value === 0

    if (currentQuestion?.numberOfSelections && applicantAnswers.value.length < currentQuestion.numberOfSelections) {
      const moreThan1 = currentQuestion?.numberOfSelections > 1 ? 's' : ''
      showError(isEnglish.value ? 
                'Please make sure to select at least ' + currentQuestion?.numberOfSelections + ' selection' + moreThan1 : 
                'Por favor asegúrese de seleccionar al menos ' + + currentQuestion?.numberOfSelections + ' respuesta' + moreThan1)
    } else {
      const a: SaveActivity = {
        email: email.value,
        applicationId: store.applicationId,
        activityId: activity.value.id,
        questionId: currentQuestion ? currentQuestion.id : '',
        finishedPractice: true,
        type: activity.value.type,
        isLast: noTimeLeft ? true : nextQuestion ? false : true,
        answers: applicantAnswers.value,
        startTime: activityStartTimestamp.value
      }
      const r = await api.saveActivity(a)
      if (r === 'Success') {
        if (noTimeLeft) {
          activitiesCompleted.value += 1
          hasCompletedActivityTest.value = true
        } else {
          currentActivityQuestion.value += 1
          if (nextQuestion) {
            applicantAnswers.value = []
          } else {
            activitiesCompleted.value += 1
            hasCompletedActivityTest.value = true
          }
        }
      } else {
        showError(isEnglish.value ? 'There was an error saving the answer' : 'Hubo un error al guardar la respuesta')
      }
    }
  }
  btnDisabled.value = false
}

const nextPracticeActivity = () => {
  if (activity.value?.practiceQuestions) {
    isApplicantAnswerIncorrect.value = false
    isApplicantAnswerCorrect.value = false
    currentActivityQuestion.value += 1
    const found = activity.value?.practiceQuestions[currentActivityQuestion.value]
    if (found) {
      applicantAnswers.value = []
    } else {
      hasCompletedPracticeActivity.value = true
      currentActivityQuestion.value = 0
    }
  }
}

const retakePracticeActivity = () => {
  hasCompletedPracticeActivity.value = false
  applicantAnswers.value = []
}

const startActivityTest = () => {
  store.setDisableLanguage(true)
  hasStartedActivityTest.value = true
  applicantAnswers.value = []
  setActivityTime()
}

const savePracticeActivity = (answer?: string) => {
  if (activity.value?.practiceQuestions) {
    const current = activity.value?.practiceQuestions[currentActivityQuestion.value]
    if (activity.value?.type === 'Checkbox') {
      let totalScore = 0
      let actualScore = 0
      if (current?.answers) {
        current.answers.forEach((a) => {
          if (a.value) {
            totalScore += a.value
            if (a.code && applicantAnswers.value.includes(a.code)) {
              actualScore += a.value
            }
          }
        })
      }
      if (totalScore === actualScore) {
        isApplicantAnswerCorrect.value = true
      } else {
        isApplicantAnswerIncorrect.value = true
      }
    } else {
      if (answer) {
        let isCorrect = false
        applicantAnswers.value.push(answer)

        if (current?.answers) {
          current.answers.forEach((a) => {
            if (a.code && applicantAnswers.value.includes(a.code)) {
              isCorrect = true
            }
          })
          if (current.answers[0]) {
            if (activity.value?.type === 'SameOrDifferent') {
              if (current.answers[0].code === 'S') {
                actualAnswer.value = isEnglish.value ? 'Same' : 'Igual'
              } else {
                actualAnswer.value = isEnglish.value ? 'Different' : 'Diferente'
              }
            } else {
              actualAnswer.value = current.answers[0].code
            }
          }
          if (applicantAnswers.value[0]) {
            if (activity.value?.type === 'SameOrDifferent') {
              if (applicantAnswers.value[0] === 'S') {
                selectedAnswer.value = isEnglish.value ? 'Same' : 'Igual'
              } else {
                selectedAnswer.value = isEnglish.value ? 'Different' : 'Diferente'
              }
            } else {
              selectedAnswer.value = applicantAnswers.value[0]
            }
          }
        }
        if (isCorrect) {
          isApplicantAnswerCorrect.value = true
        } else {
          isApplicantAnswerIncorrect.value = true
        }
      }
    }
  }
}

onBeforeRouteLeave((to, from) => {
  store.setDisableLanguage(false)
  clearTimeout(timeout.value)
})
</script>

<style>
.container-activities-view {
  width: 100%;
}
.alert {
  width: 100%;
}
.activities {
  background-color: white;
}
.activities-inner {
  background-color: white;
  min-height: 81.5dvh;
}
.logout-view {
  background-color: white;
}
@media screen and (min-width: 767px) {
  .alert {
    width: 70%;
  }
  .activities-inner {
    min-height: 85dvh;
  }
}
@media screen and (min-width: 1025px) {
  .container-activities-view {
    width: 70%;
  }
  .alert {
    width: 45%;
  }
  .activities-inner {
    min-height: 81.5dvh;
  }
}
</style>
