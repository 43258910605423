import extractErrorMessage from '@/util/extractErrorMessage'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import DialogContainer from './DialogContainer.vue'
export { DialogContainer }

export interface Dialog {
  id: number
  title?: string
  visible: boolean
  setVisible(v: boolean): void
  text: string
  leftText?: string
  textResponse?: string
  hasTextResponse?: boolean,
  buttons: {
    color: string
    text: string
    onClick(this: Dialog): void
  }[]
}

export interface RawDialogOptions<T> {
  title?: string
  text: string
  leftText?: string
  hasTextResponse?: boolean
  textResponse?: string
  buttons: {
    text: string
    color: string
    resolveValue: T
    resolveToTextResponse?: boolean
  }[]
}

export interface DialogOptions {
  title?: string
  defaultValue?: string
  okColor?: string
  okText?: string
  cancelColor?: string
  cancelText?: string
}

export const useDialogs = defineStore('dialogs', () => {
  const allDialogs = ref(Array<Dialog>())

  let nextId = 1

  const scheduleRemoveDialog = (d: Dialog) => {
    setTimeout(() => {
      const idx = allDialogs.value.indexOf(d)
      if (idx >= 0) {
        allDialogs.value.splice(idx, 1)
      }
    }, 500)
  }

  const dialog = <T>(opts: RawDialogOptions<T>) => {
    return new Promise<T>(resolve => {
      allDialogs.value.push({
        id: nextId++,
        visible: true,
        setVisible(v) {
          if (this.visible && !v) {
            this.buttons[0]?.onClick?.call(this)
          }
          this.visible = v
        },
        title: opts.title,
        text: opts.text,
        leftText: opts.leftText,
        hasTextResponse: opts.hasTextResponse,
        textResponse: opts.textResponse,
        buttons: opts.buttons.map(b => ({
          color: b.color,
          text: b.text,
          onClick() {
            this.visible = false
            if (b.resolveToTextResponse) {
              resolve(this.textResponse as any)
            } else {
              resolve(b.resolveValue)
            }
            scheduleRemoveDialog(this)
          },
        })),
      })
    })
  }

  const alert = (message: string, opts?: DialogOptions): Promise<void> => {
    return dialog({
      title: opts?.title,
      text: message,
      buttons: [
        {
          color: opts?.okColor ?? 'primary',
          text: opts?.okText ?? 'Ok',
          resolveValue: undefined,
        },
      ],
    })
  }

  const confirm = (message: string, opts?: DialogOptions): Promise<boolean> => {
    return dialog({
      title: opts?.title,
      text: message,
      buttons: [
        {
          color: opts?.cancelColor ?? 'default',
          text: opts?.cancelText ?? 'Cancel',
          resolveValue: false,
        },
        {
          color: opts?.okColor ?? 'primary',
          text: opts?.okText ?? 'Ok',
          resolveValue: true,
        },
      ],
    })
  }

  const prompt = (question: string, opts?: DialogOptions): Promise<string | null> => {
    return dialog({
      title: opts?.title,
      text: question,
      hasTextResponse: true,
      textResponse: opts?.defaultValue ?? '',
      buttons: [
        {
          color: opts?.cancelColor ?? 'default',
          text: opts?.cancelText ?? 'Cancel',
          resolveValue: null,
        },
        {
          color: opts?.okColor ?? 'primary',
          text: opts?.okText ?? 'Ok',
          resolveValue: '',
          resolveToTextResponse: true,
        },
      ],
    })
  }

  const showError = async (error: Error, opts?: DialogOptions) => {
    return dialog({
      title: opts?.title ?? 'Error',
      text: extractErrorMessage(error),
      leftText: new Date().toLocaleString(),
      buttons: [
        {
          color: opts?.okColor ?? 'primary',
          text: opts?.okText ?? 'Ok',
          resolveValue: true,
        },
      ],
    })
  }

  return {
    alert,
    confirm,
    prompt,
    dialog,
    showError,
    allDialogs,
  }
})
